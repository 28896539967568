import * as React from "react";
import {
  CustomRoutes,
  fetchUtils,
  Admin,
  Resource,
  defaultTheme,
  Pagination,
  useTranslate,
  TextInput,
  DateInput,
  Filter,
  SelectInput,
  BooleanInput,
  NullableBooleanInput,
} from "react-admin";
import authProvider from "./authProvider";
import jsonServerProvider from "ra-data-json-server";
import { url } from "./request";
import "./index.css";
import "./App.css";
import merge from "lodash/merge";
import {
  BranchesList,
  BranchesCreate,
  BranchesEdit,
} from "./components/Branches";
import { StoresList, StoresCreate, StoresEdit } from "./components/Store";
import {
  WithdrawsTypesList,
  WithdrawsTypesCreate,
  WithdrawsTypesEdit,
} from "./components/accountant/WithdrawsTypes";
import {
  UploadCentersList,
  UploadCentersCreate,
  UploadCentersEdit,
} from "./components/accountant/UploadCenters";
import {
  DepositsTypesList,
  DepositsTypesCreate,
  DepositsTypesEdit,
} from "./components/accountant/DepositsType";
import {
  DepartmentsList,
  DepartmentsCreate,
  DepartmentsEdit,
} from "./components/doctors/Departments";

import {
  XRayList,
  XRayShow,
  XRayCreate,
  XRayEdit,
} from "./components/XRays/XRays";
import {
  ExpenseTypesList,
  ExpenseTypesCreate,
  ExpenseTypesEdit,
} from "./components/accountant/ExpenseTypes";
import {
  ExpensesList,
  ExpensesCreate,
  ExpensesShow,
  ExpensesEdit,
} from "./components/accountant/Expenses";
import {
  SellInvoicesList,
  SellInvoicesEdit,
  SellInvoicesShow,
  SellInvoicesCreate,
} from "./components/invoices/SellInvoice";

import {
  SellReturnInvoicesList,
  SellReturnInvoicesCreate,
  SellReturnInvoicesShow,
} from "./components/invoices/SellReturnInvoice";
import {
  PurchasesList,
  PurchasesCreate,
  PurchasesShow,
  PurchasesEdit,
} from "./components/purchases/Purchases";
import {
  PurchaseReturnsList,
  PurchaseReturnsCreate,
  PurchaseReturnsShow,
} from "./components/purchases/PurchaseReturns";

import {
  PurchasesPreList,
  PurchasesPreCreate,
  PurchasesPreShow,
  PurchasesPreEdit,
} from "./components/purchasesPre/PurchasesPre";
import {
  CustomersList,
  CustomersCreate,
  CustomersEdit,
  CustomersShow,
} from "./components/customers/Customers";
import {
  EmployeesList,
  EmployeesCreate,
  EmployeesEdit,
  EmployeesShow,
} from "./components/employees/Employees";
import {
  EmployeeLoansList,
  EmployeeLoansCreate,
  EmployeeLoansShow,
} from "./components/employees/EmployeeLoans";
import {
  EmployeeReturnLoansList,
  EmployeeReturnLoansCreate,
  EmployeeReturnLoansShow,
} from "./components/employees/EmployeeReturnLoans";
import {
  EmployeeAbsentsList,
  EmployeeAbsentsCreate,
} from "./components/employees/EmployeeAbsents";
import {
  EmployeeRewardsList,
  EmployeeRewardsCreate,
} from "./components/employees/EmployeeRewards";
import {
  EmployeePunishmentsList,
  EmployeePunishmentsCreate,
} from "./components/employees/EmployeePunishments";
import {
  EmployeePermissionsList,
  EmployeePermissionsCreate,
} from "./components/employees/EmployeePermissions";
import {
  EmployeeSalaryPaysList,
  EmployeeSalaryPaysCreate,
} from "./components/employees/EmployeeSalaryPays";
import {
  EmployeeSalaryChangesCreate,
  EmployeeSalaryChangesList,
} from "./components/employees/EmployeeSalaryChange";

import {
  LabTestList,
  LabTestCreate,
  LabTestEdit,
  LabTestShow,
} from "./components/LabTest/LabTest";
import {
  LabTestCategoriesList,
  LabTestCategoriesCreate,
  LabTestCategoriesEdit,
} from "./components/LabTest/LabTestCategory";
import {
  MedItemList,
  MedItemCreate,
  MedItemEdit,
  MedItemShow,
} from "./components/MedItems/MedItem";

import {
  DoctorsList,
  DoctorsCreate,
  DoctorsEdit,
  DoctorsShow,
} from "./components/doctors/Doctors";
import {
  DoctorsPaymentsList,
  DoctorsPaymentsShow,
} from "./components/doctors/DoctorPayments";
import { SalarySummaryList } from "./components/employees/SalarySummary";
import { UserNamesList, UserNamesShow } from "./components/settings/userNames";
import {
  CurrencyExchangesList,
  CurrencyExchangesCreate,
  CurrencyExchangesShow,
} from "./components/accountant/CurrencyExchanges";
import {
  CustomerPaymentsList,
  CustomerPaymentsCreate,
  CustomerPaymentsShow,
  CustomerPaymentsEdit,
} from "./components/customers/CustomerPayments";
import {
  RepresentativePaymentsList,
  RepresentativePaymentsShow,
} from "./components/representatives/RepresentativePayment";
import {
  RepresentativeCustomerPaymentsList,
  RepresentativeCustomerPaymentsShow,
} from "./components/representatives/RepresentativeCustomerPayments";
import {
  SupplierList,
  SupplierCreate,
  SupplierEdit,
  SupplierShow,
} from "./components/suppliers/Supplier";
import {
  SupplierPaymentsList,
  SupplierPaymentsCreate,
  SupplierPaymentsShow,
  SupplierPaymentsEdit,
} from "./components/suppliers/SupplierPayments";
import {
  BankAccountsList,
  BankAccountsCreate,
  BankAccountsEdit,
  BankAccountsShow,
} from "./components/bankAccounts/BankAccounts";
import {
  BankAccountTransfersList,
  BankAccountTransfersCreate,
  BankAccountTransfersShow,
} from "./components/bankAccounts/BankAccountTransfers";
import {
  UserStoreBankAccountTransfersList,
  UserStoreBankAccountTransfersCreate,
  UserStoreBankAccountTransfersShow,
} from "./components/cashiers/UserStoreBankAccountTransfers";
import {
  DepositsList,
  DepositsCreate,
  DepositsShow,
} from "./components/accountant/Deposits";
import {
  ToDoList,
  ToDoCreate,
  ToDoShow,
  ToDoEdit,
} from "./components/toDoList/ToDoList";
import {
  RepresentativesList,
  RepresentativesCreate,
  RepresentativesEdit,
  RepresentativesShow,
} from "./components/representatives/Representatives";
import {
  RepresentativeInvoicesList,
  RepresentativeInvoicesCreate,
  RepresentativeInvoicesShow,
} from "./components/representatives/RepresentativeInvoices";
import {
  RepresentativeReturnInvoicesList,
  RepresentativeReturnInvoicesCreate,
  RepresentativeReturnInvoicesShow,
} from "./components/representatives/RepresentativeReturnInvoices";
import {
  RepresentativeCustomerReturnInvoicesList,
  // RepresentativeCustomerReturnInvoicesCreate,
  RepresentativeCustomerReturnInvoicesShow,
} from "./components/representatives/RepresentativeCustomerReturnInvoices";
import {
  RepresentativePreInvoicesList,
  RepresentativePreInvoicesShow,
} from "./components/representatives/RepresentativePreInvoice";
import {
  RepresentativeCustomersList,
  RepresentativeCustomersCreate,
  RepresentativeCustomersEdit,
} from "./components/representatives/RepresentativeCustomers";
import {
  WithdrawsList,
  WithdrawsCreate,
  WithdrawsShow,
} from "./components/accountant/Withdraws";
import {
  ProductCategoriesList,
  ProductCategoriesCreate,
  ProductCategoriesEdit,
} from "./components/products/ProductCategories";
import {
  ProductsList,
  ProductsEdit,
  ProductsShow,
} from "./components/products/Products";
import {
  ProductDamageTypesList,
  ProductDamageTypesCreate,
  ProductDamageTypesEdit,
} from "./components/products/ProductDamageTypes";
import {
  ProcessInvoicesList,
  ProcessInvoicesCreate,
  ProcessInvoicesShow,
} from "./components/products/ProcessInvoices";
import {
  ProductDamagesList,
  ProductDamagesCreate,
  ProductDamagesShow,
} from "./components/products/ProductDamages";
import {
  StoreTransferInvoicesList,
  StoreTransferInvoicesCreate,
  StoreTransferInvoicesShow,
} from "./components/products/StoreTransferInvoices";
import polyglotI18nProvider from "ra-i18n-polyglot";
import MyLayout from "./components/AppBar";
import Settings from "./components/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { kurdishMessages } from "./i18n/ku";
import { englishMessages } from "./i18n/en";
import { medicalKurdishMessages } from "./i18n/kuHana";
import { medicalEnglishMessages } from "./i18n/enHana";
import Dashboard from "./Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toggle/style.css";
import {
  RepresentativeCustomerSellInvoicesList,
  RepresentativeCustomerSellInvoicesShow,
} from "./components/representatives/RepresentativeCustomerSellInvoices";
import { RepresentativeCustomerChecksList } from "./components/representatives/RepresentativeCustomerChecks";
import { StoreTransactionHistoriesList } from "./components/accountant/StoreTransactionHistories";
import { ProductsCreate } from "./components/products/ProductCreate";
import {
  BrandsCreate,
  BrandsEdit,
  BrandsList,
} from "./components/brands/Brands";
import { NoPrint } from "react-easy-print";
import {
  CashierWithdrawsCreate,
  CashierWithdrawsList,
  CashierWithdrawsShow,
} from "./components/cashiers/CashierWithdraws";
import {
  CreateVisitor,
  ListVisitor,
  ShowVisitor,
  // EditVisitor,
} from "./components/visitor/customerVisits";

import { HTTP } from "./axios";
import {
  StoreInventoryInvoiceCreate,
  StoreInventoryInvoiceEdit,
  StoreInventoryInvoiceList,
  StoreInventoryInvoiceShow,
} from "./components/storeInventoryInvoice/StoreInventoryInvoice";
import { QuickSellInvoicesList } from "./components/QuickSellInvoices/QuickSellInvoicesList";
import { QuickSellReturnInvoicesList } from "./components/QuickSellInvoices/QuickSellReturnInvoicesList";
import { GetLogs } from "./components/CustomPages/GetLogs";
import { Route } from "react-router-dom";

// Custom Routes
import Pos from "./components/CustomPages/Pos";
import Profile from "./components/Profile";
import ProductStatement from "./components/reports/ProductStatement";
import CustomerStatement from "./components/reports/CustomerStatement";
import SupplierStatement from "./components/reports/SupplierStatement";
import RepresentativeStatement from "./components/reports/RepresentativeStatement";
import StockReport from "./components/reports/StockReport";
import ExpireReport from "./components/reports/ExpireReport";
import SellInvoiceReport from "./components/reports/SellInvoiceReport";
import NonPaidSellInvoiceReport from "./components/reports/NonPaidSellInvoiceReport";
import PurchaseReport from "./components/reports/PurchaseReport";
import ExpenseReport from "./components/reports/ExpenseReport";
import WithdrawReport from "./components/reports/WithdrawReport";
import DepositReport from "./components/reports/DepositReport";
import CustomerReport from "./components/reports/CustomerReport";
import SupplierReport from "./components/reports/SupplierReport";
import OrdersReport from "./components/reports/OrdersReport";
import SupplierItemReport from "./components/reports/SupplierItemReport";
import ItemSupplierReport from "./components/reports/ItemSupplierReport";
import SupplierPaymentReport from "./components/reports/SupplierPaymentReport";
import CustomerPaymentReport from "./components/reports/CustomerPaymentReport";
import EmployeeAccountStatement from "./components/HrReports/EmployeeAccountStatement";
import LoanReport from "./components/HrReports/LoanReport";
import LoanReturnReport from "./components/HrReports/LoanReturnReport";
import AbsentReport from "./components/HrReports/AbsentReport";
import RewardReport from "./components/HrReports/RewardReport";
import PunishmentReport from "./components/HrReports/PunishmentReport";
import PermissionReport from "./components/HrReports/PermissionReport";
import SalaryPayReport from "./components/HrReports/SalaryPayReport";
import BalanceSheet from "./components/accountant/BalanceSheet";
import ReportStock from "./components/reports/ReportStock";
import NotSaleReport from "./components/reports/NotSaleReport";
import SupplierStockRemain from "./components/reports/SupplierStockRemain";
import SupplierStockFullReport from "./components/reports/SupplierStockFullReport";
import SupplierSellReport from "./components/reports/SupplierSellReport";
import BrandItemReport from "./components/reports/BrandItemReport";
import { CashierSellsReport } from "./components/reports/CashierSellsReport";
import InvoiceMockup from "./components/CustomPages/InvoiceMockup";
import { VoucherMockup } from "./components/CustomPages/VoucherMockup";
import { ProductLabel } from "./components/CustomPages/ProductLabel";
import { ProductOffers } from "./components/CustomPages/ProductOffers";
import { ProductStick } from "./components/CustomPages/ProductStick";
import CashierSells from "./components/cashiers/CashierSells";
import SellInfo from "./components/cashiers/SellInfo";
import SellReceipt from "./components/CustomPages/SellReceipt/SellReceipt";
import VoucherReceipt from "./components/CustomPages/VoucherReceipt/VoucherReceipt";
import ProfitLose from "./components/adminReports/ProfitLose";
import InvoiceProfitLose from "./components/adminReports/InvoiceProfitLose";
import SupplierStatementById from "./components/reports/SupplierStatementById";
import CustomerStatementById from "./components/reports/CustomerStatementById";
import ProductStatementById from "./components/reports/ProductStatementById";
import RepresentativeCustomerStatementById from "./components/reports/RepresentativeCustomerStatementById";
import RepresentativeCustomerSellInvoicesReport from "./components/reports/RepresentativeCustomerSellInvoicesReport";
import { NewInvoice } from "./components/CustomPages/NewInvoice";
import { PrintInvoice } from "./components/CustomPages/PrintInvoice";
import { PrintInvoiceOrders } from "./components/CustomPages/PrintInvoiceOrders";
import { PrintQuickSellInvoice } from "./components/CustomPages/PrintQuckSellInvoice";
import NewDashboard from "./components/NewDashboard";
import CustomerProfitLose from "./components/adminReports/CustomerProfitLose";
import ProductSellsInvoice from "./components/reports/ProductSellsInvoice";
import DoctorPaymentsReport from "./components/MedicalReport/DoctorPaymentsReport";
import CustomerVisitsReport from "./components/MedicalReport/CustomerVisitsReport";
import DoctorsVisitsReport from "./components/MedicalReport/DoctorsVisitsReport";
import TodayCustomerVisitsReport from "./components/MedicalReport/TodayCustomerVisitsReport";
import DailyVisitReport from "./components/MedicalReport/DailyVisitReport";
import SellPricePerQtyReport from "./components/reports/SellPricePerQtyReport";

import { css } from "@emotion/react";
import _ from "lodash";
import { permissions } from "../src/components/Permissions/AllPermission";
import Permissions from "./components/Permissions/Permissions";
import { UserStoreTransactionHistoriesList } from "./components/cashiers/UserStoreTransactionHistories";
import HanaPermission from "./components/Permissions/HanaPermission";
import BahezPermission from "./components/Permissions/BahezPermission";
import SafeenPermission from "./components/Permissions/SafeenPermission";

window.role = localStorage.getItem("role") ?? "NoUser";
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Ratio", localStorage.getItem("dollarPrice"));
    options.headers.set(
      "language",
      localStorage.getItem("locale") === "kr" ? "Kurdish" : "English"
    );
    options.headers.set("storeid", localStorage.getItem("storeId") ?? 1);
    localStorage.getItem("startDate") &&
      options.headers.set("startDate", localStorage.getItem("startDate"));
    localStorage.getItem("endDate") &&
      options.headers.set("endDate", localStorage.getItem("endDate"));
  }
  const token = localStorage.getItem("token");

  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(`${url}`, httpClient);

export const MyCustomPagination = () => (
  <NoPrint>
    <Pagination
      variant={"outlined"}
      rowsPerPageOptions={[10, 15, 50, 100, 150, 500, 1000, 2500, 5000]}
      defaultValue={10}
    />
  </NoPrint>
);

export const SearchFilter = (props) => {
  const translate = useTranslate();
  const [brands, setBrands] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [cashiers, setCashiers] = React.useState([]);
  const [names, setNames] = React.useState([]);

  React.useEffect(function () {
    if (props.brand) {
      HTTP.get(`${url}/Brands?_end=5000`).then((res) => {
        let brandList = [];
        res.data.map((x) => {
          brandList.push({ id: x.id, name: x.name });
        });
        setBrands(brandList);
      });
    }
    if (props.category) {
      HTTP.get(`${url}/ProductCategories?_end=5000`).then((res) => {
        let categoryList = [];
        res.data.map((x) => {
          categoryList.push({ id: x.id, name: x.name });
        });
        setCategories(categoryList);
      });
    }
    if (props.cashiers) {
      HTTP.get(`${url}/UserStoreBankAccounts?_end=5000`).then((response) => {
        let cashierList = [];
        response.data.map((x) => {
          cashierList.push({
            id: x.id,
            name: x.firstName + " " + x.middleName,
          });
        });
        setCashiers(cashierList);
      });
    }
    if (props.names) {
      HTTP.get(`${url}/QuickSellInvoices/GetDistinctNames?_end=5000`).then(
        (response) => {
          let nameList = [];
          response.data.map((x) => {
            nameList.push({ id: x, name: x });
          });
          setNames(nameList);
        }
      );
    }
  }, []);

  return (
    <NoPrint>
      <Filter {...props}>
        {props.cashiers && (
          <SelectInput
            source="subLevelStringId"
            label="resources.root.cashiers"
            choices={cashiers}
            alwaysOn
          />
        )}
        {props.names && (
          <SelectInput
            source="name"
            label="resources.root.name"
            choices={names}
            alwaysOn
          />
        )}
        <TextInput label="resources.root.search" source="search" alwaysOn />
        {props.driver && (
          <TextInput label="resources.root.driver" source="driver" alwaysOn />
        )}
        {props.date && (
          <DateInput
            label="resources.root.startDate"
            source="startDate"
            alwaysOn
          />
        )}
        {props.date && (
          <DateInput label="resources.root.endDate" source="endDate" alwaysOn />
        )}

        {props.salary && (
          <SelectInput
            source="salaryType"
            label="resources.root.salaryType"
            alwaysOn
            choices={[
              { id: "monthly", name: translate("resources.root.monthly") },
              {
                id: "weekly",
                name: translate("resources.root.weekly"),
              },
            ]}
          />
        )}

        {props.category && (
          <SelectInput
            source="subLevelId"
            label="resources.root.category"
            alwaysOn
            choices={categories}
          />
        )}

        {props.brand && (
          <SelectInput
            source="brandId"
            label="resources.root.brand"
            alwaysOn
            choices={brands}
          />
        )}

        {props.loses && (
          <BooleanInput
            source="loses"
            label="resources.root.onlyLosses"
            alwaysOn
          />
        )}

        {props.fullDiscount && (
          <NullableBooleanInput
            source="fullDiscount"
            label="resources.root.fullDiscount"
          />
        )}
      </Filter>
    </NoPrint>
  );
};

const myDataProfider = {
  ...dataProvider,
  create: (resource, params) => {
    if (!params.data.attachment) {
      return dataProvider.create(resource, params);
    }
    return toBase64(params.data.attachment.rawFile).then((res) => {
      return httpClient(`${url}/${resource}`, {
        method: "POST",
        body: JSON.stringify({
          ...params.data,
          attachment: res,
        }),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    });
  },
  update: (resource, params) => {
    if (params.data.attachment && params.data.attachment.title) {
      return toBase64(params.data.attachment.rawFile).then((res) => {
        return httpClient(`${url}/${resource}/${params.id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...params.data,
            attachment: res,
          }),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      });
    } else {
      return dataProvider.update(resource, params);
    }
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const messages = {
  kr:
    process.env.REACT_APP_APP_NAME === "Hana"
      ? medicalKurdishMessages
      : kurdishMessages,
  en:
    process.env.REACT_APP_APP_NAME === "Hana"
      ? medicalEnglishMessages
      : englishMessages,
};

const locale = localStorage.getItem("locale");
const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  locale ? locale : "en"
);
export const hasPermission = (permission) => {
  let userType = window.role.toLowerCase();
  let permissions = [];

  if (process.env.REACT_APP_APP_NAME === "Hana") {
    permissions = HanaPermission;
  } else if (process.env.REACT_APP_APP_NAME === "Bahez") {
    permissions = BahezPermission;
  } else if (process.env.REACT_APP_APP_NAME === "Safeen") {
    permissions = SafeenPermission;
  } else {
    permissions = Permissions;
  }

  if (!Array.isArray(permission)) {
    permission = [permission];
  }
  console.log(permission);
  console.log(permissions[userType]);
  console.log(
    _.intersection(permission, permissions[userType]).length > 0 ||
      permissions[userType]?.includes("*")
  );
  return (
    _.intersection(permission, permissions[userType]).length > 0 ||
    permissions[userType]?.includes("*")
  );
};

const App = () => {
  const [direction, setDirection] = React.useState(
    localStorage.getItem("direction")
      ? localStorage.getItem("direction")
      : "ltr"
  );
  const [mode, setMode] = React.useState("dark");

  const alterDir = (dir) => {
    setDirection(dir);
    localStorage.setItem("direction", dir);
    setMode("dark");
    window.location.reload();
  };

  const myTheme = merge({}, defaultTheme, {
    typography: {
      fontFamily: "Nunito, Speda, NRT-Reg",
      textAlign: "start",
      fontSize: parseFloat(localStorage.getItem("systemFontSize")),
      fontWeight: 900,
      fontStyle: "bold",
    },
    direction,
    palette: {
      type: mode, // Switching the dark mode on is a single property value change.
    },
  });

  console.log(
    hasPermission([permissions.user]) && hasPermission([permissions.user])
  );
  return (
    <div dir={direction} className={"theRoot"}>
      <Admin
        dashboard={hasPermission([permissions.dashboard]) && Dashboard}
        authProvider={authProvider}
        dataProvider={myDataProfider}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        theme={myTheme}
      >
        {/* Products */}
        {hasPermission([permissions.productList]) && (
          <Resource
            name="Products"
            list={ProductsList}
            create={
              hasPermission([permissions.productCreate]) && ProductsCreate
            }
            edit={hasPermission([permissions.productUpdate]) && ProductsEdit}
            show={hasPermission([permissions.productView]) && ProductsShow}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.productCategoryList]) && (
          <Resource
            name="ProductCategories"
            list={ProductCategoriesList}
            create={ProductCategoriesCreate}
            edit={ProductCategoriesEdit}
            // icon={CategoryIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.productBrandList]) && (
          <Resource
            name="Brands"
            list={BrandsList}
            create={BrandsCreate}
            edit={BrandsEdit}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.damageInvoiceList]) && (
          <Resource
            name="DamageInvoices"
            list={ProductDamagesList}
            create={ProductDamagesCreate}
            show={ProductDamagesShow}
            // icon={BrokenImageIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.damageTypeList]) && (
          <Resource
            name="ProductDamageTypes"
            list={ProductDamageTypesList}
            create={ProductDamageTypesCreate}
            edit={ProductDamageTypesEdit}
            // icon={BrokenImageOutlinedIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.storeTransferList]) && (
          <Resource
            name="StoreTransferInvoices"
            list={StoreTransferInvoicesList}
            create={
              hasPermission([permissions.storeTransferCreate]) &&
              StoreTransferInvoicesCreate
            }
            show={
              hasPermission([permissions.storeTransferView]) &&
              StoreTransferInvoicesShow
            }
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.processInvoiceList]) && (
          <Resource
            name="ProcessInvoices"
            list={ProcessInvoicesList}
            create={ProcessInvoicesCreate}
            show={ProcessInvoicesShow}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.storeInventoryInvoiceList]) && (
          <Resource
            name="StoreInventoryInvoice"
            list={StoreInventoryInvoiceList}
            create={
              hasPermission([permissions.storeInventoryInvoiceCreate]) &&
              StoreInventoryInvoiceCreate
            }
            show={
              hasPermission([permissions.storeInventoryInvoiceView]) &&
              StoreInventoryInvoiceShow
            }
            edit={
              hasPermission([permissions.storeInventoryInvoiceUpdate]) &&
              StoreInventoryInvoiceEdit
            }
            options={{ alterDir, direction }}
          />
        )}
        {/* End Products */}
        {/* Customers */}
        {hasPermission([permissions.customerList]) && (
          <Resource
            name="Customers"
            list={CustomersList}
            create={
              hasPermission([permissions.customerCreate]) && CustomersCreate
            }
            show={hasPermission([permissions.customerView]) && CustomersShow}
            edit={hasPermission([permissions.customerUpdate]) && CustomersEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.customerPaymentList]) && (
          <Resource
            name="CustomerPayments"
            list={CustomerPaymentsList}
            create={
              hasPermission([permissions.customerPaymentCreate]) &&
              CustomerPaymentsCreate
            }
            show={
              hasPermission([permissions.customerPaymentView]) &&
              CustomerPaymentsShow
            }
            edit={
              hasPermission([permissions.customerPaymentUpdate]) &&
              CustomerPaymentsEdit
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Customers */}
        {/* Sell Invoice */}
        {hasPermission([permissions.sellInvoiceList]) && (
          <Resource
            name="SellInvoices"
            list={SellInvoicesList}
            create={
              hasPermission([permissions.sellInvoiceCreate]) &&
              SellInvoicesCreate
            }
            show={
              hasPermission([permissions.sellInvoiceView]) && SellInvoicesShow
            }
            edit={
              hasPermission([permissions.sellInvoiceUpdate]) && SellInvoicesEdit
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.returnSellInvoiceList]) && (
          <Resource
            name="SellReturnInvoices"
            list={SellReturnInvoicesList}
            create={
              hasPermission([permissions.returnSellInvoiceCreate]) &&
              SellReturnInvoicesCreate
            }
            show={
              hasPermission([permissions.returnSellInvoiceView]) &&
              SellReturnInvoicesShow
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Sell invoice */}
        {/* Supplier */}
        {hasPermission([permissions.supplierList]) && (
          <Resource
            name="Suppliers"
            list={SupplierList}
            create={
              hasPermission([permissions.supplierCreate]) && SupplierCreate
            }
            show={hasPermission([permissions.supplierView]) && SupplierShow}
            edit={hasPermission([permissions.supplierUpdate]) && SupplierEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.supplierPaymentList]) && (
          <Resource
            name="SupplierPayments"
            list={SupplierPaymentsList}
            create={
              hasPermission([permissions.supplierPaymentCreate]) &&
              SupplierPaymentsCreate
            }
            show={
              hasPermission([permissions.supplierPaymentView]) &&
              SupplierPaymentsShow
            }
            edit={
              hasPermission([permissions.supplierPaymentUpdate]) &&
              SupplierPaymentsEdit
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Supplier*/}
        {/* Purchase*/}
        {hasPermission([permissions.purchaseInvoiceList]) && (
          <Resource
            name="PurchaseInvoices"
            list={PurchasesList}
            create={
              hasPermission([permissions.purchaseInvoiceCreate]) &&
              PurchasesCreate
            }
            show={
              hasPermission([permissions.purchaseInvoiceView]) && PurchasesShow
            }
            edit={
              hasPermission([permissions.purchaseInvoiceUpdate]) &&
              PurchasesEdit
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.returnPurchaseInvoiceList]) && (
          <Resource
            name="PurchaseReturnInvoices"
            list={PurchaseReturnsList}
            create={
              hasPermission([permissions.returnPurchaseInvoiceCreate]) &&
              PurchaseReturnsCreate
            }
            show={
              hasPermission([permissions.returnPurchaseInvoiceView]) &&
              PurchaseReturnsShow
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Purchase */}

        {/* Purchase Pre */}
        {hasPermission([permissions.purchasePreInvoiceList]) && (
          <Resource
            name="PurchasePreInvoices"
            list={PurchasesPreList}
            create={
              hasPermission([permissions.purchaseInvoiceCreate]) &&
              PurchasesPreCreate
            }
            show={
              hasPermission([permissions.purchaseInvoiceView]) &&
              PurchasesPreShow
            }
            edit={
              hasPermission([permissions.purchaseInvoiceUpdate]) &&
              PurchasesPreEdit
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Purchase Pre */}

        {/* Representative */}
        {hasPermission([permissions.representativeList]) && (
          <>
            <Resource
              name="Representatives"
              list={RepresentativesList}
              create={
                hasPermission([permissions.representativeCreate]) &&
                RepresentativesCreate
              }
              show={
                hasPermission([permissions.representativeView]) &&
                RepresentativesShow
              }
              edit={
                hasPermission([permissions.representativeUpdate]) &&
                RepresentativesEdit
              }
            />
            <Resource
              name="RepresentativeInvoices"
              list={RepresentativeInvoicesList}
              create={RepresentativeInvoicesCreate}
              // edit={RepresentativeInvoicesEdit}
              show={RepresentativeInvoicesShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativePayments"
              list={RepresentativePaymentsList}
              // create={RepresentativePaymentsCreate}
              show={RepresentativePaymentsShow}
              // icon={StoreIcon}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativeReturnInvoices"
              list={RepresentativeReturnInvoicesList}
              create={RepresentativeReturnInvoicesCreate}
              // edit={RepresentativeReturnInvoicesEdit}
              show={RepresentativeReturnInvoicesShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativePreInvoices"
              list={RepresentativePreInvoicesList}
              show={RepresentativePreInvoicesShow}
              options={{ alterDir, direction }}
            />
          </>
        )}
        {/* End Representative */}
        {/* Representative Customer */}
        {hasPermission([permissions.representativeCustomerList]) && (
          <>
            <Resource
              name="RepresentativeCustomers"
              list={RepresentativeCustomersList}
              create={
                hasPermission([permissions.RepresentativeCustomersCreate]) &&
                RepresentativeCustomersCreate
              }
              edit={
                hasPermission([permissions.representativeCustomerUpdate]) &&
                RepresentativeCustomersEdit
              }
            />
            <Resource
              name="RepresentativeCustomerPayments"
              list={RepresentativeCustomerPaymentsList}
              show={RepresentativeCustomerPaymentsShow}
              // icon={StoreIcon}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativeCustomerSellInvoices"
              list={RepresentativeCustomerSellInvoicesList}
              show={RepresentativeCustomerSellInvoicesShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativeCustomerReturnInvoices"
              list={RepresentativeCustomerReturnInvoicesList}
              // create={RepresentativeCustomerReturnInvoicesCreate}
              // edit={RepresentativeCustomerReturnInvoicesEdit}
              show={RepresentativeCustomerReturnInvoicesShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="RepresentativeCustomerChecks"
              list={RepresentativeCustomerChecksList}
            />
          </>
        )}
        {/* End Representative Customer */}
        {/* Cashier */}
        {hasPermission([permissions.cashierSellList]) && (
          <Resource
            name="QuickSellInvoices"
            list={QuickSellInvoicesList}
            hascreate={false}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.cashierReturnList]) && (
          <Resource
            name="QuickSellReturnInvoices"
            list={QuickSellReturnInvoicesList}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.cashierWithdrawList]) && (
          <Resource
            name="UserStoreBankAccountWithdraws"
            list={CashierWithdrawsList}
            create={
              hasPermission([permissions.cashierWithdrawCreate]) &&
              CashierWithdrawsCreate
            }
            show={
              hasPermission([permissions.cashierWithdrawView]) &&
              CashierWithdrawsShow
            }
            options={{ alterDir, direction }}
          />
        )}
        {/* End Cashier */}
        {/* HR  */}
        {hasPermission([permissions.HrList]) && (
          <>
            <Resource
              name="Employees"
              list={EmployeesList}
              create={EmployeesCreate}
              edit={EmployeesEdit}
              show={EmployeesShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="Employeeloans"
              list={EmployeeLoansList}
              create={EmployeeLoansCreate}
              show={EmployeeLoansShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeReturnLoans"
              list={EmployeeReturnLoansList}
              create={EmployeeReturnLoansCreate}
              show={EmployeeReturnLoansShow}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeAbsents"
              list={EmployeeAbsentsList}
              create={EmployeeAbsentsCreate}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeRewards"
              list={EmployeeRewardsList}
              create={EmployeeRewardsCreate}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeePunishments"
              list={EmployeePunishmentsList}
              create={EmployeePunishmentsCreate}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeAllows"
              list={EmployeePermissionsList}
              create={EmployeePermissionsCreate}
              options={{ alterDir, direction }}
            />

            <Resource
              name="EmployeeSalaryPays"
              list={EmployeeSalaryPaysList}
              create={EmployeeSalaryPaysCreate}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeSalaryChanges"
              list={EmployeeSalaryChangesList}
              create={EmployeeSalaryChangesCreate}
              options={{ alterDir, direction }}
            />
            <Resource
              name="EmployeeSalarySummary"
              list={SalarySummaryList}
              options={{ alterDir, direction }}
            />
          </>
        )}
        {/* End HR */}
        {/* Accounts */}
        {hasPermission([permissions.bankAccountList]) && (
          <Resource
            name="BankAccounts"
            list={BankAccountsList}
            create={BankAccountsCreate}
            show={
              hasPermission([permissions.bankAccountView]) && BankAccountsShow
            }
            edit={
              hasPermission([permissions.bankAccountUpdate]) && BankAccountsEdit
            }
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.storeTransactionHistoriesList]) && (
          <Resource
            name="StoreTransactionHistories"
            list={StoreTransactionHistoriesList}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.bankAccountTransferList]) && (
          <Resource
            name="BankAccountTransfers"
            list={BankAccountTransfersList}
            create={
              hasPermission([permissions.bankAccountTransferCreate]) &&
              BankAccountTransfersCreate
            }
            show={
              hasPermission([permissions.bankAccountTransferView]) &&
              BankAccountTransfersShow
            }
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.userStoreBankAccountTransferList]) && (
          <Resource
            name="UserStoreBankAccountTransfers"
            list={UserStoreBankAccountTransfersList}
            create={
              hasPermission([permissions.userStoreBankAccountTransferCreate]) &&
              UserStoreBankAccountTransfersCreate
            }
            show={
              hasPermission([permissions.userStoreBankAccountTransferView]) &&
              UserStoreBankAccountTransfersShow
            }
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.userStoreTransactionHistoriesList]) && (
          <Resource
            name="UserStoreTransactionHistories"
            list={UserStoreTransactionHistoriesList}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.balanceSheet]) && (
          <CustomRoutes>
            <Route path="/BalanceSheet" element={<BalanceSheet />} />
          </CustomRoutes>
        )}
        {hasPermission([permissions.depositList]) && (
          <Resource
            name="Deposits"
            list={DepositsList}
            create={DepositsCreate}
            show={DepositsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.withdrawList]) && (
          <Resource
            name="Withdraws"
            list={WithdrawsList}
            create={WithdrawsCreate}
            show={WithdrawsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {hasPermission([permissions.expenseList]) && (
          <Resource
            name="Expenses"
            list={ExpensesList}
            create={ExpensesCreate}
            edit={ExpensesEdit}
            show={ExpensesShow}
          />
        )}
        {hasPermission([permissions.currencyExchangeList]) && (
          <Resource
            name="CurrencyExchanges"
            list={CurrencyExchangesList}
            create={
              hasPermission([permissions.currencyExchangeCreate]) &&
              CurrencyExchangesCreate
            }
            show={
              hasPermission([permissions.currencyExchangeView]) &&
              CurrencyExchangesShow
            }
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
        )}
        {/* End Accounts */}
        {/* Settings */}
        {hasPermission([permissions.setting]) && (
          <>
            <Resource
              name="Stores"
              list={StoresList}
              create={StoresCreate}
              edit={StoresEdit}
              // icon={StoreIcon}
              options={{ alterDir, direction }}
            />
            <Resource
              name="DepositTypes"
              list={DepositsTypesList}
              create={DepositsTypesCreate}
              edit={DepositsTypesEdit}
            />
            <Resource
              name="WithdrawTypes"
              list={WithdrawsTypesList}
              create={WithdrawsTypesCreate}
              edit={WithdrawsTypesEdit}
            />
            <Resource
              name="ExpenseTypes"
              list={ExpenseTypesList}
              create={ExpenseTypesCreate}
              edit={ExpenseTypesEdit}
            />
            <Resource
              name="UploadCenters"
              list={UploadCentersList}
              create={UploadCentersCreate}
              edit={UploadCentersEdit}
            />
          </>
        )}
        {hasPermission([permissions.user]) &&
          hasPermission([permissions.user]) && (
            <Resource
              name="Users"
              list={UserNamesList}
              show={UserNamesShow}
              options={{ alterDir, direction }}
            />
          )}
        {/* End Settings */}
        {/* Doctors */}
        {hasPermission([
          permissions.doctorList,
          permissions.doctorPaymentList,
          permissions.departmentList,
        ]) && (
          <>
            {hasPermission([permissions.doctorList]) && (
              <Resource
                name="Doctors"
                list={DoctorsList}
                create={DoctorsCreate}
                edit={DoctorsEdit}
                show={DoctorsShow}
                options={{ alterDir, direction }}
              />
            )}
            {hasPermission([permissions.doctorPaymentList]) && (
              <Resource
                name="DoctorPayments"
                list={DoctorsPaymentsList}
                show={DoctorsPaymentsShow}
              />
            )}
            {hasPermission([permissions.departmentList]) && (
              <Resource
                name="Departments"
                list={DepartmentsList}
                create={DepartmentsCreate}
                edit={DepartmentsEdit}
              />
            )}
          </>
        )}
        {/* End Doctors */}
        {/* Medical */}
        {hasPermission([
          permissions.labTestList,
          permissions.labTestCategoryList,
          permissions.medItemsList,
          permissions.xRayList,
        ]) && (
          <>
            {hasPermission([permissions.labTestList]) && (
              <Resource
                name="LabTests"
                list={LabTestList}
                create={LabTestCreate}
                edit={LabTestEdit}
                show={LabTestShow}
                options={{ alterDir, direction }}
              />
            )}
            {hasPermission([permissions.labTestCategoryList]) && (
              <Resource
                name="LabTestCategories"
                list={LabTestCategoriesList}
                create={LabTestCategoriesCreate}
                edit={LabTestCategoriesEdit}
              />
            )}
            {hasPermission([permissions.medItemsList]) && (
              <Resource
                name="MedItems"
                list={MedItemList}
                create={MedItemCreate}
                edit={MedItemEdit}
                show={MedItemShow}
                options={{ alterDir, direction }}
              />
            )}
            {hasPermission([permissions.xRayList]) && (
              <Resource
                name="XRays"
                list={XRayList}
                show={XRayShow}
                create={XRayCreate}
                edit={XRayEdit}
              />
            )}
          </>
        )}

        {/* End Medical */}
        {/* Visitors */}
        {hasPermission([permissions.visitorList]) && (
          <Resource
            name="CustomerVisits"
            list={ListVisitor}
            show={ShowVisitor}
          />
        )}
        {/* End Visitors */}
        {/* General Report */}
        {hasPermission([permissions.generalReport]) && (
          <CustomRoutes>
            <Route path="/Reports/OrdersReport" element={<OrdersReport />} />
            <Route
              path="/Reports/CustomerStatement/:id"
              element={<CustomerStatementById />}
            />
            <Route
              path="/Reports/CustomerStatement"
              element={<CustomerStatement />}
            />
            <Route
              path="/Reports/CashierSellsReport"
              element={<CashierSellsReport />}
            />
            <Route
              path="/Reports/RepresentativeStatement"
              element={<RepresentativeStatement />}
            />
            <Route
              path="/Reports/RepresentativeCustomerStatement/:id"
              element={<RepresentativeCustomerStatementById />}
            />
            <Route
              path="/Reports/RepresentativeCustomerSellInvoicesReport"
              element={<RepresentativeCustomerSellInvoicesReport />}
            />
            <Route path="/Reports/ExpireReport" element={<ExpireReport />} />
            <Route
              path="/Reports/SellInvoiceReport"
              element={<SellInvoiceReport />}
            />
            <Route
              path="/Reports/NonPaidSellInvoiceReport"
              element={<NonPaidSellInvoiceReport />}
            />
            <Route
              path="/Reports/PurchaseReport"
              element={<PurchaseReport />}
            />
            <Route path="/Reports/ExpenseReport" element={<ExpenseReport />} />
            <Route
              path="/Reports/WithdrawReport"
              element={<WithdrawReport />}
            />
            <Route path="/Reports/DepositReport" element={<DepositReport />} />
            <Route
              path="/Reports/CustomerReport"
              element={<CustomerReport />}
            />
            <Route
              path="/Reports/CustomerPaymentReport"
              element={<CustomerPaymentReport />}
            />
            {process.env.REACT_APP_APP_NAME === "Bahez" && (
              <Route
                path="/Reports/SellPricePerQtyReport"
                element={<SellPricePerQtyReport />}
              />
            )}
          </CustomRoutes>
        )}
        {/* End General Report */}
        {/* Item Report */}
        {hasPermission([permissions.itemReport]) && (
          <CustomRoutes>
            <Route
              path="/Reports/ProductStatement"
              element={<ProductStatement />}
            />
            <Route
              path="/Reports/ProductStatement/:id"
              element={<ProductStatementById />}
            />
            <Route path="/Reports/ReportStock" element={<ReportStock />} />
            <Route path="/Reports/NotSaleReport" element={<NotSaleReport />} />
            <Route path="/Reports/StockReport" element={<StockReport />} />{" "}
            <Route
              path="/Reports/BrandItemReport"
              element={<BrandItemReport />}
            />
            <Route
              path="/Reports/ProductSellsInvoice"
              element={<ProductSellsInvoice />}
            />
          </CustomRoutes>
        )}
        {/* End Item Report */}
        {/* Supplier Report */}
        {hasPermission([permissions.supplierReport]) && (
          <CustomRoutes>
            <Route
              path="/Reports/SupplierStatement"
              element={<SupplierStatement />}
            />
            <Route
              path="/Reports/SupplierStatement/:id"
              element={<SupplierStatementById />}
            />
            <Route
              path="/Reports/SupplierReport"
              element={<SupplierReport />}
            />
            <Route
              path="/Reports/SupplierPaymentReport"
              element={<SupplierPaymentReport />}
            />
            <Route
              path="/Reports/SupplierStockRemain"
              element={<SupplierStockRemain />}
            />
            <Route
              path="/Reports/SupplierItemReport"
              element={<SupplierItemReport />}
            />
            <Route
              path="/Reports/ItemSupplierReport"
              element={<ItemSupplierReport />}
            />
            <Route
              path="/Reports/SupplierSellReport"
              element={<SupplierSellReport />}
            />
            <Route
              path="/Reports/SupplierStockFullReport"
              element={<SupplierStockFullReport />}
            />
          </CustomRoutes>
        )}
        {/* End Supplier Report */}
        {/* Admin Report */}
        {hasPermission([permissions.adminReport]) && (
          <>
            <CustomRoutes>
              <Route path="/AdminReports/ProfitLose" element={<ProfitLose />} />
              <Route
                path="/AdminReports/InvoiceProfitLose"
                element={<InvoiceProfitLose />}
              />
              <Route
                path="/AdminReports/CustomerProfitLose"
                element={<CustomerProfitLose />}
              />
            </CustomRoutes>
            <Resource
              // path=""
              name="Utilities/GetLogs"
              list={GetLogs}
              options={{ alterDir, direction }}
            />
          </>
        )}
        {/* End Admin Report */}
        {/* HR Report */}
        {hasPermission([permissions.hrReport]) && (
          <CustomRoutes>
            <Route
              path="/HrReports/EmployeeAccountStatement"
              element={<EmployeeAccountStatement />}
            />
            <Route path="/HrReports/AbsentReport" element={<AbsentReport />} />
            <Route path="/HrReports/LoanReport" element={<LoanReport />} />
            <Route
              path="/HrReports/LoanReturnReport"
              element={<LoanReturnReport />}
            />
            <Route
              path="/HrReports/PermissionReport"
              element={<PermissionReport />}
            />
            <Route
              path="/HrReports/PunishmentReport"
              element={<PunishmentReport />}
            />
            <Route path="/HrReports/RewardReport" element={<RewardReport />} />
            <Route
              path="/HrReports/SalaryPayReport"
              element={<SalaryPayReport />}
            />
          </CustomRoutes>
        )}

        <Resource
          name="Settings"
          create={Settings}
          icon={AssessmentIcon}
          options={{ alterDir, direction }}
        />
        <CustomRoutes>
          <Route path="/Pos" element={<Pos />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/InvoiceMockup" element={<InvoiceMockup />} />
          <Route path="/VoucherMockup" element={<VoucherMockup />} />
          <Route path="/ProductLabel" element={<ProductLabel />} />
          <Route path="/ProductOffers" element={<ProductOffers />} />
          <Route path="/ProductStick" element={<ProductStick />} />
          <Route path="/Cashier/CashierSells" element={<CashierSells />} />
          <Route path="/Cashier/SellInfo/:id/show" element={<SellInfo />} />
          <Route
            path="/Cashier/SellInfo/:id/show/:return"
            element={<SellInfo />}
          />
          <Route path="/SellReceipt" element={<SellReceipt />} />
          <Route path="/VoucherReceipt" element={<VoucherReceipt />} />
          <Route
            path="/Reports/RepresentativeCustomerStatement/:id"
            element={<RepresentativeCustomerStatementById />}
          />
          <Route path="/NewInvoice" element={<NewInvoice />} />
          <Route path="/PrintInvoice/:type/:json" element={<PrintInvoice />} />
          <Route
            path="/PrintInvoiceOrders/:type"
            element={<PrintInvoiceOrders />}
          />
          <Route
            path="/PrintInvoice/quicksellinvoice/:type/:json"
            element={<PrintQuickSellInvoice />}
          />
          <Route
            path="/MedicalReport/DoctorPaymentsReport"
            element={<DoctorPaymentsReport />}
          />
          <Route
            path="/MedicalReport/DoctorsVisitsReport"
            element={<DoctorsVisitsReport />}
          />
          <Route
            path="/MedicalReport/CustomerVisitsReport"
            element={<CustomerVisitsReport />}
          />
          <Route
            path="/MedicalReport/TodayCustomerVisitsReport"
            element={<TodayCustomerVisitsReport />}
          />
          <Route
            path="/MedicalReport/DailyVisitReport"
            element={<DailyVisitReport />}
          />
        </CustomRoutes>
      </Admin>
    </div>
  );
};
export default App;
