import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import lodash from "lodash";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { path } from "../../request";
import Autocomplete from "@mui/lab/Autocomplete";
import MaterialTextField from "@mui/material/TextField";
export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(0);
  const [categories, setCategories] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [total, setTotal] = React.useState({
    totalSellQty: 0,
    totalSellSmallQty: 0,
    totalSellGiftQty: 0,
    totalSellSmallGiftQty: 0,
    totalReturnQty: 0,
    totalReturnSmallQty: 0,

    totalDiscountPrice: {
      mc: 0,
      sc: 0,
    },
    totalReturnPrice: {
      mc: 0,
      sc: 0,
    },
    totalSellPrice: {
      mc: 0,
      sc: 0,
    },
    totalProfit: {
      mc: 0,
      sc: 0,
    },
    totalDamagePrice: {
      mc: 0,
      sc: 0,
    },
  });

  const [switches, setSwitches] = React.useState([
    {
      title: "sellQty",
      value: true,
    },
    {
      title: "smallMeasureSellQty",
      value: true,
    },
    {
      title: "damageQty",
      value: true,
    },
    {
      title: "returns",
      value: true,
    },
    {
      title: "averageSellPrice",
      value: true,
    },
    {
      title: "averageSellOnePrice",
      value: true,
    },
    {
      title: "totalSellPrice",
      value: true,
    },
    {
      title: "totalSellProfit",
      value: true,
    },
    {
      title: "attachment",
      value: true,
    },
  ]);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }
  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    let product = ``;
    if (selectedProduct !== 0) {
      product = `&id=${selectedProduct}`;
    } else {
      product = "";
    }
    let category = ``;
    if (categories.length !== 0) {
      category = `&byCategory=true${categories}`;
    } else {
      category = "";
    }

    await HTTP.get(
      `${url}/AdminReports/GetItemProfitLoseReport?_start=${start}&_end=${end}${sendDates}${category}${product}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotal({
          totalSellQty: response.headers["totalsellqty"],
          totalSellSmallQty: response.headers["totalsellsmallqty"],
          totalSellGiftQty: response.headers["totalsellgiftqty"],
          totalSellSmallGiftQty: response.headers["totalsellsmallgiftqty"],
          totalReturnQty: response.headers["totalreturnqty"],
          totalReturnSmallQty: response.headers["totalreturnsmallqty"],

          totalDiscountPrice: {
            mc: response.headers["mctotaldiscountprice"],
            sc: response.headers["sctotaldiscountprice"],
          },
          totalReturnPrice: {
            mc: response.headers["mctotalreturnprice"],
            sc: response.headers["sctotalreturnprice"],
          },
          totalSellPrice: {
            mc: response.headers["mctotalsellprice"],
            sc: response.headers["sctotalsellprice"],
          },
          totalProfit: {
            mc: response.headers["mctotalprofit"],
            sc: response.headers["sctotalprofit"],
          },
          totalDamagePrice: {
            mc: response.headers["mctotaldamageprice"],
            sc: response.headers["sctotaldamageprice"],
          },
        });

        setData(
          lodash
            .chain(response.data)
            .groupBy("productId")
            .map((value, key) => {
              let mcAveragePurchaseOnePriceList = value;
              let mcAveragePurchasePriceList = value;
              let mcAverageSellOnePriceList = value;
              let mcAverageSellPriceList = value;
              let scAveragePurchaseOnePriceList = value;
              let scAveragePurchasePriceList = value;
              let scAverageSellOnePriceList = value;
              let scAverageSellPriceList = value;

              mcAveragePurchaseOnePriceList =
                mcAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              mcAveragePurchasePriceList = mcAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              mcAverageSellOnePriceList = mcAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              mcAverageSellPriceList = mcAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              scAveragePurchaseOnePriceList =
                scAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              scAveragePurchasePriceList = scAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              scAverageSellOnePriceList = scAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              scAverageSellPriceList = scAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              return {
                productId: key,
                productName: value[0].productName,
                productBarcode: value[0].productBarcode,
                productAttachment: value[0].productAttachment,
                smallMeasurePurchaseQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseQty"
                ),
                purchaseQty: lodash.sumBy(value, "purchaseQty"),
                sellQty: lodash.sumBy(value, "sellQty"),
                damageQty: lodash.sumBy(value, "damageQty"),
                smallMeasureSellQty: lodash.sumBy(value, "smallMeasureSellQty"),
                smallMeasurePurchaseReturnQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseReturnQty"
                ),
                purchaseReturnQty: lodash.sumBy(value, "purchaseReturnQty"),
                sellReturnQty: lodash.sumBy(value, "sellReturnQty"),
                smallMeasureSellReturnQty: lodash.sumBy(
                  value,
                  "smallMeasureSellReturnQty"
                ),

                ///// type === purchase
                mcAveragePurchaseOnePrice: lodash.meanBy(
                  scAveragePurchaseOnePriceList,
                  "mcAveragePurchaseOnePrice"
                ),
                scAveragePurchaseOnePrice: lodash.meanBy(
                  mcAveragePurchaseOnePriceList,
                  "scAveragePurchaseOnePrice"
                ),
                mcAveragePurchasePrice: lodash.meanBy(
                  mcAveragePurchasePriceList,
                  "mcAveragePurchasePrice"
                ),
                scAveragePurchasePrice: lodash.meanBy(
                  scAveragePurchasePriceList,
                  "scAveragePurchasePrice"
                ),

                ///// type === quick sell or sell
                mcAverageSellOnePrice: lodash.meanBy(
                  mcAverageSellOnePriceList,
                  "mcAverageSellOnePrice"
                ),
                scAverageSellOnePrice: lodash.meanBy(
                  scAverageSellOnePriceList,
                  "scAverageSellOnePrice"
                ),

                mcAverageSellPrice: lodash.meanBy(
                  mcAverageSellPriceList,
                  "mcAverageSellPrice"
                ),
                scAverageSellPrice: lodash.meanBy(
                  scAverageSellPriceList,
                  "scAverageSellPrice"
                ),

                mcTotalSellPrice: lodash.sumBy(value, "mcTotalSellPrice"),
                scTotalSellPrice: lodash.sumBy(value, "scTotalSellPrice"),
                mcTotalPurchasePrice: lodash.sumBy(
                  value,
                  "mcTotalPurchasePrice"
                ),
                scTotalPurchasePrice: lodash.sumBy(
                  value,
                  "scTotalPurchasePrice"
                ),
                mcTotalSellProfit: lodash.sumBy(value, "mcTotalSellProfit"),
                scTotalSellProfit: lodash.sumBy(value, "scTotalSellProfit"),
                type: "-",
              };
            })
            .value()
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  // React.useEffect(function () {
  //   setIsLoading(true);
  // }, []);

  function regetData() {
    setIsLoading(true);
    getData();
  }
  // React.useEffect(
  //   function () {
  //     setIsLoading(true);
  //     getData();
  //   },
  //   [products, categories, startDate, endDate, start, end]
  // );

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );
  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=10&search=${inputValue}`
      );

      let newArray = [];
      newArray.push({
        value: 0,
        label: "All",
        product: 0,
      });
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  const loadCategoriesOption = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/ProductCategories?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((category) => {
        newArray.push({
          value: category.id,
          label: category.name,
          category: category,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div className="m-4">
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.profitLoseReport")}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}

            <Row>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.chooseProduct")} :
                </p>
                {localStorage.getItem("useOldSelect") === "true" ? (
                  <Autocomplete
                    id="combo-box-demo"
                    options={products}
                    isMulti
                    getOptionLabel={(option) =>
                      `${option.name} - ${option.barcode}`
                    }
                    className="w-100"
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    onChange={(e, val) => {
                      setSelectedProduct(val.id);
                    }}
                    renderInput={(params) => (
                      <MaterialTextField {...params} variant="outlined" />
                    )}
                  />
                ) : (
                  <AsyncSelect
                    defaultOptions
                    className="w-100"
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    loadOptions={loadOptions}
                    onChange={(e) => {
                      console.log(e);
                      setSelectedProduct(e ? e.value : 0);
                    }}
                  />
                )}
              </Col>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.chooseCategory")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadCategoriesOption}
                  isMulti
                  menuPortalTarget={document.body}
                  className="w-100"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    let category = "";
                    if (e.length !== 0) {
                      e.map((x) => (category += "&categoryIds=" + x.value));
                      setCategories(category);
                    } else {
                      setCategories(``);
                    }
                  }}
                />
              </Col>
              <Col className="col-12  mt-4">
                <Row className="justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 mt-4 row row-cols-2 row-cols-md-4 justify-content-start">
                <h5>
                  {translate("resources.root.totalDiscountPrice")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalDiscountPrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalDiscountPrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalReturnPrice")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalReturnPrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalReturnPrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalSellPrice")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalSellPrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalSellPrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalProfit")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalProfit?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalProfit?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>

                <h5>
                  {translate("resources.root.totalSellQty")}:&nbsp;
                  {parseFloat(total?.totalSellQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(total?.totalSellSmallQty)?.toLocaleString()}
                </h5>

                <h5>
                  {translate("resources.root.totalSellGiftQty")}:&nbsp;
                  {parseFloat(total?.totalSellGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(total?.totalSellSmallGiftQty)?.toLocaleString()}
                </h5>

                <h5>
                  {translate("resources.root.totalReturnQty")}:&nbsp;
                  {parseFloat(total?.totalReturnQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(total?.totalReturnSmallQty)?.toLocaleString()}
                </h5>

                <h5>
                  {translate("resources.root.totalDamage")}:&nbsp;
                  {showInUSD
                    ? `${parseFloat(
                        total?.totalDamagePrice?.mc
                      )?.toLocaleString()} ${translate("resources.root.usd")}`
                    : `${parseFloat(
                        total?.totalDamagePrice?.sc
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                </h5>
              </Col>
              <Col className="col-12 row row-cols-2 row-cols-md-3 row-cols-lg-5 p-0 mt-4 justify-content-start ">
                <InputGroup className="row justify-content-start mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setShowInUSD(e.target.checked)}
                        defaultChecked={showInUSD}
                      />
                    }
                    label={translate("resources.root.showInUSD")}
                  />
                </InputGroup>
                {switches.map((switcher, index) => {
                  return (
                    <InputGroup
                      key={index}
                      className="row justify-content-start mx-md-5 mx-lg-0  "
                    >
                      <FormControlLabel
                        className="m-0 text-nowrap "
                        control={
                          <Switch
                            onChange={(e) => {
                              let updatedSwitches = switches.map((item, i) => {
                                if (i === index) {
                                  return { ...item, value: e.target.checked };
                                }
                                return item;
                              });
                              setSwitches(updatedSwitches);
                            }}
                            defaultChecked={switcher.value}
                          />
                        }
                        label={translate(`resources.root.${switcher.title}`)}
                      />
                    </InputGroup>
                  );
                })}
              </Col>
              <Col className="col-12 row">
                <Col className="col-4  mt-4">
                  <Button
                    id="export"
                    variant={"primary btn-block"}
                    onClick={() => {
                      toCsv(
                        document.getElementById("exportableTable"),
                        "download.csv"
                      );
                    }}
                  >
                    {translate("resources.root.exportCsv")}
                  </Button>
                </Col>
                <Col className="col-4  mt-4">
                  <Button
                    variant={"outline-primary btn-block"}
                    onClick={() => {
                      let sidebar =
                        document.getElementsByClassName("MuiDrawer-root");
                      let appMenu =
                        document.getElementsByClassName("MuiPaper-root");
                      let appBar = document.getElementsByClassName("theRoot");
                      let table = document.getElementsByTagName("table");
                      sidebar[0].style.display = "none";
                      appMenu[0].style.display = "none";
                      appBar[0].style.marginTop = "0px";
                      table[0].style.fontSize = "12px";
                      window.print();
                      sidebar[0].style.display = "block";
                      appMenu[0].style.display = "block";
                      appBar[0].style.marginTop = "40px";
                      table[0].style.fontSize = "15px";
                    }}
                  >
                    <FaPrint /> {translate("resources.root.print")}{" "}
                  </Button>
                </Col>
                <Col className="col-4  mt-4">
                  <Button
                    variant={"primary btn-block"}
                    onClick={() => {
                      setIsLoading(true);
                      getData();
                    }}
                  >
                    {translate(
                      `resources.root.${isLoading ? "isLoading" : "search"}`
                    )}
                  </Button>
                </Col>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {switches.map((switcher) => {
                      return (
                        switcher.value && (
                          <th>
                            {translate(`resources.root.${switcher.title}`)}
                          </th>
                        )
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          <td>
                            {one.productName.length > 50
                              ? one.productName.substring(0, 50).concat("...")
                              : one.productName}
                          </td>
                          <td>{one.productBarcode ?? "-"}</td>

                          {switches[0].value && (
                            <td>
                              {one.sellQty ?? "0"} +{" "}
                              {one.smallMeasureSellQty ?? "0"}
                            </td>
                          )}
                          {switches[1].value && (
                            <td>
                              {one.sellGiftQty ?? "0"} +{" "}
                              {one.smallMeasureSellGiftQty ?? "0"}
                            </td>
                          )}
                          {switches[2].value && <td>{one.damageQty ?? "0"}</td>}
                          {switches[3].value && (
                            <td>
                              {one.sellReturnQty ?? "0"} +{" "}
                              {one.smallMeasureSellReturnQty ?? "0"}
                            </td>
                          )}
                          {switches[4].value && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[5].value && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[6].value && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[7].value && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[8].value && (
                            <td>
                              <img
                                style={{ width: "80px" }}
                                src={
                                  one.productAttachment !== null
                                    ? `${path}${one.productAttachment}`
                                    : `https://via.placeholder.com/125`
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
