import * as React from "react";
import { useRedirect, useNotify, useTranslate } from "react-admin";
import { url } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import { Form } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Swal from "sweetalert2";

import Tooltip from "@mui/material/Tooltip";

import FormControl from "@mui/material/FormControl";

import InputAdornment from "@mui/material/InputAdornment";

import OutlinedInput from "@mui/material/OutlinedInput";

import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useParams } from "react-router-dom";

const CreatePurchasePreInvoice = (props) => {
  const _ = require("lodash");
  const { id } = useParams();

  const { invoice } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const [supplier, setSuppliers] = React.useState([]);
  const [previousPurchasePrice, setPreviousPurchasePrice] =
    React.useState(null);
  const [selectedSupplier, setSelectedSupplier] = React.useState();
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);

  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderQtt, setOrderQtt] = React.useState(1);
  const [productSerial, setProductSerial] = React.useState(null); // use this to sset the selected serial string
  const [productSerials, setProductSerials] = React.useState([]); // usisng for the list of product serialials
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    invoice === "return"
      ? localStorage.getItem("purchasePreReturnOrdersInvoiceReturnOrders")
        ? JSON.parse(
            localStorage.getItem("purchasePreReturnOrdersInvoiceReturnOrders")
          )
        : []
      : localStorage.getItem("purchasePreOrders")
      ? JSON.parse(localStorage.getItem("purchasePreOrders"))
      : []
  );
  if (invoice === "return") {
    localStorage.setItem(
      "purchasePreReturnOrdersInvoiceReturnOrders",
      JSON.stringify(orders)
    );
  } else {
    localStorage.setItem("purchasePreOrders", JSON.stringify(orders));
  }

  const [totals, setTotals] = React.useState({});
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);

  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [orderDiscount, setOrderDiscount] = React.useState(0);
  const [orderDiscountPercentage, setOrderDiscountPercentage] =
    React.useState(0);
  const [orderDiscountInPercent, setOrderDiscountInPercent] =
    React.useState(true);
  const [gift, setGift] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [invoiceNote, setInvoiceNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const redirect = useRedirect();
  const idemPotent = uuidv4();

  React.useEffect(() => {
    HTTP.get(`${url}/suppliers?_end=25000`).then((res) => {
      setSuppliers(res.data);
    });

    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    setProductSerials([]);
    setProductSerial(null);
    if (selectedProduct != null && selectedProduct?.id != undefined) {
      HTTP.get(`${url}/ProductSerials/${selectedProduct?.id}`).then((res) => {
        setProductSerials(res.data);
      });
    }
    selectedProduct &&
      selectedSupplier &&
      HTTP.get(
        `${url}/PurchasePreInvoices/GetLastPriceOfProduct?supplierId=${selectedSupplier?.id}&productId=${selectedProduct?.id}`
      ).then((res) => {
        setPreviousPurchasePrice(res.data);
      });
  }, [selectedSupplier, selectedProduct]);
  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  function roundToTwo(num, to = 3) {
    return +(Math.round(num + "e+" + to) + "e-" + to);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=100&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };
  const supplierLoadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/suppliers?_end=100&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((supplier) => {
        newArray.push({
          value: supplier.id,
          label: `${supplier.supplierName} |  ${supplier.phone}`,
          supplier: supplier,
        });
      });
      callback(newArray);
    }
  };
  const addOrderToList = () => {
    const dollarPrice = localStorage.getItem("dollarPrice") || 1450;

    if (parseFloat(orderQtt) > 0 || parseFloat(gift) > 0) {
      const found = orders.find(
        (el) => el.productId === parseInt(selectedProduct?.id)
      );
      let newOrders = [];
      if (found) {
        newOrders = orders.map(
          (el) =>
            el.productId === selectedProduct?.id && {
              productId: selectedProduct?.id,

              giftQte: el.giftQte + gift,

              barcode: selectedProduct.barcode,

              name: selectedProduct.name,

              note: note,

              productSerial: {
                serialNumber: productSerial,
              },

              qte: parseFloat(orderQtt) + parseFloat(el.qte ? el.qte : 0),

              mcPurchasePrice:
                (selectedSupplier?.payLoanByMain
                  ? modifiedPrice
                  : modifiedPrice / dollarPrice) || 0,
              scPurchasePrice:
                (selectedSupplier?.payLoanByMain
                  ? modifiedPrice * dollarPrice
                  : modifiedPrice) || 0,

              mcBasePurchasePrice: selectedProduct.mcPurchasePrice,
              scBasePurchasePrice: selectedProduct.scPurchasePrice,

              mcBaseTotalPrice: selectedProduct.mcTotalPrice,
              scBaseTotalPrice: selectedProduct.scTotalPrice,

              mcSellPrice: selectedProduct.mcSellPrice,
              scSellPrice: selectedProduct.scSellPrice,

              mcTotalPrice:
                ((selectedSupplier?.payLoanByMain
                  ? modifiedPrice
                  : modifiedPrice / dollarPrice) || 0) *
                (parseFloat(orderQtt) + parseFloat(el.qte ? el.qte : 0)),

              scTotalPrice:
                ((selectedSupplier?.payLoanByMain
                  ? modifiedPrice * dollarPrice
                  : modifiedPrice) || 0) *
                (parseFloat(orderQtt) + parseFloat(el.qte ? el.qte : 0)),

              mcDiscount:
                parseFloat(el.mcDiscount) +
                parseFloat(
                  selectedSupplier?.payLoanByMain
                    ? orderDiscount
                    : orderDiscount * dollarPrice
                ),
              scDiscount:
                parseFloat(el.scDiscount) +
                parseFloat(
                  selectedSupplier?.payLoanByMain
                    ? orderDiscount / dollarPrice
                    : orderDiscount
                ),
            }
        );
        newOrders = [
          ...orders.filter((el) => el.productId !== selectedProduct?.id),
          ...newOrders.filter((el) => el !== false),
        ];
      } else {
        newOrders = [
          ...orders,
          {
            productId: selectedProduct?.id,

            giftQte: gift,

            barcode: selectedProduct.barcode,

            name: selectedProduct.name,

            note: note,

            productSerial: {
              serialNumber: productSerial,
            },

            qte: parseFloat(orderQtt),

            mcPurchasePrice:
              (selectedSupplier?.payLoanByMain
                ? modifiedPrice
                : modifiedPrice / dollarPrice) || 0,
            scPurchasePrice:
              (selectedSupplier?.payLoanByMain
                ? modifiedPrice * dollarPrice
                : modifiedPrice) || 0,

            mcBaseTotalPrice:
              ((selectedSupplier?.payLoanByMain
                ? modifiedPrice
                : modifiedPrice / dollarPrice) || 0) *
              (parseFloat(orderQtt) || 0),
            scBaseTotalPrice:
              ((selectedSupplier?.payLoanByMain
                ? modifiedPrice * dollarPrice
                : modifiedPrice) || 0) * (parseFloat(orderQtt) || 0),

            mcBasePurchasePrice:
              (selectedSupplier?.payLoanByMain
                ? modifiedPrice
                : modifiedPrice / dollarPrice) || 0,
            scBasePurchasePrice:
              (selectedSupplier?.payLoanByMain
                ? modifiedPrice * dollarPrice
                : modifiedPrice) || 0,

            mcSellPrice: selectedProduct.mcSellPrice,
            scSellPrice: selectedProduct.scSellPrice,

            mcTotalPrice:
              ((selectedSupplier?.payLoanByMain
                ? modifiedPrice
                : modifiedPrice / dollarPrice) || 0) *
              (parseFloat(orderQtt) || 0),

            scTotalPrice:
              ((selectedSupplier?.payLoanByMain
                ? modifiedPrice * dollarPrice
                : modifiedPrice) || 0) * (parseFloat(orderQtt) || 0),

            mcDiscount: selectedSupplier?.payLoanByMain
              ? orderDiscount
              : orderDiscount / dollarPrice,
            scDiscount: selectedSupplier?.payLoanByMain
              ? orderDiscount * dollarPrice
              : orderDiscount,
          },
        ];
      }

      setOrders(newOrders);
    }

    if (localStorage.getItem("updateSellingPrice") === "true") {
      HTTP.post(
        `${url}/Products/UpdateProductPrice?byMain=${true}&productId=${
          selectedProduct?.id
        }&scPrice=${(
          selectedProduct.scPurchasePrice * localStorage.getItem("dollarPrice")
        ).toFixed(3)}&mcPrice=${selectedProduct.mcPurchasePrice.toFixed(3)}`,
        {}
      );
    }

    setOrderQtt(1);
    setOrderToEdit(-1);
    setModifiedPrice(0);
    setGift(0);
    setNote("");
    setDelay(false);
    setSelectedProduct(null);
  };
  const changeTotals = () => {
    setTotals({
      ...totals,
      mcTotalPrice: _.sumBy(orders, ({ mcTotalPrice = 0 }) => {
        return roundToTwo(mcTotalPrice);
      }),
      scTotalPrice: _.sumBy(orders, ({ scTotalPrice = 0 }) => {
        return roundToTwo(scTotalPrice);
      }),
      mcDiscount: _.sumBy(orders, ({ mcDiscount = 0 }) => {
        return roundToTwo(mcDiscount);
      }),
      scDiscount: _.sumBy(orders, ({ scDiscount = 0 }) => {
        return roundToTwo(scDiscount);
      }),
      mcFinalBasePrice: roundToTwo(
        _.sumBy(orders, ({ mcBaseTotalPrice = 0, mcDiscount = 0 }) => {
          return mcBaseTotalPrice - mcDiscount;
        })
      ),
      scFinalBasePrice: roundToTwo(
        _.sumBy(orders, ({ scBaseTotalPrice = 0, scDiscount = 0 }) => {
          return scBaseTotalPrice - scDiscount;
        })
      ),
      mcFinalPrice: roundToTwo(
        _.sumBy(orders, ({ mcTotalPrice = 0, mcDiscount = 0 }) => {
          return mcTotalPrice - mcDiscount;
        })
      ),
      scFinalPrice: roundToTwo(
        _.sumBy(orders, ({ scTotalPrice = 0, scDiscount = 0 }) => {
          return scTotalPrice - scDiscount;
        })
      ),
      totalQte: _.sumBy(orders, ({ qte = 0 }) => {
        return qte;
      }),
      totalGiftQte: _.sumBy(orders, ({ giftQte = 0 }) => {
        return giftQte;
      }),
    });
  };
  React.useEffect(() => {
    changeTotals();
  }, [orders, discount]);
  const changeCol = (id, newValue, col) => {
    const dollarPrice = localStorage.getItem("dollarPrice") || 1450;

    let selectedOrder = orders.find((el) => el.productId === id);
    let selectedOrderIndex = orders.indexOf(selectedOrder);

    selectedOrder[col] = newValue;
    if (selectedSupplier?.payLoanByMain) {
      selectedOrder.mcTotalPrice =
        selectedOrder.qte * selectedOrder.mcPurchasePrice;
      selectedOrder.scPurchasePrice =
        selectedOrder.mcPurchasePrice * dollarPrice;
      selectedOrder.scTotalPrice =
        selectedOrder.scPurchasePrice * selectedOrder.qte;
    } else {
      selectedOrder.scTotalPrice =
        selectedOrder.qte * selectedOrder.scPurchasePrice;
      selectedOrder.mcPurchasePrice =
        selectedOrder.scPurchasePrice / dollarPrice;
      selectedOrder.mcTotalPrice =
        selectedOrder.mcPurchasePrice * selectedOrder.qte;
    }

    let newOrders = orders.filter((x) => x.productId !== id);

    newOrders = [
      ...newOrders.slice(0, selectedOrderIndex),
      selectedOrder,
      ...newOrders.slice(selectedOrderIndex),
    ];
    setOrders(newOrders);
  };
  const handleGiftDistribute = () => {
    let newQty = parseFloat(orderQtt) + parseFloat(gift);
    let totalPurchasePrice = parseFloat(orderQtt) * parseFloat(modifiedPrice);
    let newPriceOfPurchase = totalPurchasePrice / newQty;
    setOrderQtt(newQty);
    setModifiedPrice(newPriceOfPurchase);
    setGift(0);
  };
  const distributeTaxOtherTransport = () => {
    orders?.map((el) => {
      console.log(el);
      el.scPurchasePrice =
        (el.scBasePurchasePrice * el.qte -
          // el.scDiscount -
          el.scBasePurchasePrice *
            el.qte *
            //  - el.scDiscount
            (percentageDiscount / 100)) /
        el.qte;

      el.scTotalPrice = el.qte * el.scPurchasePrice;
      el.scBaseTotalPrice = el.qte * el.scBasePurchasePrice;

      el.mcPurchasePrice =
        (el.mcBasePurchasePrice * el.qte -
          // parseFloat(el.mcDiscount) -
          el.mcBasePurchasePrice *
            // - parseFloat(el.mcDiscount)
            el.qte *
            (percentageDiscount / 100)) /
        el.qte;
      console.log(el.mcPurchasePrice, "percentageDiscount");

      el.mcTotalPrice = el.qte * el.mcPurchasePrice;
      el.mcBaseTotalPrice = el.qte * el.mcBasePurchasePrice;
    });

    let newMcTotalPrice = _.sumBy(orders, ({ mcTotalPrice = 0 }) => {
      return roundToTwo(mcTotalPrice);
    });
    let newScTotalPrice = _.sumBy(orders, ({ scTotalPrice = 0 }) => {
      return roundToTwo(scTotalPrice);
    });

    changeTotals();
  };

  React.useEffect(() => {
    if (id) {
      HTTP.get(`/purchasePreInvoices/${id}`).then((response) => {
        setOrders(response.data.purchasePreOrders);
        setSelectedSupplier(response.data.supplier);
      });
    }
  }, []);
  return (
    <main className="d-flex flex-column gap-4 w-100 p-3">
      <section className="d-flex flex-column gap-2">
        <div className="d-flex gap-3 justify-content-center align-items-center">
          {!id && (
            <div className="flex-1">
              {localStorage.getItem("useOldSelect") === "true" ? (
                <Autocomplete
                  style={{ flex: 1 }}
                  className="w-100"
                  id="combo-box-demo"
                  options={supplier}
                  getOptionLabel={(option) =>
                    `${option.supplierName} | ${option.phone}`
                  }
                  onChange={(el, val) => {
                    setDelay(false);
                    setSelectedSupplier(val);

                    setDiscount(0);
                    setPercentageDiscount(0);
                    setInvoiceNumber(val.invoiceNumber ?? "");

                    setAddress(val?.address ?? "");
                    if (selectedProduct) {
                      setModifiedPrice(
                        val?.payLoanByMain
                          ? selectedProduct.mcPurchasePrice
                          : selectedProduct.scPurchasePrice
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      error={submitAttempted && !selectedSupplier}
                      label={translate("resources.root.chooseSupplier")}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  menuPortalTarget={document.body}
                  placeholder={`${translate("resources.root.chooseSupplier")}`}
                  loadOptions={supplierLoadOptions}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(el) => {
                    setDelay(false);
                    setSelectedSupplier(el?.supplier);

                    setDiscount(0);
                    setPercentageDiscount(0);
                    setInvoiceNumber(el?.supplier?.invoiceNumber ?? "");

                    setAddress(el?.supplier?.address ?? "");

                    if (selectedProduct) {
                      setModifiedPrice(
                        el?.supplier?.payLoanByMain
                          ? selectedProduct.mcPurchasePrice
                          : selectedProduct.scPurchasePrice
                      );
                    }
                  }}
                />
              )}
            </div>
          )}
          <Form.Group className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.date")} :
              </Form.Label>
              <Form.Control
                type="date"
                initialValue={selectedDate}
                defaultShow={true}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm:ss"
                onChange={(e) => {
                  setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                }}
              />
            </div>
          </Form.Group>
          <Form.Group className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.invoiceNumber")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.address")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </Form.Group>
          <div>
            {!invoice && (
              <FormControlLabel
                control={
                  <Switch
                    checked={cashPayment}
                    onChange={(e) => {
                      setCashPayment(e.target.checked);
                      setDelay(false);
                    }}
                    color="primary"
                  />
                }
                label={translate("resources.root.cash")}
              />
            )}
          </div>
        </div>
        {selectedSupplier && (
          <div className=" d-flex justify-content-center align-items-center gap-3">
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.name")}:{" "}
                {selectedSupplier?.supplierName}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.phone")}: {selectedSupplier?.phone}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.currentLoan")}:{" "}
                {selectedSupplier?.payLoanByMain ? (
                  <>
                    {selectedSupplier?.mcCurrentLoan?.toLocaleString()}{" "}
                    {translate("resources.root.usd")}
                  </>
                ) : (
                  <>
                    {selectedSupplier?.scCurrentLoan?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </>
                )}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.maxLoan")}:{" "}
                {selectedSupplier?.payLoanByMain ? (
                  <>
                    {selectedSupplier?.mcRoofLoan?.toLocaleString()}{" "}
                    {translate("resources.root.usd")}
                  </>
                ) : (
                  <>
                    {selectedSupplier?.scRoofLoan?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </>
                )}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white">
              <p className="p-0 m-0 ">
                {selectedSupplier &&
                  `${translate("resources.root.futureLoan")} ${(
                    roundToTwo(
                      selectedSupplier?.payLoanByMain
                        ? parseFloat(selectedSupplier?.mcCurrentLoan)
                        : parseFloat(selectedSupplier?.scCurrentLoan)
                    ) +
                    (invoice === "return" ? -1 : 1) *
                      (!cashPayment
                        ? parseFloat(
                            selectedSupplier?.payLoanByMain
                              ? totals?.mcFinalPrice
                              : totals?.scFinalPrice
                          )
                        : 0)
                  )?.toLocaleString()} ${
                    selectedSupplier?.payLoanByMain
                      ? translate("resources.root.usd")
                      : translate("resources.root.iqd")
                  }`}
              </p>
            </div>
          </div>
        )}
      </section>
      {selectedSupplier && (
        <section className="d-flex flex-column justify-content-center align-items-center gap-2 bg-gray-100 p-2 py-5">
          <div className="d-flex gap-2 justify-content-center align-items-center w-100">
            <div className="flex-2">
              {localStorage.getItem("useOldSelect") === "true" ? (
                <Autocomplete
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.barcode}`
                  }
                  onChange={(el, val) => {
                    setSelectedProduct(val);
                    setOrderQtt(0);
                    setModifiedPrice(
                      val
                        ? selectedSupplier?.payLoanByMain
                          ? val.mcPurchasePrice
                          : val.scPurchasePrice
                        : 0
                    );
                    setOrderDiscountPercentage(0);
                    setOrderDiscount(0);
                  }}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      label={translate("resources.root.chooseProduct")}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  menuPortalTarget={document.body}
                  placeholder={`${translate("resources.root.chooseProduct")}`}
                  loadOptions={loadOptions}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    setSelectedProduct(e.product);
                    setOrderQtt(1);
                    setModifiedPrice(
                      e.product
                        ? selectedSupplier?.payLoanByMain
                          ? e.product.mcPurchasePrice
                          : e.product.scPurchasePrice
                        : 0
                    );
                    setOrderDiscountPercentage(0);
                    setOrderDiscount(0);
                  }}
                />
              )}
            </div>
            {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
              <Autocomplete
                className="flex-1"
                id="combo-box-demo"
                options={productSerials}
                getOptionLabel={(option) => `${option.serialNumber}`}
                onChange={(el, val) => {
                  setProductSerial(val?.serialNumber ?? null);
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    label={translate("resources.root.chooseSerial")}
                    variant="outlined"
                  />
                )}
              />
            )}
            <MaterialTextField
              className="flex-1"
              type="number"
              label={translate("resources.root.quantity")}
              variant="outlined"
              disabled={!selectedProduct}
              value={orderQtt}
              onChange={(e) => {
                !invoice
                  ? ((parseFloat(e.target.value) +
                      orders
                        .filter((el) => el.productId === selectedProduct?.d)
                        .map(
                          (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                        )
                        .reduce((a, b) => a + b, 0) +
                      gift <=
                      (selectedProduct &&
                        parseFloat(selectedProduct.qteInStock)) &&
                      e.target.value >= 0) ||
                      selectedProduct.canSellBellowZero) &&
                    setOrderQtt(e.target.value)
                  : setOrderQtt(e.target.value);
              }}
            />
            <MaterialTextField
              className="flex-1"
              type="number"
              disabled={!selectedProduct}
              label={
                selectedSupplier?.payLoanByMain
                  ? translate("resources.root.priceInUSD")
                  : translate("resources.root.priceInIQD")
              }
              value={modifiedPrice}
              onChange={(e) => setModifiedPrice(parseFloat(e.target.value))}
              variant="outlined"
            />
            {/* &nbsp; */}

            <FormControl variant="outlined">
              <InputLabel htmlFor="filled-adornment-password">
                {" "}
                {translate("resources.root.gifts")}
              </InputLabel>
              <OutlinedInput
                id="filled-adornment-password"
                type={"number"}
                label={translate("resources.root.gifts")}
                disabled={!selectedProduct}
                value={gift}
                defaultValue={gift}
                onChange={(e) => {
                  parseFloat(e.target.value) +
                    orders
                      .filter((el) => el.productId === selectedProduct.id)
                      .map((el) => parseFloat(el.qte) + parseFloat(el.giftQte))
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) +
                    parseFloat(e.target.value) >=
                    0 && setGift(parseFloat(e.target.value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title="Distribute the gift over quantity">
                      <IconButton
                        disabled={!selectedProduct}
                        aria-label="toggle password visibility"
                        onClick={handleGiftDistribute}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <AddTaskIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
            {process.env.REACT_APP_ORDER_DISCOUNT !== "true" && (
              <div className="btn btn-outline-primary cursor-normal flex-1">
                <p className="p-0 m-0">
                  {translate("resources.root.totalPrice")}:{" "}
                  {selectedSupplier?.payLoanByMain
                    ? `${(modifiedPrice > 0
                        ? roundToTwo(modifiedPrice * orderQtt) || 0
                        : roundToTwo(
                            selectedProduct?.mcPurchasePrice * orderQtt
                          ) || 0
                      )?.toLocaleString()}
              ${translate("resources.root.usd")}`
                    : `${(modifiedPrice > 0
                        ? roundToTwo(modifiedPrice * orderQtt) || 0
                        : roundToTwo(
                            selectedProduct?.scPurchasePrice * orderQtt
                          ) || 0
                      )?.toLocaleString()}
               ${translate("resources.root.iqd")}`}
                </p>
              </div>
            )}
            {process.env.REACT_APP_ORDER_DISCOUNT !== "true" && (
              <button
                type="button"
                className="btn btn-primary btn-block m-0 flex-1"
                disabled={
                  (parseFloat(orderQtt) <= 0 && parseFloat(gift) <= 0) ||
                  !selectedProduct ||
                  !selectedSupplier
                }
                onClick={addOrderToList}
              >
                {translate("resources.root.addOrder")}
              </button>
            )}
          </div>
          {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
            <div className="d-flex gap-2 justify-content-center align-items-center w-100">
              <FormControlLabel
                control={
                  <Switch
                    checked={orderDiscountInPercent}
                    onChange={(e) =>
                      setOrderDiscountInPercent(e.target.checked)
                    }
                    color="primary"
                  />
                }
                label={translate("resources.root.inPercentage")}
              />
              <MaterialTextField
                className="flex-1"
                type="number"
                disabled={!orderDiscountInPercent || percentageDiscount > 0}
                label={translate("resources.root.discountPer")}
                value={orderDiscountPercentage}
                onChange={(e) => {
                  e.target.value >= 0 &&
                    e.target.value <= 100 &&
                    setOrderDiscountPercentage(e.target.value);
                  setOrderDiscount(
                    (e.target.value / 100) * (modifiedPrice * orderQtt)
                  );
                }}
                variant="outlined"
              />
              <MaterialTextField
                className="flex-1"
                type="number"
                disabled={orderDiscountInPercent || discount > 0}
                label={translate("resources.root.discount")}
                value={orderDiscount}
                onChange={(e) => {
                  e.target.value >= 0 && setOrderDiscount(e.target.value);
                  setOrderDiscountPercentage(
                    (e.target.value / (modifiedPrice * orderQtt)) * 100
                  );
                }}
                variant="outlined"
              />
              <div className="btn btn-outline-primary cursor-normal flex-1">
                <p className="p-0 m-0">
                  {translate("resources.root.totalPrice")}:{" "}
                  {selectedSupplier?.payLoanByMain
                    ? `${(modifiedPrice > 0
                        ? roundToTwo(
                            modifiedPrice * orderQtt - orderDiscount
                          ) || 0
                        : roundToTwo(
                            selectedProduct?.mcPurchasePrice * orderQtt -
                              orderDiscount
                          ) || 0
                      )?.toLocaleString()}
              ${translate("resources.root.usd")}`
                    : `${(modifiedPrice > 0
                        ? roundToTwo(
                            modifiedPrice * orderQtt - orderDiscount
                          ) || 0
                        : roundToTwo(
                            selectedProduct?.scPurchasePrice * orderQtt -
                              orderDiscount
                          ) || 0
                      )?.toLocaleString()}
               ${translate("resources.root.iqd")}`}
                </p>
              </div>

              <button
                type="button"
                className="btn btn-primary btn-block m-0 flex-1"
                disabled={
                  (parseFloat(orderQtt) <= 0 && parseFloat(gift) <= 0) ||
                  !selectedProduct ||
                  !selectedSupplier
                }
                onClick={addOrderToList}
              >
                {translate("resources.root.addOrder")}
              </button>
            </div>
          )}
          {selectedProduct && (
            <div className=" d-flex justify-content-center align-items-center gap-3">
              <div className="rounded p-1 px-3 bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.productMeasure")}:{" "}
                  {selectedProduct.productMeasure}
                </p>
              </div>
              <div className="rounded p-1 px-3 bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.oneMeasure")}:{" "}
                  {selectedProduct.oneMeasure}
                </p>
              </div>
              <div className="rounded p-1 px-3 bg-primary text-white fs-6">
                {translate("resources.root.oneContains")}:{" "}
                {selectedProduct.oneContains}{" "}
                {translate("resources.root.items")}
              </div>

              <div className="rounded p-1 px-3 bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.currency")}:{" "}
                  {selectedSupplier?.payLoanByMain
                    ? translate("resources.root.usd")
                    : translate("resources.root.iqd")}
                </p>
              </div>
              {previousPurchasePrice && (
                <div className="rounded p-1 px-3 bg-success text-white fs-6">
                  <p className="p-0 m-0 ">
                    {translate("resources.root.previousPurchasePrice")}:{" "}
                    {selectedSupplier?.payLoanByMain
                      ? `${previousPurchasePrice.mcPurchasePrice?.toLocaleString()} ${translate(
                          "resources.root.usd"
                        )}`
                      : `${previousPurchasePrice.scPurchasePrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </p>
                </div>
              )}
              <div className="rounded p-1 px-3 bg-primary text-white">
                <p className="p-0 m-0 ">
                  {translate("resources.root.purchasePrice")}:{" "}
                  {selectedSupplier?.payLoanByMain
                    ? `${selectedProduct.mcPurchasePrice?.toLocaleString()} ${translate(
                        "resources.root.usd"
                      )}`
                    : `${selectedProduct.scPurchasePrice?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`}
                </p>
              </div>
              <div className="rounded p-1 px-3 bg-primary text-white">
                <p className="p-0 m-0 ">
                  {translate("resources.root.max")}:{" "}
                  {selectedProduct.qteInStock?.toLocaleString()}
                </p>
              </div>
            </div>
          )}
          <MaterialTextField
            variant="outlined"
            className="w-100 text-dark flex-1"
            type="text"
            label={translate("resources.root.note")}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline={true}
          />
        </section>
      )}
      {orders?.length > 0 && (
        <section className="d-flex flex-column gap-2">
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{translate("resources.root.id")}</TableCell>
                  <TableCell>{translate("resources.root.barcode")}</TableCell>
                  <TableCell align="start">
                    {translate("resources.root.name")}
                  </TableCell>
                  {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                    <TableCell align="start">
                      {translate("resources.root.serial")}
                    </TableCell>
                  )}
                  <TableCell align="start">
                    {translate("resources.root.quantity")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.gifts")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.price")}
                  </TableCell>

                  <TableCell align="start">
                    {translate("resources.root.sellPrice")}
                  </TableCell>
                  {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                    <TableCell align="start">
                      {translate("resources.root.discount")}
                    </TableCell>
                  )}
                  <TableCell align="start">
                    {translate("resources.root.totalPrice")}
                  </TableCell>
                  {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                    <TableCell align="start">
                      {translate("resources.root.finalPrice")}
                    </TableCell>
                  )}
                  <TableCell align="start">
                    {translate("resources.root.note")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders &&
                  orders.map((order, index) => (
                    <TableRow
                      style={{
                        backgroundColor:
                          order.mcPurchasePrice > order.mcSellPrice &&
                          "#FA5C5C",
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {order?.productId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {order?.product?.barcode || order?.barcode}
                      </TableCell>
                      <TableCell align="start">
                        {order?.product?.name || order?.name}
                      </TableCell>
                      {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                        <TableCell align="start">
                          {order.productSerial?.serialNumber}
                        </TableCell>
                      )}
                      <TableCell
                        onDoubleClick={() => {
                          if (selectedSupplier) {
                            Swal.fire({
                              title: "Change order Quantity",
                              input: "number",
                              inputValue: order.qte,
                              showCancelButton: true,
                              focusConfirm: true,
                              confirmButtonColor: "#117baf",
                              cancelButtonColor: "#B71316",
                              inputValidator: (value) => {
                                if (!value) {
                                  return "You need to write something!";
                                }
                              },
                              confirmButtonText: translate(
                                "resources.root.submit"
                              ),

                              preConfirm: (value) => {
                                changeCol(
                                  order.productId,
                                  parseFloat(value),
                                  "qte"
                                );
                              },
                            });
                          } else {
                            Swal.fire({
                              title: "Pleae Select the Customer",
                              text: "You can't change the item details unless you select the supplier",
                              icon: "warning",
                            });
                          }
                        }}
                        align="start"
                      >
                        {order.qte}
                      </TableCell>
                      <TableCell align="start">{order.giftQte}</TableCell>
                      <TableCell
                        onDoubleClick={() => {
                          if (selectedSupplier) {
                            Swal.fire({
                              title: "Change order purchase Price",
                              input: "number",
                              inputValue: selectedSupplier?.payLoanByMain
                                ? order.mcPurchasePrice
                                : order.scPurchasePrice,
                              showCancelButton: true,
                              focusConfirm: true,
                              confirmButtonColor: "#117baf",
                              cancelButtonColor: "#B71316",
                              inputValidator: (value) => {
                                if (!value) {
                                  return "You need to write something!";
                                }
                              },
                              confirmButtonText: translate(
                                "resources.root.submit"
                              ),

                              preConfirm: (value) => {
                                changeCol(
                                  order.productId,
                                  parseFloat(value),
                                  selectedSupplier?.payLoanByMain
                                    ? "mcPurchasePrice"
                                    : "scPurchasePrice"
                                );
                              },
                            });
                          } else {
                            Swal.fire({
                              title: "Pleae Select the Customer",
                              text: "You can't change the item details unless you select the supplier",
                              icon: "warning",
                            });
                          }
                        }}
                        align="start"
                      >
                        {selectedSupplier?.payLoanByMain
                          ? roundToTwo(order.mcPurchasePrice)?.toLocaleString()
                          : roundToTwo(
                              order.scPurchasePrice
                            )?.toLocaleString()}{" "}
                      </TableCell>

                      <TableCell align="start">
                        {selectedSupplier?.payLoanByMain
                          ? roundToTwo(order.mcSellPrice, 3)?.toLocaleString()
                          : roundToTwo(order.scSellPrice, 3)?.toLocaleString()}
                      </TableCell>
                      {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                        <TableCell align="start">
                          {selectedSupplier?.payLoanByMain
                            ? roundToTwo(order.mcDiscount)?.toLocaleString()
                            : roundToTwo(order.scDiscount)?.toLocaleString()}
                        </TableCell>
                      )}
                      <TableCell align="start">
                        {selectedSupplier?.payLoanByMain
                          ? roundToTwo(
                              order?.mcPurchasePrice * order.qte || 0
                            )?.toLocaleString()
                          : roundToTwo(
                              order?.scPurchasePrice * order.qte || 0
                            )?.toLocaleString()}
                      </TableCell>
                      {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                        <TableCell align="start">
                          {selectedSupplier?.payLoanByMain
                            ? roundToTwo(
                                order?.mcPurchasePrice * order.qte -
                                  order.mcDiscount
                              )?.toLocaleString()
                            : roundToTwo(
                                order?.scPurchasePrice * order.qte -
                                  order.scDiscount
                              )?.toLocaleString()}
                        </TableCell>
                      )}
                      <TableCell align="start">{order.note}</TableCell>
                      <TableCell align="start">
                        <DeleteIcon
                          onClick={() => {
                            swal({
                              title: translate("resources.root.makeSure"),
                              text: translate(
                                "resources.root.confirmPerminantDelete"
                              ),
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                let tempOrders = orders;
                                tempOrders = tempOrders.filter(
                                  (el) => el.productId !== order.productId
                                );
                                setOrders(tempOrders);
                              }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className=" d-flex justify-content-center align-items-center gap-3">
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {selectedSupplier?.payLoanByMain
                  ? `${translate("resources.root.totalPrice")}: ${(
                      totals?.mcTotalPrice || 0
                    )?.toLocaleString()} ${translate("resources.root.usd")}`
                  : `${translate("resources.root.totalPrice")}: ${(
                      totals?.scTotalPrice || 0
                    )?.toLocaleString()} ${translate("resources.root.iqd")}`}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {selectedSupplier?.payLoanByMain
                  ? `${translate(
                      "resources.root.finalPrice"
                    )}: ${totals?.mcFinalPrice?.toLocaleString()} ${translate(
                      "resources.root.usd"
                    )}`
                  : `${translate(
                      "resources.root.finalPrice"
                    )}: ${totals?.scFinalPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {`${translate(
                  "resources.root.totalQuantity"
                )}: ${totals?.totalQte?.toLocaleString()}`}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {`${translate(
                  "resources.root.totalGifts"
                )}: ${totals?.totalGiftQte?.toLocaleString()}`}
              </p>
            </div>
            <div className="rounded p-1 px-3 bg-primary text-white">
              <p className="p-0 m-0 ">
                {`${translate("resources.root.finalQuantity")}: ${(
                  totals?.totalQte - totals?.totalGiftQte
                )?.toLocaleString()}`}
              </p>
            </div>
          </div>
        </section>
      )}
      {orders?.length > 0 && (
        <section className="d-flex flex-column justify-content-between bg-gray-100 p-2 py-5 gap-3">
          <div className=" d-flex justify-content-center align-items-center gap-3 ">
            <MaterialTextField
              type="number"
              focused
              className="flex-1"
              label={translate("resources.root.discountInPercentage")}
              value={parseFloat(percentageDiscount)}
              variant="outlined"
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  setPercentageDiscount(e.target.value);
                  setDiscount(
                    (roundToTwo(
                      parseFloat(
                        selectedSupplier?.payLoanByMain
                          ? totals?.mcFinalBasePrice
                          : totals?.scFinalBasePrice
                      )
                    ) /
                      100) *
                      e.target.value
                  );
                }
              }}
            />
            <MaterialTextField
              type="number"
              focused
              className="flex-1"
              variant="outlined"
              label={translate("resources.root.discount")}
              value={parseFloat(discount)}
              onChange={(e) => {
                if (
                  e.target.value >= 0 &&
                  e.target.value <=
                    parseFloat(
                      selectedSupplier?.payLoanByMain
                        ? totals?.mcFinalBasePrice
                        : totals?.scFinalBasePrice
                    )
                ) {
                  setDiscount(e.target.value);
                  setPercentageDiscount(
                    parseFloat(
                      (e.target.value * 100) /
                        parseFloat(
                          selectedSupplier?.payLoanByMain
                            ? totals?.mcFinalBasePrice
                            : totals?.scFinalBasePrice
                        )
                    )
                  );
                }
              }}
            />{" "}
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>{" "}
          </div>

          <div className="d-flex justify-content-center align-items-center gap-4">
            <Tooltip
              title={translate("resources.root.restoreDiscountToOriginal")}
            >
              <button
                type="button"
                className="btn btn-outline-danger btn-block my-2"
                disabled={delay}
                onClick={() => {
                  let oldTotal = orders
                    .map(
                      (el) =>
                        parseFloat(el.mcPurchasePrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0);
                  orders.map((el) => {
                    el.mcPurchasePrice = parseFloat(el.mcBasePurchasePrice);
                    el.mcTotalPrice =
                      parseFloat(el.qte) * parseFloat(el.mcPurchasePrice);
                  });
                  let newTotal = orders
                    .map(
                      (el) =>
                        parseFloat(el.mcPurchasePrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0);
                  if (oldTotal === newTotal) {
                    return;
                  }
                  // TODO: update the state and Restore Gift Qte as well
                  setPercentageDiscount(100 - (oldTotal / newTotal) * 100);
                }}
              >
                {translate("resources.root.restore")}
              </button>
            </Tooltip>{" "}
          </div>
        </section>
      )}
      <section className="d-flex flex-column gap-2">
        <div className=" d-flex justify-content-center align-items-center gap-3 ">
          <MaterialTextField
            className="flex-1"
            variant="outlined"
            label={translate("resources.root.note")}
            value={invoiceNote}
            onChange={(e) => setInvoiceNote(e.target.value)}
          />
          <MaterialTextField
            className="flex-1"
            variant="outlined"
            label={
              process.env.REACT_APP_APP_NAME === "Techeye" &&
              selectedSupplier?.id === 35
                ? translate("resources.root.supplier")
                : translate("resources.root.driver")
            }
            value={driver}
            onChange={(e) => setDriver(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 w-100">
          <button
            className="btn btn-primary btn-block m-0"
            disabled={delay || !selectedSupplier || orders.length <= 0}
            onClick={() => {
              setSubmitAttempted(true);
              Swal.fire({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("resources.root.no"),
                showDenyButton: true,
                confirmButtonColor: "#1e6fc3",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((result) => {
                const dollarPrice = localStorage.getItem("dollarPrice") || 1450;
                setDelay(true);
                if (result.isConfirmed) {
                  setIsLoading(true);
                  let purchasePreOrders, PrereturnOrders;
                  if (invoice === "return") {
                    PrereturnOrders = orders;
                  } else {
                    purchasePreOrders = orders;
                  }

                  const body = selectedSupplier?.payLoanByMain
                    ? {
                        theirNumber: invoiceNumber,
                        address,
                        supplierId: selectedSupplier?.id,
                        voucherDate: moment(selectedDate).add(3, "hours"),
                        mcTotalPrice: totals?.mcTotalPrice,
                        mcFinalPrice: totals?.mcFinalPrice - discount,
                        isCash: cashPayment,
                        payByMain: true,
                        totalQte: totals?.totalQte,
                        totalGiftQte: totals?.totalGiftQte,
                        mcInvoiceDiscount: parseFloat(discount),
                        mcOrdersDiscount: totals?.mcDiscount,
                        mcFinalDiscount: 0,
                        note: invoiceNote,
                        driver,
                        purchasePreOrders,
                        idemPotentToken: idemPotent,
                        // purchasePreReturnOrders: PrereturnOrders,
                      }
                    : {
                        theirNumber: invoiceNumber,
                        address,
                        supplierId: selectedSupplier?.id,
                        voucherDate: moment(selectedDate).add(3, "hours"),
                        scTotalPrice: totals?.scTotalPrice,
                        scFinalPrice: totals?.scFinalPrice - discount,
                        isCash: cashPayment,
                        payByMain: false,
                        totalQte: totals?.totalQte,
                        totalGiftQte: totals?.totalGiftQte,
                        scInvoiceDiscount: parseFloat(discount),
                        scOrdersDiscount: totals?.scDiscount,
                        scFinalDiscount: 0,
                        note: invoiceNote,
                        driver,
                        purchasePreOrders,
                        idemPotentToken: idemPotent,
                        // purchasePreReturnOrders: PrereturnOrders,
                      };

                  if (id) {
                    HTTP.put(
                      `${url}/${
                        invoice === "return"
                          ? "PurchaseReturnInvoices"
                          : "PurchasePreInvoices"
                      }/${id}`,
                      body
                    )
                      .then((res) => {
                        if (invoice === "return") {
                          localStorage.removeItem(
                            "purchasePreReturnOrdersInvoiceReturnOrders"
                          );
                        } else {
                          localStorage.removeItem("purchasePreOrders");
                        }
                        redirect(
                          invoice !== "return"
                            ? "/PurchasePreInvoices/" + res.data.id + "/show"
                            : "/PurchaseReturnInvoices"
                        );
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        notify(err.response.data.message, {
                          type: "error",
                          undoable: true,
                        });
                      });
                  } else {
                    HTTP.post(
                      `${url}/${
                        invoice === "return"
                          ? "PurchaseReturnInvoices"
                          : "PurchasePreInvoices"
                      }`,
                      body
                    )
                      .then((res) => {
                        if (invoice === "return") {
                          localStorage.removeItem(
                            "purchasePreReturnOrdersInvoiceReturnOrders"
                          );
                        } else {
                          localStorage.removeItem("purchasePreOrders");
                        }
                        redirect(
                          invoice !== "return"
                            ? "/PurchasePreInvoices/" + res.data.id + "/show"
                            : "/PurchaseReturnInvoices"
                        );
                      })
                      .catch((err) => {
                        setIsLoading(false);
                        notify(err.response.data.message, {
                          type: "error",
                          undoable: true,
                        });
                      });
                  }
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </button>
          <button
            className="btn btn-outline-primary btn-block m-0"
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </button>
        </div>{" "}
      </section>

      {isLoading && <LoadingScreen />}
    </main>
  );
};

export default CreatePurchasePreInvoice;
