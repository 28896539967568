import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import lodash from "lodash";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default (SupplierItemReport) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplierObject, setSupplierObject] = React.useState([]);

  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [supplier, setSupplier] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataByType, setDataByType] = React.useState([]);
  const [product, setProduct] = React.useState("All");
  const [products, setProducts] = React.useState([]);

  const [total, setTotal] = React.useState({
    showInUSD: false,
    groupProducts: false,

    mcTotalSellPerPurchasePrice: 0,
    scTotalSellPerPurchasePrice: 0,

    totalPurchaseGiftQty: 0,
    totalPurchaseSmallGiftQty: 0,
    totalSellGiftQty: 0,
    totalSellSmallGiftQty: 0,

    mcTotalSell: 0,
    scTotalSell: 0,
    mcTotalPurchase: 0,
    scTotalPurchase: 0,
  });

  const [switches, setSwitches] = React.useState([
    {
      title: "purchaseQty",
      value: true,
    },
    {
      title: "smallMeasurePurchaseQty",
      value: true,
    },
    {
      title: "sellQty",
      value: true,
    },
    {
      title: "smallMeasureSellQty",
      value: true,
    },
    {
      title: "averagePurchasePrice",
      value: true,
    },
    {
      title: "averagePurchaseOnePrice",
      value: true,
    },
    {
      title: "averageSellPrice",
      value: true,
    },
    {
      title: "averageSellOnePrice",
      value: true,
    },
    {
      title: "totalPurchasePrice",
      value: true,
    },
    {
      title: "totalSellPrice",
      value: true,
    },
    {
      title: "totalSellProfit",
      value: true,
    },
  ]);
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    } else {
      sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
    }

    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    } else {
      sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
    }

    await HTTP.get(
      `${url}/Reports/GetSupplierItemReport?id=${supplier}&_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotal({
          mcTotalSellPerPurchasePrice:
            response.headers.mctotalsellperpurchaseprice,
          scTotalSellPerPurchasePrice:
            response.headers.sctotalsellperpurchaseprice,

          totalPurchaseGiftQty: response.headers["totalpurchasegiftqty"],
          totalPurchaseSmallGiftQty:
            response.headers["totalpurchasesmallgiftqty"],
          totalSellGiftQty: response.headers["totalsellgiftqty"],
          totalSellSmallGiftQty: response.headers["totalsellsmallgiftqty"],

          scTotalPurchase: response.headers.sctotalpurchaseprice,
          mcTotalPurchase: response.headers.mctotalpurchaseprice,
          scTotalSell: response.headers.sctotalsellprice,
          mcTotalSell: response.headers.mctotalsellprice,
        });

        setData(response.data);
        setDataByType(response.data);

        let prod = [{ value: "All", label: "All" }];
        lodash
          .uniqBy(response.data, "productName")
          .map((e) =>
            prod.push({ value: e, label: e.productName, id: e.productId })
          );
        setProducts(prod);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Suppliers?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((supplier) => {
        newArray.push({
          value: supplier.id,
          label: `${supplier.supplierName} (${supplier.phone.replace(
            "+964",
            "0"
          )})`,
          supplier: supplier,
        });
      });
      callback(newArray);
    }
  };
  const loadOptionsProducts = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let newArray = [];
      products.map((product) => {
        newArray.push({
          value: product.id,
          label: product.label,
          product: product.value,
        });
      });
      console.log(newArray);
      callback(newArray);
    }
  };

  return (
    <div className="m-2">
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierItemReport")}
              {supplier === 0 ? "" : " (" + supplierObject.supplierName + ")"}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }}>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.suppliers")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="w-100"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setSupplier(e ? e.value : 0);
                    setSupplierObject(e ? e.supplier : 0);
                  }}
                />
              </Col>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 mt-4">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.products")} :
                </p>
                <AsyncSelect
                  className="w-100"
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  loadOptions={loadOptionsProducts}
                  onChange={(e) => {
                    setData(
                      e.label !== "All"
                        ? e.value !== product
                          ? dataByType.filter((x) => x.productName === e.label)
                          : data
                        : dataByType
                    );
                    setProduct(e.value);
                    console.log("dataByType", dataByType);
                    console.log("e", e);
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-8 mt-4">
                <Row className="justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mt-2 mt-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="col-6 col-lg-2 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => {
                          setTotal({
                            ...total,
                            groupProducts: e.target.checked,
                          });
                          total.groupProducts &&
                            setData(
                              lodash
                                .chain(data)
                                .groupBy("productId")
                                .map((value, key) => {
                                  let mcAveragePurchaseOnePriceList = value;
                                  let mcAveragePurchasePriceList = value;
                                  let mcAverageSellOnePriceList = value;
                                  let mcAverageSellPriceList = value;
                                  let scAveragePurchaseOnePriceList = value;
                                  let scAveragePurchasePriceList = value;
                                  let scAverageSellOnePriceList = value;
                                  let scAverageSellPriceList = value;

                                  mcAveragePurchaseOnePriceList =
                                    mcAveragePurchaseOnePriceList.filter(
                                      (o) => {
                                        return o.type === "Purchase";
                                      }
                                    );
                                  mcAveragePurchasePriceList =
                                    mcAveragePurchasePriceList.filter((o) => {
                                      return o.type === "Purchase";
                                    });
                                  mcAverageSellOnePriceList =
                                    mcAverageSellOnePriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  mcAverageSellPriceList =
                                    mcAverageSellPriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  scAveragePurchaseOnePriceList =
                                    scAveragePurchaseOnePriceList.filter(
                                      (o) => {
                                        return o.type === "Purchase";
                                      }
                                    );
                                  scAveragePurchasePriceList =
                                    scAveragePurchasePriceList.filter((o) => {
                                      return o.type === "Purchase";
                                    });
                                  scAverageSellOnePriceList =
                                    scAverageSellOnePriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  scAverageSellPriceList =
                                    scAverageSellPriceList.filter((o) => {
                                      return (
                                        o.type === "Quick Sell" ||
                                        o.type === "Sell"
                                      );
                                    });
                                  return {
                                    productId: key,
                                    productName: value[0].productName,
                                    productBarcode: value[0].productBarcode,
                                    smallMeasurePurchaseQty: lodash.sumBy(
                                      value,
                                      "smallMeasurePurchaseQty"
                                    ),
                                    purchaseQty: lodash.sumBy(
                                      value,
                                      "purchaseQty"
                                    ),
                                    sellQty: lodash.sumBy(value, "sellQty"),
                                    smallMeasureSellQty: lodash.sumBy(
                                      value,
                                      "smallMeasureSellQty"
                                    ),
                                    smallMeasurePurchaseReturnQty: lodash.sumBy(
                                      value,
                                      "smallMeasurePurchaseReturnQty"
                                    ),
                                    purchaseReturnQty: lodash.sumBy(
                                      value,
                                      "purchaseReturnQty"
                                    ),
                                    sellReturnQty: lodash.sumBy(
                                      value,
                                      "sellReturnQty"
                                    ),
                                    smallMeasureSellReturnQty: lodash.sumBy(
                                      value,
                                      "smallMeasureSellReturnQty"
                                    ),

                                    ///// type === purchase
                                    mcAveragePurchaseOnePrice: lodash.meanBy(
                                      scAveragePurchaseOnePriceList,
                                      "mcAveragePurchaseOnePrice"
                                    ),
                                    scAveragePurchaseOnePrice: lodash.meanBy(
                                      mcAveragePurchaseOnePriceList,
                                      "scAveragePurchaseOnePrice"
                                    ),

                                    mcAveragePurchasePrice: lodash.meanBy(
                                      mcAveragePurchasePriceList,
                                      "mcAveragePurchasePrice"
                                    ),
                                    scAveragePurchasePrice: lodash.meanBy(
                                      scAveragePurchasePriceList,
                                      "scAveragePurchasePrice"
                                    ),

                                    ///// type === quick sell or sell
                                    mcAverageSellOnePrice: lodash.meanBy(
                                      mcAverageSellOnePriceList,
                                      "mcAverageSellOnePrice"
                                    ),
                                    scAverageSellOnePrice: lodash.meanBy(
                                      scAverageSellOnePriceList,
                                      "scAverageSellOnePrice"
                                    ),

                                    mcAverageSellPrice: lodash.meanBy(
                                      mcAverageSellPriceList,
                                      "mcAverageSellPrice"
                                    ),
                                    scAverageSellPrice: lodash.meanBy(
                                      scAverageSellPriceList,
                                      "scAverageSellPrice"
                                    ),

                                    mcTotalSellPrice: lodash.sumBy(
                                      value,
                                      "mcTotalSellPrice"
                                    ),
                                    scTotalSellPrice: lodash.sumBy(
                                      value,
                                      "scTotalSellPrice"
                                    ),
                                    mcTotalPurchasePrice: lodash.sumBy(
                                      value,
                                      "mcTotalPurchasePrice"
                                    ),
                                    scTotalPurchasePrice: lodash.sumBy(
                                      value,
                                      "scTotalPurchasePrice"
                                    ),
                                    mcTotalSellProfit: lodash.sumBy(
                                      value,
                                      "mcTotalSellProfit"
                                    ),
                                    scTotalSellProfit: lodash.sumBy(
                                      value,
                                      "scTotalSellProfit"
                                    ),
                                    type: "-",
                                  };
                                })
                                .value()
                            );
                        }}
                        defaultChecked={total.groupProducts}
                      />
                    }
                    label={translate("resources.root.groupProducts")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-6 col-lg-2 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) =>
                          setTotal({
                            ...total,
                            showInUSD: e.target.checked,
                          })
                        }
                        defaultChecked={total.showInUSD}
                      />
                    }
                    label={translate("resources.root.showInUSD")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-12 mt-4 row row-cols-2 row-cols-md-4 justify-content-start">
                <h5>
                  {translate("resources.root.totalSellPrice")}: $
                  {parseFloat(total?.mcTotalSell)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalSellPrice")}:{" "}
                  {parseFloat(total?.scTotalSell)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
                <h5>
                  {translate("resources.root.totalPurchasePrice")}: $
                  {parseFloat(total?.mcTotalPurchase)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalPurchasePrice")}:{" "}
                  {parseFloat(total?.scTotalPurchase)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>

                <h5>
                  {translate("resources.root.totalSellPerPurchasePrice")}: $
                  {parseFloat(
                    total?.mcTotalSellPerPurchasePrice
                  )?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalSellPerPurchasePrice")}:{" "}
                  {parseFloat(
                    total?.scTotalSellPerPurchasePrice
                  )?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
                <h5>
                  {translate("resources.root.totalPurchaseGiftQty")}:
                  {parseFloat(total?.totalPurchaseGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(
                    total?.totalPurchaseSmallGiftQty
                  )?.toLocaleString()}{" "}
                </h5>
                <h5>
                  {translate("resources.root.totalSellGiftQty")}:
                  {parseFloat(total?.totalSellGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {parseFloat(
                    total?.totalSellSmallGiftQty
                  )?.toLocaleString()}{" "}
                </h5>
              </Col>

              {switches?.map((switcher, index) => {
                return (
                  <Col key={index} className="col-6 col-lg-2 p-0 mt-4">
                    <InputGroup className="row justify-content-start mx-md-5 mx-lg-0  ">
                      <FormControlLabel
                        className="m-0 text-nowrap "
                        control={
                          <Switch
                            onChange={(e) => {
                              const updatedSwitches = switches.map(
                                (item, i) => {
                                  if (i === index) {
                                    return { ...item, value: e.target.checked };
                                  }
                                  return item;
                                }
                              );
                              setSwitches(updatedSwitches);
                            }}
                            defaultChecked={switcher.value}
                          />
                        }
                        label={translate(`resources.root.${switcher.title}`)}
                      />
                    </InputGroup>
                  </Col>
                );
              })}

              <Col className="col-12 row">
                <Col className="col-4  mt-4">
                  <Button
                    id="export"
                    variant={"primary btn-block"}
                    onClick={() => {
                      toCsv(
                        document.getElementById("exportableTable"),
                        "download.csv"
                      );
                    }}
                  >
                    {translate("resources.root.exportCsv")}
                  </Button>
                </Col>
                <Col className="col-4  mt-4">
                  <Button
                    variant={"outline-primary btn-block"}
                    onClick={() => {
                      let sidebar =
                        document.getElementsByClassName("MuiDrawer-root");
                      let appMenu =
                        document.getElementsByClassName("MuiPaper-root");
                      let appBar = document.getElementsByClassName("theRoot");
                      let table = document.getElementsByTagName("table");
                      sidebar[0].style.display = "none";
                      appMenu[0].style.display = "none";
                      appBar[0].style.marginTop = "0px";
                      table[0].style.fontSize = "12px";
                      window.print();
                      sidebar[0].style.display = "block";
                      appMenu[0].style.display = "block";
                      appBar[0].style.marginTop = "40px";
                      table[0].style.fontSize = "15px";
                    }}
                  >
                    <FaPrint /> {translate("resources.root.print")}{" "}
                  </Button>
                </Col>
                <Col className="col-4  mt-4">
                  <Button
                    variant={"primary btn-block"}
                    onClick={() => {
                      setIsLoading(true);
                      getData();
                    }}
                  >
                    {translate(
                      `resources.root.${isLoading ? "isLoading" : "search"}`
                    )}
                  </Button>
                </Col>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {switches?.map((item, index) => {
                      return (
                        item.value && (
                          <th key={index}>
                            {translate(`resources.root.${item.title}`)}
                          </th>
                        )
                      );
                    })}

                    <th>{translate("resources.root.type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          <td>
                            {one.productName.length > 50
                              ? one.productName.substring(0, 50).concat("...")
                              : one.productName}
                          </td>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.productBarcode ?? "-"}
                            </a>
                          </td>

                          {switches[0].value && (
                            <td>{one.purchaseQty ?? "0"}</td>
                          )}
                          {switches[1].value && (
                            <td>{one.smallMeasurePurchaseQty ?? "0"}</td>
                          )}
                          {switches[2].value && <td>{one.sellQty ?? "0"}</td>}
                          {switches[3].value && (
                            <td>{one.smallMeasureSellQty ?? "0"}</td>
                          )}
                          {switches[4].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[5].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[6].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[7].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[8].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[9].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {switches[10].value && (
                            <td>
                              {total.showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          <td>{one.type ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={parseFloat(totalItems)}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
