import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";

// import Pagination from "@mui/material/Pagination";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(50);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [doctors, setDoctors] = React.useState();
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).add(-1, "M").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  // const [ignoreZero, setIgnoreZero] = React.useState(false);
  // const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  // const [totalQte, setTotalQte] = React.useState(0);
  // const [totalSmallQte, setTotalSmallQte] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    let doctor = ``;

    if (doctors) {
      doctor = `&subLevelId=${doctors}`;
      console.log(doctors, "id");
    } else {
      doctor = ``;
    }

    await HTTP.get(
      `${url}/DoctorPayments?_start=${start}&_end=${end}${sendDates}${doctor}&forReport=${true}`
    )
      .then((response) => {
        setTotalCount(response.headers["x-total-count"]);
        setScTotalPrice(parseFloat(response.headers["sctotalprice"]));
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Doctors?_end=50&search=${inputValue}`
      );
      let newArray = [];
      newArray.push({
        value: 0,
        label: "none",
        productCategory: "none",
      });
      response.data.map((productCategory) => {
        newArray.push({
          value: productCategory.id,
          label: productCategory.name,
          productCategory: productCategory,
        });
      });
      callback(newArray);
    }
  };

  console.log(doctors);

  return (
    <div className="my-5">
      <PrintProvider>
        <Print single name={`customerStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("ra.navigation.DoctorPaymentsReport")}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row className="d-flex align-items-center justify-content-around my-3">
              <Col className="row col-12 col-md-6">
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="startDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2 text-nowrap">
                        {translate("resources.root.startDate")} :
                      </Form.Label>
                      <Form.Control
                        className=""
                        label="startDate"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col className="col-12 col-md-6 mb-3">
                  <Form.Group controlId="endDate">
                    <Col className="d-flex align-items-center m-0 p-0">
                      <Form.Label className="m-0 mr-2  text-nowrap">
                        {translate("resources.root.endDate")} :
                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Col>
              <Col className="col-6 d-flex align-items-center justify-content-start m-0 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.doctor")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1"
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setDoctors(e ? e.value : 0);
                  }}
                />
              </Col>
              <Col className="col-4 pt-3">
                <NoPrint>
                  <Button
                    variant={"outline-primary btn-block"}
                    onClick={() => {
                      let sidebar =
                        document.getElementsByClassName("MuiDrawer-root");
                      let appMenu =
                        document.getElementsByClassName("MuiPaper-root");
                      let appBar = document.getElementsByClassName("theRoot");
                      let table = document.getElementsByTagName("table");
                      sidebar[0].style.display = "none";
                      appMenu[0].style.display = "none";
                      appBar[0].style.marginTop = "0px";
                      table[0].style.fontSize = "12px";
                      window.print();
                      sidebar[0].style.display = "block";
                      appMenu[0].style.display = "block";
                      appBar[0].style.marginTop = "40px";
                      table[0].style.fontSize = "15px";
                    }}
                  >
                    <FaPrint /> {translate("resources.root.print")}{" "}
                  </Button>
                </NoPrint>
              </Col>
              <Col className="col-4 pt-3">
                <Button
                  id="export"
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 pt-3">
                <Button
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    getData();
                  }}
                >
                  {translate("resources.root.search")}
                </Button>
              </Col>
              <Col className="col-12 mt-4">
                <Table bordered hover className="">
                  <thead>
                    <tr>
                      <th>{translate("resources.root.totalVisitCount")}</th>
                      <th>{translate("resources.root.totalPrice")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && (
                      <tr>
                        <td>{totalCount?.toLocaleString()}</td>
                        <td>{scTotalPrice?.toLocaleString()} IQD</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.doctorName")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.forgivePrice")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.accountant")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a
                              href={`#/doctorPayments /${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.id}
                            </a>
                          </td>
                          <td>{one.doctorName}</td>
                          <td>
                            {process.env.REACT_APP_SINGLE_CURRENCY === "true"
                              ? process.env.REACT_APP_SHOW_MC === "true"
                                ? `$${one.mcPrice?.toLocaleString()}`
                                : `${one.scPrice?.toLocaleString()} IQD`
                              : localStorage.getItem("sellMainCurrency") ===
                                "true"
                              ? `$${one.mcPrice?.toLocaleString()}`
                              : `${one.scPrice?.toLocaleString()} IQD`}
                          </td>
                          <td>
                            {process.env.REACT_APP_SINGLE_CURRENCY === "true"
                              ? process.env.REACT_APP_SHOW_MC === "true"
                                ? `$${one.mcForgivePrice?.toLocaleString()}`
                                : `${one.scForgivePrice?.toLocaleString()} IQD`
                              : localStorage.getItem("sellMainCurrency") ===
                                "true"
                              ? `$${one.mcForgivePrice?.toLocaleString()}`
                              : `${one.scForgivePrice?.toLocaleString()} IQD`}
                          </td>
                          <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                          <td>{one.accountant}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>

                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
