export const permissions = {
  //! Dashboard
  dashboard: "permissions.dashboard",
  dashboardCashers: "permissions.dashboard.cashers",
  dashboardSafeboxes: "permissions.dashboard.safeboxes",
  dashboardTabs: "permissions.dashboard.tabs",

  //? product section
  //! product
  productList: "permissions.product.list",
  productCreate: "permissions.product.create",
  productUpdate: "permissions.product.update",
  productDelete: "permissions.product.delete",
  productView: "permissions.product.view",

  //! product Category
  productCategoryList: "permissions.productcategory.list",

  //! product Brand
  productBrandList: "permissions.productbrand.list",

  //! damage Invoice
  damageInvoiceList: "permissions.damageinvoice.list",

  //! damage Type
  damageTypeList: "permissions.damagetype.list",

  //! storeTransfer
  storeTransferList: "permissions.storetransfer.list",
  storeTransferCreate: "permissions.storetransfer.create",
  storeTransferAccept: "permissions.storetransfer.accept",
  storeTransferView: "permissions.storetransfer.view",

  //! process Invoice
  processInvoiceList: "permissions.processinvoice.list",

  //! store inventory Invoice
  storeInventoryInvoiceList: "permissions.storeinventoryinvoice.list",
  storeInventoryInvoiceCreate: "permissions.storeinventoryinvoice.create",
  storeInventoryInvoiceUpdate: "permissions.storeinventoryinvoice.update",
  storeInventoryInvoiceDelete: "permissions.storeinventoryinvoice.delete",
  storeInventoryInvoiceView: "permissions.storeinventoryinvoice.view",

  //? customer section
  //! customer
  customerList: "permissions.customer.list",
  customerCreate: "permissions.customer.create",
  customerUpdate: "permissions.customer.update",
  customerDelete: "permissions.customer.delete",
  customerView: "permissions.customer.view",

  customerVisit: "permissions.customer.visit",
  customerVisitLabTests: "permissions.customer.visit.labtesst",
  customerVisitXRays: "permissions.customer.visit.xrays",
  customerVisitMedItems: "permissions.customer.visit.meditems",
  customerVisitProducts: "permissions.customer.visit.products",

  //! customer payment
  customerPaymentList: "permissions.customerpayment.list",
  customerPaymentCreate: "permissions.customerpayment.create",
  customerPaymentUpdate: "permissions.customerpayment.update",
  customerPaymentDelete: "permissions.customerpayment.delete",
  customerPaymentView: "permissions.customerpayment.view",

  //? sell invoice section
  //! sell Invoice
  sellInvoiceList: "permissions.sellinvoice.list",
  sellInvoiceCreate: "permissions.sellinvoice.create",
  sellInvoiceUpdate: "permissions.sellinvoice.update",
  sellInvoiceDelete: "permissions.sellinvoice.delete",
  sellInvoiceView: "permissions.sellinvoice.view",

  //! sell Return Invoice
  returnSellInvoiceList: "permissions.returnsellinvoice.list",
  returnSellInvoiceCreate: "permissions.returnsellinvoice.create",
  returnSellInvoiceDelete: "permissions.returnsellinvoice.delete",
  returnSellInvoiceView: "permissions.returnsellinvoice.view",

  //? supplier section
  //! supplier
  supplierList: "permissions.supplier.list",
  supplierCreate: "permissions.supplier.create",
  supplierUpdate: "permissions.supplier.update",
  supplierDelete: "permissions.supplier.delete",
  supplierView: "permissions.supplier.view",

  //! supplier payment
  supplierPaymentList: "permissions.supplierpayment.list",
  supplierPaymentCreate: "permissions.supplierpayment.create",
  supplierPaymentUpdate: "permissions.supplierpayment.update",
  supplierPaymentDelete: "permissions.supplierpayment.delete",
  supplierPaymentView: "permissions.supplierpayment.view",

  //? purchase invoice section
  //! purchase Invoice
  purchaseInvoiceList: "permissions.purchaseinvoice.list",
  purchaseInvoiceCreate: "permissions.purchaseinvoice.create",
  purchaseInvoiceUpdate: "permissions.purchaseinvoice.update",
  purchaseInvoiceDelete: "permissions.purchaseinvoice.delete",
  purchaseInvoiceView: "permissions.purchaseinvoice.view",

  //! purchase Return Invoice
  returnPurchaseInvoiceList: "permissions.returnpurchaseinvoice.list",
  returnPurchaseInvoiceCreate: "permissions.returnpurchaseinvoice.create",
  returnPurchaseInvoiceDelete: "permissions.returnpurchaseinvoice.delete",
  returnPurchaseInvoiceView: "permissions.returnsellinvoice.view",

  //? purchasePre invoice section
  //! purchasePre Invoice
  purchasePreInvoiceList: "permissions.purchasepreinvoice.list",
  purchasePreInvoiceCreate: "permissions.purchasepreinvoice.create",
  purchasePreInvoiceUpdate: "permissions.purchasepreinvoice.update",
  purchasePreInvoiceDelete: "permissions.purchasepreinvoice.delete",
  purchasePreInvoiceView: "permissions.purchasepreinvoice.view",

  //? Representative section
  //! Representative
  representativeList: "permissions.representative.list",
  representativeCreate: "permissions.representative.create",
  representativeUpdate: "permissions.representative.update",
  representativeDelete: "permissions.representative.delete",
  representativeView: "permissions.representative.view",

  //? Representative Customer section
  //! Representative Customer
  representativeCustomerList: "permissions.representativecustomer.list",
  representativeCustomerCreate: "permissions.representativecustomer.create",
  representativeCustomerUpdate: "permissions.representativecustomer.update",
  representativeCustomerDelete: "permissions.representativecustomer.delete",
  representativeCustomerView: "permissions.representativecustomer.view",

  //? Cashier section
  //! sells
  cashierSellList: "permissions.cashiersell.list",
  //! Return
  cashierReturnList: "permissions.cashierreturnsell.list",
  //! WithDraws
  cashierWithdrawList: "permissions.cashierwithdraw.list",
  cashierWithdrawView: "permissions.cashierwithdraw.view",
  cashierWithdrawCreate: "permissions.cashierwithdraw.create",
  cashierWithdrawDelete: "permissions.cashierwithdraw.delete",

  //? HR section
  //! HR
  HrList: "permissions.hr.list",

  //? Accountant section
  //! Bank Accounts
  bankAccountList: "permissions.bankaccount.list",
  bankAccountUpdate: "permissions.bankaccount.update",
  bankAccountView: "permissions.bankaccount.view",
  bankAccountViewPermission: "permissions.bankaccount.setpermissions",

  //! Store Transaction Histories
  storeTransactionHistoriesList: "permissions.storetransaction",

  //! Bank account transfer
  bankAccountTransferList: "permissions.bankaccounttransfer.list",
  bankAccountTransferCreate: "permissions.bankaccounttransfer.create",
  bankAccountTransferView: "permissions.bankaccounttransfer.view",

  //! User Store Bank Account Transfer
  userStoreBankAccountTransferList:
    "permissions.userstorebankaccounttransfer.list",
  userStoreBankAccountTransferCreate:
    "permissions.userstorebankaccounttransfer.create",
  userStoreBankAccountTransferView:
    "permissions.userstorebankaccounttransfer.view",

  //! User Store Transaction Histories
  userStoreTransactionHistoriesList: "permissions.userstoretransaction",

  //! Balance Sheet
  balanceSheet: "permissions.balancesheet",

  //! Deposit
  depositList: "permissions.deposit.list",

  //! Withdraw
  withdrawList: "permissions.withdraw.list",

  //! Expense
  expenseList: "permissions.expense.list",
  expenseUpdate: "permissions.expense.update",

  //! Currency exchange
  currencyExchangeList: "permissions.currencyexchange.list",
  currencyExchangeCreate: "permissions.currencyexchange.create",
  currencyExchangeView: "permissions.currencyexchange.view",

  //? Doctor
  //! Doctor
  doctorList: "permissions.doctor.list",
  //! Doctor Payment
  doctorPaymentList: "permissions.doctorpayment.list",
  //! Department
  departmentList: "permissions.department.list",

  //? Medical
  //! LabTest
  labTestList: "permissions.labtest.list",
  labTestCategoryList: "permissions.labtestcategory.list",
  medItemsList: "permissions.meditems.list",
  xRayList: "permissions.xray.list",

  //? Visitor
  //! Visitor
  visitorList: "permissions.visitor.list",

  //? Setting
  //! setting
  setting: "permissions.setting",
  user: "permissions.user",

  //? Reports module
  //! General Report
  generalReport: "permissions.generalreport",

  //! HR Report
  hrReport: "permissions.hrreport",

  //! Item Report
  itemReport: "permissions.itemreport",

  //! Medical Report
  medicalReport: "permissions.medicalreport",

  //! Supplier Report
  supplierReport: "permissions.supplierreport",

  //! Admin Report
  adminReport: "permissions.adminreport",
};
