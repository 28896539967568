import * as React from "react";
import { useTranslate } from "react-admin";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import ExtensionIcon from "@mui/icons-material/Extension";
import ExtensionTwoToneIcon from "@mui/icons-material/ExtensionTwoTone";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import WarningIcon from "@mui/icons-material/Warning";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import TransformIcon from "@mui/icons-material/Transform";
import CheckIcon from "@mui/icons-material/Check";
import LabelIcon from "@mui/icons-material/Label";
import { hasPermission } from "./../App";
import { permissions } from "./Permissions/AllPermission";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ScienceIcon from "@mui/icons-material/Science";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { MdOutlineSick } from "react-icons/md";

export default function NestedList() {
  const translate = useTranslate();

  const [menuItems, setMenuItems] = React.useState([
    // ! Dashboard
    {
      title: "dashboard",
      active: false,
      module: true,
      permissions: [permissions.dashboard],
      route: "",
      icon: <PersonIcon className="IconMenu" />,
    },

    // ! Products
    {
      title: "Products",
      active: false,
      module: true,
      icon: <ExtensionIcon className="IconMenu" />,
      permissions: [
        permissions.productList,
        permissions.productCategoryList,
        permissions.productBrandList,
        permissions.damageInvoiceList,
        permissions.damageTypeList,
        permissions.storeTransferList,
        permissions.processInvoiceList,
        permissions.storeInventoryInvoiceList,
      ],
      subItems: [
        {
          title: "Products",
          route: "Products",
          icon: <ExtensionTwoToneIcon className="IconMenu" />,
          permission: [permissions.productList],
        },
        {
          title: "ProductCategories",
          route: "ProductCategories",
          icon: <CategoryIcon className="IconMenu" />,
          permission: [permissions.productCategoryList],
        },
        {
          title: "Brands",
          route: "Brands",
          icon: <LabelIcon className="IconMenu" />,
          permission: [permissions.productBrandList],
        },
        {
          title: "DamageInvoices",
          route: "DamageInvoices",
          icon: <BrokenImageOutlinedIcon className="IconMenu" />,
          permission: [permissions.damageInvoiceList],
        },
        {
          title: "ProductDamageTypes",
          route: "ProductDamageTypes",
          icon: <BrokenImageIcon className="IconMenu" />,
          permission: [permissions.damageTypeList],
        },
        {
          title: "StoreTransferInvoices",
          route: "StoreTransferInvoices",
          icon: <TransformIcon className="IconMenu" />,
          permission: [permissions.storeTransferList],
        },
        {
          title: "ProcessInvoices",
          route: "ProcessInvoices",
          icon: <CreateNewFolderIcon className="IconMenu" />,
          permission: [permissions.processInvoiceList],
        },
        {
          title: "StoreInventoryInvoice",
          route: "StoreInventoryInvoice",
          icon: <CreateNewFolderIcon className="IconMenu" />,
          permission: [permissions.storeInventoryInvoiceList],
        },
      ],
    },

    // ! Customers
    {
      title: "Customers",
      active: false,
      module: true,
      icon: <PersonIcon className="IconMenu" />,
      permissions: [permissions.customerList, permissions.customerPaymentList],
      subItems: [
        {
          title: "Customers",
          route: "Customers",
          icon: <PersonIcon className="IconMenu" />,
          permission: [permissions.customerList],
        },
        {
          title: "Payments",
          route: "CustomerPayments",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.customerPaymentList],
        },
      ],
    },

    // ! Sells
    {
      title: "Selling",
      active: false,
      module: true,
      icon: <ReceiptIcon className="IconMenu" />,
      permissions: [
        permissions.sellInvoiceList,
        permissions.returnSellInvoiceList,
      ],
      subItems: [
        {
          title: "Selling",
          route: "SellInvoices",
          icon: <ReceiptTwoToneIcon className="IconMenu" />,
          permission: [permissions.sellInvoiceList],
        },
        {
          title: "Return",
          route: "SellReturnInvoices",
          icon: (
            <AssignmentReturnIcon
              className="IconMenu"
              style={{ transform: "scaleX(-1)" }}
            />
          ),
          permission: [permissions.returnSellInvoiceList],
        },
      ],
    },

    // ! Supplier
    {
      title: "Suppliers",
      active: false,
      module: true,
      icon: <BusinessIcon className="IconMenu" />,
      permissions: [permissions.supplierList, permissions.supplierPaymentList],
      subItems: [
        {
          title: "Suppliers",
          route: "Suppliers",
          icon: <PersonIcon className="IconMenu" />,
          permission: [permissions.supplierList],
        },
        {
          title: "Payments",
          route: "SupplierPayments",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.supplierPaymentList],
        },
      ],
    },

    // ! Purchase
    {
      title: "Purchases",
      active: false,
      module: true,
      icon: <ShoppingBasketIcon className="IconMenu" />,
      permissions: [
        permissions.purchaseInvoiceList,
        permissions.returnPurchaseInvoiceList,
      ],
      subItems: [
        {
          title: "Purchases",
          route: "PurchaseInvoices",
          icon: <ReceiptIcon className="IconMenu" />,
          permission: [permissions.purchaseInvoiceList],
        },
        {
          title: "PurchaseReturnInvoices",
          route: "PurchaseReturnInvoices",
          icon: (
            <AssignmentReturnIcon
              className="IconMenu"
              style={{ transform: "scaleX(-1)" }}
            />
          ),
          permission: [permissions.returnPurchaseInvoiceList],
        },
      ],
    },

    // ! PurchasePre
    {
      title: "PurchasesPre",
      active: false,
      module: process.env.REACT_APP_APP_NAME === "IndustryTemp" && true,
      icon: <ShoppingBasketIcon className="IconMenu" />,
      permissions: [permissions.purchasePreInvoiceList],
      subItems: [
        {
          title: "PurchasesPre",
          route: "PurchasePreInvoices",
          icon: <ReceiptIcon className="IconMenu" />,
          permission: [permissions.purchasePreInvoiceList],
        },
      ],
    },

    // ! Representatives
    {
      title: "Representatives",
      active: false,
      module:
        process.env.REACT_APP_REPRESENTATIVE_MODULE === "true" &&
        process.env.REACT_APP_REPRESENTATIVE_MODULE !== undefined
          ? true
          : false,
      icon: <SupervisedUserCircleIcon className="IconMenu" />,
      permissions: [permissions.representativeList],
      subItems: [
        {
          title: "Representatives",
          route: "Representatives",
          icon: <SupervisedUserCircleIcon className="IconMenu" />,
          permission: [permissions.representativeList],
        },
        {
          title: "RepresentativeInvoices",
          route: "RepresentativeInvoices",
          icon: <ReceiptIcon className="IconMenu" />,
          permission: [permissions.representativeList],
        },
        {
          title: "Payments",
          route: "RepresentativePayments",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.representativeList],
        },
        {
          title: "Return",
          route: "RepresentativeReturnInvoices",
          icon: <AssignmentReturnIcon className="IconMenu" />,
          permission: [permissions.representativeList],
        },
        {
          title: "PreInvoices",
          route: "RepresentativePreInvoices",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.representativeList],
        },
      ],
    },

    // ! Representatives Customers
    {
      title: "RepresentativeCustomers",
      active: false,
      module:
        process.env.REACT_APP_REPRESENTATIVE_MODULE === "true" &&
        process.env.REACT_APP_REPRESENTATIVE_MODULE !== undefined
          ? true
          : false,
      icon: <PeopleOutline className="IconMenu" />,
      permissions: [permissions.representativeCustomerList],
      subItems: [
        {
          title: "RepresentativeCustomers",
          route: "RepresentativeCustomers",
          icon: <PersonIcon className="IconMenu" />,
          permission: [permissions.representativeCustomerList],
        },
        {
          title: "Invoices",
          route: "RepresentativeCustomerSellInvoices",
          icon: <ReceiptIcon className="IconMenu" />,
          permission: [permissions.representativeCustomerList],
        },
        {
          title: "Payments",
          route: "RepresentativeCustomerPayments",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.representativeCustomerList],
        },
        {
          title: "Return",
          route: "RepresentativeCustomerReturnInvoices",
          icon: <AssignmentReturnIcon className="IconMenu" />,
          permission: [permissions.representativeCustomerList],
        },
        {
          title: "Checks",
          route: "RepresentativeCustomerChecks",
          icon: <CheckIcon className="IconMenu" />,
          permission: [permissions.representativeCustomerList],
        },
      ],
    },

    // ! Cashier
    {
      title: "Cashiers",
      active: false,
      module:
        process.env.REACT_APP_CASHIER_MODULE === "true" &&
        process.env.REACT_APP_CASHIER_MODULE !== undefined
          ? true
          : false,
      icon: <GroupIcon className="IconMenu" />,
      permissions: [
        permissions.cashierSellList,
        permissions.cashierReturnList,
        permissions.cashierWithdrawList,
      ],
      subItems: [
        {
          title: "Selling",
          route: "QuickSellInvoices",
          icon: <ReceiptIcon className="IconMenu" />,
          permission: [permissions.cashierSellList],
        },
        {
          title: "Return",
          route: "QuickSellReturnInvoices",
          icon: (
            <ReceiptIcon
              className="IconMenu"
              style={{ transform: "scaleX(-1)" }}
            />
          ),
          permission: [permissions.cashierReturnList],
        },
        {
          title: "Withdraws",
          route: "UserStoreBankAccountWithdraws",
          icon: (
            <ReceiptIcon
              className="IconMenu"
              style={{ transform: "scaleX(-1)" }}
            />
          ),
          permission: [permissions.cashierWithdrawList],
        },
      ],
    },

    // ! HR
    {
      title: "HR",
      active: false,
      module:
        process.env.REACT_APP_HR_MODULE === "true" &&
        process.env.REACT_APP_HR_MODULE !== undefined
          ? true
          : false,
      icon: <GroupIcon className="IconMenu" />,
      permissions: [permissions.HrList],
      subItems: [
        {
          title: "Employees",
          route: "Employees",
          icon: <GroupIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeeLoans",
          route: "EmployeeLoans",
          icon: <AccountBalanceWalletIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeeReturnLoans",
          route: "EmployeeReturnLoans",
          icon: <PaymentIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeeAbsents",
          route: "EmployeeAbsents",
          icon: <EventBusyIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeeRewards",
          route: "EmployeeRewards",
          icon: <CardGiftcardIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeePunishments",
          route: "EmployeePunishments",
          icon: <WarningIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "EmployeePermissions",
          route: "EmployeeAllows",
          icon: <EventAvailableIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "Salary",
          route: "EmployeeSalaryPays",
          icon: <ListAltIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
        {
          title: "salaryChange",
          route: "EmployeeSalaryChanges",
          icon: <PlaylistAddCheckIcon className="IconMenu" />,
          permission: [permissions.HrList],
        },
      ],
    },

    // ! Accountant
    {
      title: "Accountant",
      active: false,
      module: true,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [
        permissions.bankAccountList,
        permissions.bankAccountTransferList,
        permissions.balanceSheet,
        permissions.depositList,
        permissions.withdrawList,
        permissions.expenseList,
        permissions.currencyExchangeList,
      ],
      subItems: [
        {
          title: "BankAccounts",
          route: "BankAccounts",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.bankAccountList],
        },
        {
          title: "StoreTransactionHistories",
          route: "StoreTransactionHistories",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.storeTransactionHistoriesList],
        },
        {
          title: "BankAccountTransfers",
          route: "BankAccountTransfers",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.bankAccountTransferList],
        },
        {
          title: "UserStoreBankAccountTransfers",
          route: "UserStoreBankAccountTransfers",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.userStoreBankAccountTransferList],
        },
        {
          title: "UserStoreTransactionHistories",
          route: "UserStoreTransactionHistories",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.userStoreTransactionHistoriesList],
        },
        {
          title: "BalanceSheet",
          route: "BalanceSheet",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.balanceSheet],
        },
        {
          title: "Deposits",
          route: "Deposits",
          icon: <ExitToAppIcon className="IconMenu" />,
          permission: [permissions.depositList],
        },
        {
          title: "Withdraws",
          route: "Withdraws",
          icon: (
            <ExitToAppIcon
              className="IconMenu"
              style={{ transform: "rotate(-180deg)" }}
            />
          ),
          permission: [permissions.withdrawList],
        },
        {
          title: "Expenses",
          route: "Expenses",
          icon: <DescriptionIcon className="IconMenu" />,
          permission: [permissions.expenseList],
        },
        {
          title: "CurrencyExchanges",
          route: "CurrencyExchanges",
          icon: <LocalAtmIcon className="IconMenu" />,
          permission: [permissions.currencyExchangeList],
        },
      ],
    },

    // ! Doctors
    {
      title: "Doctors",
      active: false,
      module: process.env.REACT_APP_APP_NAME === "Hana" ? true : false,
      icon: <PersonAddAlt1Icon className="IconMenu" />,
      permissions: [
        permissions.doctorList,
        permissions.doctorPaymentList,
        permissions.departmentList,
      ],
      subItems: [
        {
          title: "Doctors",
          route: "Doctors",
          icon: <PersonAddAlt1Icon className="IconMenu" />,
          permission: [permissions.doctorList],
        },

        {
          title: "DoctorPayments",
          route: "DoctorPayments",
          icon: <DescriptionIcon className="IconMenu" />,
          permission: [permissions.doctorPaymentList],
        },
        {
          title: "Departments",
          route: "Departments",
          icon: <DescriptionIcon className="IconMenu" />,
          permission: [permissions.departmentList],
        },
      ],
    },
    // ! Doctors
    {
      title: "Medical",
      active: false,
      module: process.env.REACT_APP_APP_NAME === "Hana" ? true : false,
      icon: <ScienceIcon className="IconMenu" />,
      permissions: [
        permissions.labTestList,
        permissions.labTestCategoryList,
        permissions.medItemsList,
        permissions.xRayList,
      ],
      subItems: [
        {
          title: "LabTests",
          route: "LabTests",
          icon: <ScienceIcon className="IconMenu" />,
          permission: [permissions.labTestList],
        },
        {
          title: "LabTestCategories",
          route: "LabTestCategories",
          icon: <ScienceIcon className="IconMenu" />,
          permission: [permissions.labTestCategoryList],
        },
        {
          title: "MedItems",
          route: "MedItems",
          icon: <ScienceIcon className="IconMenu" />,
          permission: [permissions.medItemsList],
        },
        {
          title: "XRays",
          route: "XRays",
          icon: <DescriptionIcon className="IconMenu" />,
          permission: [permissions.xRayList],
        },
      ],
    },
    // ! Visitor
    {
      title: "Visitors",
      active: false,
      module: process.env.REACT_APP_APP_NAME === "Hana" ? true : false,
      icon: <MdOutlineSick className="IconMenu" />,
      permissions: [permissions.visitorList],
      subItems: [
        {
          title: "Visitors",
          route: "CustomerVisits",
          icon: <MdOutlineSick className="IconMenu" />,
          permission: [permissions.visitorList],
        },
      ],
    },
    // ! Settings
    {
      title: "Settings",
      active: false,
      module: true,
      icon: <SettingsApplicationsIcon className="IconMenu" />,
      permissions: [permissions.setting],
      subItems: [
        {
          title: "Stores",
          route: "Stores",
          icon: <StoreIcon className="IconMenu" />,
          permission: [permissions.setting, permissions.user],
        },
        {
          title: "DepositTypes",
          route: "DepositTypes",
          icon: <ExitToAppIcon className="IconMenu" />,
          permission: [permissions.setting],
        },
        {
          title: "WithdrawTypes",
          route: "WithdrawTypes",
          icon: (
            <ExitToAppIcon
              className="IconMenu"
              style={{ transform: "rotate(-180deg)" }}
            />
          ),
          permission: [permissions.setting],
        },

        {
          title: "ExpenseTypes",
          route: "ExpenseTypes",
          icon: <DescriptionIcon className="IconMenu" />,
          permission: [permissions.setting],
        },
        {
          title: "UserNames",
          route: "Users",
          icon: <AssignmentIndIcon className="IconMenu" />,
          permission: [permissions.user],
        },
        {
          title: "uploadCenter",
          route: "UploadCenters",
          icon: (
            <ExitToAppIcon
              className="IconMenu"
              style={{ transform: "rotate(-180deg)" }}
            />
          ),
          permission: [permissions.setting],
        },
      ],
    },

    // ! General Reports
    {
      title: "GeneralReports",
      active: false,
      module: true,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [permissions.generalReport],
      subItems: [
        {
          title: "OrdersReport",
          route: "Reports/OrdersReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "CustomerStatement",
          route: "Reports/CustomerStatement",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "CashierSellReport",
          route: "Reports/CashierSellsReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "RepresentativeCustomerSellInvoicesReport",
          route: "Reports/RepresentativeCustomerSellInvoicesReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "RepresentativeStatement",
          route: "Reports/RepresentativeStatement",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "ExpireReport",
          route: "Reports/ExpireReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "SellInvoiceReport",
          route: "Reports/SellInvoiceReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "NonPaidSellInvoiceReport",
          route: "Reports/NonPaidSellInvoiceReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "PurchaseReport",
          route: "Reports/PurchaseReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "ExpenseReport",
          route: "Reports/ExpenseReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "WithdrawReport",
          route: "Reports/WithdrawReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "DepositReport",
          route: "Reports/DepositReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "CustomerReport",
          route: "Reports/CustomerReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "CustomerPaymentReport",
          route: "Reports/CustomerPaymentReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.generalReport],
        },
        {
          title: "SellPricePerQtyReport",
          route: "Reports/SellPricePerQtyReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission:
            [permissions.generalReport] &&
            process.env.REACT_APP_APP_NAME === "Bahez",
        },
      ],
    },

    // ! Item Reports
    {
      title: "ItemsReports",
      active: false,
      module: true,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [permissions.itemReport],
      subItems: [
        {
          title: "ProductStatement",
          route: "Reports/ProductStatement",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
        {
          title: "ReportStock",
          route: "Reports/ReportStock",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
        {
          title: "NotSaleReport",
          route: "Reports/NotSaleReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
        {
          title: "StockAlertReport",
          route: "Reports/StockReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
        {
          title: "BrandItemReport",
          route: "Reports/BrandItemReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
        {
          title: "ProductSellsInvoice",
          route: "Reports/ProductSellsInvoice",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.itemReport],
        },
      ],
    },

    // ! Medical Reports
    {
      title: "MedicalReports",
      active: false,
      module: process.env.REACT_APP_APP_NAME === "Hana" ? true : false,
      icon: <SummarizeIcon className="IconMenu" />,
      permissions: [permissions.medicalReport],
      subItems: [
        {
          title: "DoctorPaymentsReport",
          route: "MedicalReport/DoctorPaymentsReport",
          icon: <SummarizeIcon className="IconMenu" />,
          permission: [permissions.medicalReport],
        },
        {
          title: "PatientVisitsReport",
          route: "MedicalReport/CustomerVisitsReport",
          icon: <SummarizeIcon className="IconMenu" />,
          permission: [permissions.medicalReport],
        },
        {
          title: "TodayPatientVisitsReport",
          route: "MedicalReport/TodayCustomerVisitsReport",
          icon: <SummarizeIcon className="IconMenu" />,
          permission: [permissions.medicalReport],
        },
        {
          title: "DailyVisitReport",
          route: "MedicalReport/DailyVisitReport",
          icon: <SummarizeIcon className="IconMenu" />,
          permission: [permissions.medicalReport],
        },
      ],
    },

    // ! SupplierReports
    {
      title: "SupplierReports",
      active: false,
      module: true,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [permissions.supplierReport],
      subItems: [
        {
          title: "SupplierStatement",
          route: "Reports/SupplierStatement",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierReport",
          route: "Reports/SupplierReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierPaymentReport",
          route: "Reports/SupplierPaymentReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierStockRemain",
          route: "Reports/SupplierStockRemain",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierItemReport",
          route: "Reports/SupplierItemReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "ItemSupplierReport",
          route: "Reports/ItemSupplierReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierSellReport",
          route: "Reports/SupplierSellReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
        {
          title: "SupplierStockFullReport",
          route: "Reports/SupplierStockFullReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.supplierReport],
        },
      ],
    },

    // ! HrReport
    {
      title: "HrReport",
      active: false,
      module:
        process.env.REACT_APP_HR_MODULE === "true" &&
        process.env.REACT_APP_HR_MODULE !== undefined
          ? true
          : false,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [permissions.hrReport],
      subItems: [
        {
          title: "EmployeeStatement",
          route: "HrReports/EmployeeAccountStatement",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "AbsentReport",
          route: "HrReports/AbsentReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "LoanReport",
          route: "HrReports/LoanReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "LoanReturnReport",
          route: "HrReports/LoanReturnReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "PermissionReport",
          route: "HrReports/PermissionReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "PunishmentReport",
          route: "HrReports/PunishmentReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "RewardReport",
          route: "HrReports/RewardReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
        {
          title: "SalaryPayReport",
          route: "HrReports/SalaryPayReport",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.hrReport],
        },
      ],
    },

    // ! AdminReport
    {
      title: "AdminReports",
      active: false,
      module: true,
      icon: <AccountBalanceIcon className="IconMenu" />,
      permissions: [permissions.adminReport],
      subItems: [
        {
          title: "ProfitLose",
          route: "AdminReports/ProfitLose",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.adminReport],
        },
        {
          title: "InvoiceProfitLose",
          route: "AdminReports/InvoiceProfitLose",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.adminReport],
        },
        {
          title: "CustomerProfitLose",
          route: "AdminReports/CustomerProfitLose",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.adminReport],
        },
        {
          title: "Logs",
          route: "Utilities/GetLogs",
          icon: <AccountBalanceIcon className="IconMenu" />,
          permission: [permissions.adminReport],
        },
      ],
    },
  ]);

  const openHandler = (index) => {
    const updatedMenuItems = menuItems.map((item, i) => {
      if (i === index) {
        if (item.active) {
          return { ...item, active: false };
        } else {
          return { ...item, active: true };
        }
      } else {
        return { ...item, active: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };

  return (
    <List
      aria-labelledby="nested-list-subheader"
      className={`sideBarBg`}
      style={{ width: "250px" }}
    >
      {menuItems.map((item, index) => {
        return (
          <span key={index}>
            {item?.module === true && hasPermission(item.permissions) && (
              <ListItem
                button
                onClick={() => {
                  item?.subItems?.length > 0
                    ? openHandler(index)
                    : window.open(`#/${item?.route}`, "_self");
                }}
                className={
                  window.location.hash === `#/${item?.route}`
                    ? `py-0 bg-light text-primary gap-1 sideBarList`
                    : `py-0 text-light gap-1 sideBarList`
                }
              >
                <ListItemIcon className={`min-width-0 text-light text-base`}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className={`text-base py-1 text-start`}
                  primary={translate(`ra.navigation.${item.title}`)}
                />
                {item.subItems?.length > 0 &&
                  (item.active ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
            )}

            <Collapse in={item.active} timeout="auto" unmountOnExit>
              <List className="sideBarListItem" component="div" disablePadding>
                {item?.subItems?.length > 0 &&
                  item?.subItems?.map((subItem, index2) => {
                    return (
                      hasPermission(subItem?.permission) && (
                        <ListItem
                          key={index2}
                          button
                          className={
                            window.location.hash === `#/${subItem.route}`
                              ? `py-1 bg-light text-primary gap-1 `
                              : `py-1 text-light gap-1 border-bottom`
                          }
                          onClick={() => {
                            window.open(`#/${subItem?.route}`, "_self");
                          }}
                        >
                          <ListItemIcon
                            className={`min-width-0 text-sm
                              ${
                                window.location.hash === `#/${subItem.route}`
                                  ? "text-primary"
                                  : "text-light"
                              }

                              `}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            className="text-start text-sm"
                            primary={translate(
                              `ra.navigation.${subItem.title}`
                            )}
                          />
                        </ListItem>
                      )
                    );
                  })}
              </List>
            </Collapse>
          </span>
        );
      })}
    </List>
  );
}
