export const englishMessages = {
  ra: {
    action: {
      edit: "Edit",
      create: "Create",
      add_filter: "Add Filter",
      save: "Save",
      saveAll: "Save for All Branches",

      export: "Export",
      delete: "Delete",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      bulk_actions: "Actions",
      add: "Add",
      remove: "Remove",
      back: "Back",
      unselect: "Unselect",
      refresh: "Refresh",
      confirm: "Confirm",
      cancel: "Cancel",
      clear_input_value: "Clear value",
      search: "Search",
    },
    auth: {
      logout: "Logout",
      user_menu: "Menu",
      username: "Username",
      password: "Password",
      sign_in: "Sign In",
      auth_check_error: "Invalid credentials",
    },
    validation: {
      required: "Required",
      minValue: "Too Low",
      maxValue: "Too Hight",
    },
    notification: {
      updated: "Updated",
      created: "Created",
      deleted: "Deleted",
      logged_out: "Logout",
    },
    input: {
      image: {
        upload_single: "Upload Image",
      },
    },
    boolean: {
      true: "Yes",
      false: "No",
      null: "Null",
    },
    navigation: {
      undefined: "Undefined",
      page_rows_per_page: "Page Rows Per Page",
      page_range_info: "Page Range Info",
      next: "Next",
      Departments: "Departments",
      LabTestCategories: "Lab Test Categories",
      previous: "Previous",
      no_results: "No Results Found",
      Branches: "Branches",
      BalanceSheet: "Balance Sheet",

      GeneralReports: "General Reports",
      OrdersReport: "Orders Report",
      CustomerStatement: "Customer Statement",
      CashierSellReport: "Cashier Sell Report",
      RepresentativeStatement: "Representative Statement",
      ExpireReport: "Expire Report",
      SellInvoiceReport: "Sell Invoice Report",
      NonPaidSellInvoiceReport: "Not Paid Sell Invoice Report",
      PurchaseReport: "Purchase Report",
      ExpenseReport: "Expense Report",
      WithdrawReport: "Withdraw Report",
      DepositReport: "Deposit Report",
      CustomerReport: "Customer Report",
      CustomerPaymentReport: "Customer Payment Report",
      SellPricePerQtyReport: "Sell Price Per Quantity Report",

      ItemsReports: "Items Report",
      ProductStatement: "Product Statement",
      ReportStock: "Report Stock",
      NotSaleReport: "Not Sale Report",
      StockReport: "Stock Report",
      ProductSellsInvoice: "Product Sell Invoice",
      StockAlertReport: "Stock Alert Report",
      BrandItemReport: "Brand Item Report",

      SupplierReports: "Supplier Reports",
      SupplierStatement: "Supplier Statement",
      SupplierReport: "Supplier Report",
      SupplierPaymentReport: "Supplier Payment Report",
      SupplierStockRemain: "Supplier Stock Remain",
      SupplierItemReport: "Supplier Item Report",
      ItemSupplierReport: "Item Supplier Report",
      SupplierSellReport: "Supplier Sell Report",
      SupplierStockFullReport: "Supplier Stock Full Report",

      HrReport: "HR Report",
      EmployeeStatement: "Employee Statement",
      AbsentReport: "Absent Report",
      LoanReport: "Loan Report",
      LoanReturnReport: "Loan Return Report",
      PermissionReport: "Permission Report",
      PunishmentReport: "Punishment Report",
      RewardReport: "Reward Report",
      SalaryPayReport: "Salary Pay Report",

      AdminReports: "Admin Reports",
      ProfitLose: "Profit Loss",
      InvoiceProfitLose: "Invoice Profit Loss",
      CustomerProfitLose: "Customer Profit Lose",
      Logs: "Logs",

      Stores: "Stores",
      Cashiers: "Cashiers",
      ProductCategories: "Categories",
      Products: "Products",
      Brands: "Brands",
      StoreInventoryInvoice: "Store Inventory Invoice",
      ProductDamageTypes: "Damage Types",
      ProductDamages: "Damages",
      Selling: "Selling",
      Return: "Returns",
      Customers: "Customers",
      CustomerPayments: "Customer Payments",
      BankAccounts: "Bank Accounts",
      Industries: "Industry",
      Suppliers: "Suppliers",
      SupplierPayments: "Supplier Payments",
      Purchases: "Purchases",
      PurchasesPre: "PurchasesPre",
      PurchaseInvoices: "Purchase Invoices",
      PurchasePreInvoices: "Purchase Pre Invoices",
      PurchaseReturnInvoices: "Purchase Return Invoices",
      Deposits: "Deposits",
      Withdraws: "Withdraws",
      WithdrawTypes: "Withdraw Types",
      uploadCenter: "Upload Center",
      DepositTypes: "Deposit Types",
      Expenses: "Expenses",
      ExpenseTypes: "Expense Types",
      Accountant: "Accountant",
      Employees: "Employees",
      EmployeeLoans: "Loans",
      EmployeeReturnLoans: "Returned Loans",
      EmployeeAbsents: "Absents",
      EmployeeRewards: "Rewards",
      EmployeePunishments: "Punishments",
      EmployeePermissions: "Permissions",
      Representatives: "Representatives",
      RepresentativeCustomers: "Representative Customers",
      Settings: "Settings",
      Salary: "Salary",
      SalarySummary: "Salary Summary",
      PreInvoices: "Pre Invoices",
      UserNames: "User Names",
      CurrencyExchanges: "Currency Exchanges",
      HR: "HR",
      ProcessInvoices: "Process Invoices",
      DamageInvoices: "Damage Invoices",
      BankAccountTransfers: "Bank Accounts Transfers",
      UserStoreBankAccountTransfers: "User Store Bank Accounts Transfers",
      UserStoreTransactionHistories: "User Store Transactions History",
      StoreTransferInvoices: "Store Transfer Invoices",
      RepresentativeInvoices: "Invoices",
      RepresentativePayments: "Representative Payments",
      RepresentativeCustomerPayments: "Representative Customer Payments",
      Payments: "Payments",
      Dashboard: "Dashboard",
      Pos: "POS",
      Checks: "Checks",
      StoreTransactionHistories: "Transaction Histories",
      Invoices: "Invoices",
      options: "Options",
      cashierSells: "Cashiers",
      skip_nav: "Skip Nav",
      salaryChange: "Salary Change",
      dashboard: "Dashboard",
      customersInfo: "Patients Information",
      Doctors: "Doctors",
      DoctorLoans: "Doctor Loans",
      DoctorReturnLoans: "Return Loans",
      DoctorAbsents: "Doctors Absents",
      DoctorPermissions: "Doctors Permissions",
      DoctorRewards: "Doctors Rewards",
      DoctorPunishments: "Doctors Pendishments",
      LabTests: "Lab Tests",
      Visitors: "Visitors",
      AddVisitor: "Add Visitor",
      MedItems: "Med Items",
      Medical: "Medical",
      XRays: "X-Rays",
      DoctorPayments: "Doctor Payments",
      MedicalReports: "Medical Reports",
      DoctorPaymentsReport: "Doctor Payments Report",
      patientVisitsReport: "Patient Visits Report",
      todayPatientVisitsReport: "Today Patient Visits Report",
      UnpaidVisitsReport: "Unpaid Visits Report",
      doctorsVisitsReport: "Doctors Visits Report",
      DoctorVisitsReport: "Doctor Visits Report",
      Visits: "visits",
      dailyVisitReport: "Daily Visit Report",
      RepresentativeCustomerSellInvoices:
        "Representative Customer Sell Invoices",
      RepresentativeCustomerSellInvoicesReport:
        "Representative Customer Sell InvoicesReport",
    },
    page: {
      dashboard: "Dashboard",
      list: "List",
      show: "Show",
      edit: "Edit",
      empty: "This List is Empty",
      invite: "Invite",
      not_found: "404 | Not Found",
      create: "Create",
      error: "Error",
    },
    message: {
      not_found: "We could not find the page you are looking for..",
      invalid_form: "Please check the errors",
      error: "Error",
    },
  },
  resources: {
    root: {
      UserStoreBankAccountWithdraws: "Withdraws",
      email: "Email",
      storeIds: "Choose Store",
      firstName: "First Name",
      middleName: "Middle Name",
      lastName: "Last Name",
      phone2: "Second Phone No.",
      male: "Male",
      female: "Female",
      dob: "Date of Birth",
      nationality: "Nationality",
      currentWork: "Current Work",
      idCardNumber: "ID Card No.",
      idCardType: "ID Card Type",
      mcRoofLoan: "Max Loan in USD",
      scRoofLoan: "Max Loan in IQD",
      openAccountLoan: "Open Account Loan",
      mcOpenAccountLoan: "Current Loan in USD",
      scOpenAccountLoan: "Current Loan in IQD",
      available: "Available",
      usd: "USD",
      iqd: "IQD",
      store: "Store",
      stores: "Stores",
      add: "ADD",
      title: "Title",
      action: "Action",
      swalTitle: "Are you sure?",
      swalText: "Once deleted, you will not be able to recover this Store!",
      swalDeleted: "Deleted Successfully!",
      swalDeleteCancelled: "Your Store is safe!",
      swalTransferProduct: "To trasnfer this products to another Store?",
      swalSuccess: "Successfully Done!",

      date: "Date",
      payedBy: "Paid By",
      description: "Description",
      shortDescription: "Short Description",
      longDescription: "Long Description",
      address: "Address",
      zipCode: "Zip Code",
      webSite: "Web site",
      customerNumber: "Customer No.",
      billTo: "Bill To",
      companyName: "Company Name",
      otherInstructions: "Other Comments and Instructions",
      instructionOne: "Total payments due 30 days.",
      instructionTwo: "Please include the invoice number in your checks.",
      totalNumber: "Total Quantity",
      previousPrice: "Previous Loan",
      purePrice: "Final Price",
      currentLoan: "Current Loan",
      gift: "Gift",
      accountant: "Accountant",
      customer: "Customer",
      chooseCustomer: "Choose Customer",
      chooseSupplier: "Choose Supplier",
      invoiceNumber: "Invoice Number",
      phone: "Phone",
      maxLoan: "Max Loan",
      futureLoan: "Future Loan",
      productMeasure: "Product Measure",
      oneMeasure: "One Measure",
      oneContains: "One Contains",
      totalPrice: "Total Price",
      finalPrice: "Final Price",
      netPrice: "Net Price",
      totalQuantity: "Total Quantity",
      totalGifts: "Total Gifts",
      finalQuantity: "Final Quantity",
      discountInPercentage: "Discount In Percentage",
      inPercentage: "In %",
      discount: "Discount",
      confirmCreateNewInvoice: "Do you want to create a new invoice?",
      createCancel: "Creation Dismised",
      makeSure: "Are you sure?",
      chooseProduct: "Choose Product",
      quantity: "Quantity",
      gifts: "Gifts",
      max: "Max",
      price: "Price",
      cash: "Cash",
      debt: "Debt",
      dollar: "Dollar",
      dinar: "Dinar",
      addOrder: "Add Order",
      barcode: "Barcode",
      name: "Name",
      scientificName: "Scientific Name",
      BusinessName: "Business Name",
      items: "Items",
      priceInUSD: "Price in USD",
      priceInIQD: "Price in  IQD",
      currency: "Currency",
      driver: "Driver",
      note: "Note",
      create: "Create",
      selectCustomer: "Please Choose a Customer!",
      emptyListOfOrders: "Empty list of orders is not allowed!",
      allowedBy: "Allowed by",
      maximumLoanExceed: "Maximum loan exceeded!",
      clearOrders: "Clear Orders",
      storeNotExist: "This store does not exist",
      customerCantBuy: "This customer cannot buy from this store",
      startDate: "Start Date",
      endDate: "End Date",
      search: "Search",
      refNo: "Reference No.",
      print: "Print",
      customers: "Customers",
      country: "Country",
      city: "City",
      supplierName: "Supplier Name",
      owner: "Owner",
      supplier: "Supplier",
      reciever: "Receiver",
      remainder: "Remainder",
      salaryType: "Salary Type",
      salary: "Salary",
      mcBasicSalary: "Salary: $",
      scBasicSalary: "Salary: IQD",
      salaryUsd: "Salary Currency: USD",
      salaryIqd: "Salary Currency: IQD",
      loanUsd: "Loan Currency: USD",
      loanIqd: "Loan Currency: IQD",
      loans: "Loans",
      returnedLoan: "Returned Loan",
      absents: "Absents",
      rewards: "Rewards",
      punishments: "Punishments",
      permissions: "Permissions",
      totalLoan: "Total Loan",
      TotalReturnedLoan: "Total Returned Loan",
      totalRewards: "Total Rewards",
      totalPunishments: "Total Punishments",
      totalSubtractedSalary: "Total Subtracted Salary",
      rewardedBy: "Rewarded By",
      reason: "Reason",
      type: "Type",
      punishedBy: "Punished By",
      subtructedSalary: "Subtracted Salary",
      hours: "Hours",
      days: "Days",
      employee: "Employee",
      chooseEmployee: "Choose Employee",
      remains: "Remains",
      added: "Successfully Added",
      updated: "Successfully Updated",
      forgivePrice: "Forgive Price",
      loanMaxLoan: "Max Loan",
      dollarSell: "Dollar Sell",
      dinnarSell: "Dinar Sell",
      dollarPurchase: "Dollar Purchase",
      dinnarPurchase: "Dinar Purchase",
      preferedName: "Preferred Name",
      weightInKg: "Weight In Kg",
      chooseBrand: "Choose Brand",
      chooseStore: "Choose Store",
      chooseCategory: "Choose Category",
      productionDate: "Production Date",
      expireDate: "Expire Date",
      alertQuantity: "Alert Quantity",
      purchasePrice: "Purchase Price",
      sellingPrice: "Selling Price",
      purchase: "Purchase",
      selling: "Selling",
      sellByUSD: "Sell By USD",
      sellByIQD: "Sell By IQD",
      purchaseByUSD: "Purchase By USD",
      purchaseByIQD: "Purchase By IQD",
      cannotSellBelowZero: "Can't Sell Below Zero",
      canSellBelowZero: "Can Sell Below Zero",
      initialQuantity: "Initial Quantity",
      actions: "Actions",
      measure: "Measure",
      product: "Product",
      chooseRepresentative: "Choose Representative",
      selectRepresentative: "Please Choose a Representative",
      dinnarReturn: "Dinar Return",
      dollarReturn: "Dollar Return",
      Representative: "Representative",
      ignoreProductPriceType: "Ignore Product Price Type",
      acceptedByAccountant: "Accepted",
      canTakeLoan: "Can Take Loan",
      gpsLock: "GPS Lock",
      saturday: "Saturday",
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      useCustomPhoneNumbers: "Use Custom Phone Numbers",
      supplierNumber: "Supplier No.",
      maxPossibleLoanInUSD: "Max Possible Loan in USD",
      maxPossibleLoanInIQD: "Max Possible Loan in IQD",
      addressConfirmed: "Address Confirmed",
      addressConfirmedBy: "Address Confirmed By",
      attachment: "Attachment",
      placeName: "Place Name",
      monthly: "Monthly",
      weekly: "Weekly",
      datetime: "Datetime",
      selectDatetime: "Select a Datetime",
      useMainCurrency: "Use Main Currency",
      loan: "Loan",
      pleaseSelectSupplier: "Please Select Supplier!",
      cashPaid: "Cash Paid",
      reward: "Reward",
      punishment: "Punishment",
      stock: "Stock",
      sellPrice: "Sell Price",
      qteInStock: "Qty In Stock",
      sellByMain: "Sell By Main",
      unlockAll: "Unlock All",
      lockAll: "Lock All",
      toggle: "Toggle",
      totalQte: "Total Qty",
      id: "ID",
      showId: "Show ID",
      giftQuantity: "Gift Quantity",
      purchaseInvoice: "Purchase Invoice",
      finalDiscount: "Final Discount",
      invoice: "Invoice",
      orders: "Orders",
      default: "Default",
      no: "No",
      sellInvoice: "Sell Invoice",
      countInvoice: "Count Invoice",
      total: "Total",
      sellReturnInvoice: "Sell Return Invoice",
      purchaseReturnInvoice: "Purchase Return Invoice",
      representativeInvoice: "Representative Invoice",
      representativeReturnInvoice: "Representative Return Invoice",
      representativeCustomerInvoice: "Representative Customer Invoice",
      representativeCustomerReturnInvoice:
        "Representative Customer Return Invoice",
      expenses: "Expenses",
      supplierPayments: "Supplier Payments",
      customerPayments: "Customer Payments",
      boxes: "Boxes",
      lastLogins: "Last Logins",
      time: "Time",
      ip: "Ip",
      sells: "Sells",
      reports: "Reports",
      productStatement: "Product Statement",
      customerStatement: "Customer Statement",
      supplierStatement: "Supplier Statement",
      stockReport: "Stock Alert Report",
      expireReport: "Expire Report",
      productStatementReport: "Product Statement",
      currentQuantity: "Current Quantity",
      previousQuantity: "Previous Quantity",
      startQuantity: "Start Quantity",
      income: "Income",
      outcome: "Outcome",
      balance: "Balance",
      perPage: "Per Page",
      dollarWithdraw: "Dollar Withdraw",
      dinarWithdraw: "Dinar Withdraw",
      dollarDeposit: "Dollar Deposit",
      dinarDeposit: "Dinar Deposit",
      previousBalance: "Previous Balance",
      initialBalance: "Initial Balance",
      currentBalance: "Current Balance",
      stockAlertReport: "Stock Alert",
      ignoreZero: "Ignore Zero",
      ignoreBellowZeroProducts: "Ignore Bellow Zero Products",
      qtyInStock: "Qty In Stock",
      alertQty: "Alert Qty",
      willExpireInDays: "Will Expire In Days",
      previous: "Previous",
      next: "Next",
      suppliers: "Suppliers",
      dollarPrice: "Dollar Price",
      selectDefaultCustomer: "Default Customer",
      defaultProductsInPos: "Default Products In Pos",
      updateSellingPriceOnSale: "Update Selling Price On Sale",
      usdToIqd: "Dollar To Dinar",
      iqdToUsd: "Dinar To Dollar",
      createProduct: "Create Product",
      createCustomer: "Create Customer",
      createSupplier: "Create Supplier",
      createSellInvoice: "Create SellInvoice",
      createPurchase: "Create Purchase",
      createRepresentatives: "Create Representatives",
      createRepresentativeCustomers: "Create Representative Customers",
      cantDeleteNonZeroProducts: "Can't Delete Non Zero Products",
      areYouSure: "Are You Sure?",
      yes: "Yes",
      No: "No",
      mcPrice: "Main Price",
      scPrice: "Second Price",
      fromMcToSc: "From Dollar To Dinar",
      depositor: "Depositor",
      byMain: "By Main",
      depositType: "Deposit Type",
      label: "Label",
      currentPunishments: "Current Punishments",
      mainCurrencyBalance: "Main Currency Balance",
      detail: "Detail",
      accountNumber: "Account Number",
      fromBankAccount: "From Bank Account",
      toBankAccount: "To Bank Account",
      mainCurrencyAmount: "Main Currency Amount",
      withdrawer: "Withdrawer",
      withdrawType: "Withdraw Type",
      uploadCenter: "Upload Center",
      ExpenseTypes: "Expense Type",
      expenseType: "Expense Type",
      pleaseFillInTheRequiredDetails: "Please Fill In The Required Details",
      totalQty: "Total Quantity",
      isCash: "Cash",
      products: "Products",
      createProducts: "Create Products",
      image: "Image",
      damageBy: "Damage By",
      damageType: "Damage Type",
      producedProduct: "Produced Product",
      producedQuantity: "Produced Quantity",
      usedQuantity: "Used Quantity",
      processQuantity: "Process Quantity",
      transferredBy: "Transferred By",
      invoiceNo: "Invoice No.",
      toAddNewOrder: "To Add New Order",
      preOrderDate: "Pre Order Date",
      delivered: "Delivered",
      representativeName: "Representative Name",
      checkType: "Check Type",
      result: "Result",
      BalanceInUSD: "Balance In USD",
      balanceInIQD: "Balance In IQD",
      depositInUSD: "Deposit In USD",
      depositInIQD: "Deposit In IQD",
      withdrawInUSD: "Withdraw In USD",
      withdrawInIQD: "Withdraw In IQD",
      details: "Details",
      totalMcTransferFromOther: "Total Dollar Transfer From Other",
      totalScTransferFromOther: "Total Dinar Transfer From Other",
      totalMcTransferToOther: "Total Dollar Transfer To Other",
      totalScTransferToOther: "Total Dinar Transfer To Other",
      openingDate: "Opening Date",
      bankAccount: "Bank Account",
      branch: "Branch",
      username: "Username",
      password: "Password",
      usertype: "User Type",
      createDamages: "Create Damages",
      createTransfers: "Create Transfers",
      createProcess: "Create Process",
      createCustomerPayment: "Create Customer Payment",
      sellInvoices: "Sell Invoices",
      createSellInvoices: "Create Sell Invoices",
      createSellReturnInvoice: "Create Sell Return Invoice",
      createSupplierPayment: "Create Supplier Payment",
      purchaseInvoices: "Purchase Invoices",
      createPurchaseInvoices: "Create Purchase Invoices",
      createPurchaseReturnInvoice: "Create Purchase Return Invoice",
      createWithdraws: "Create Withdraws",
      createDeposits: "Create Deposits",
      createExpenses: "Create Expenses",
      quickCommands: "Quick Commands",
      phoneNumber: "Phone Number",
      phoneNumber2: "Phone Number 2",
      vouchersFontSize: "Vouchers Font Size",
      productWeightInKg: "Product Weight In Kg",
      productOneMeasure: "Product One Measure",
      productOneContains: "Product One Contains",
      representativeStatement: "Representative Statement",
      representatives: "Representatives",
      showCurrentLoan: "Show Current Loan",
      updateDriver: "Update Driver",
      printOrders: "Print Orders",
      profile: "Profile",
      updateAvatar: "Update Avatar",
      accountInfo: "Account Info",
      updateInfo: "Update Info",
      passwordInfo: "Password Info",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      updatePassword: "Update Password",
      expenseStatementReport: "Expense Statement Report",
      depositStatementReport: "Deposit Statement Report",
      withdrawStatementReport: "Withdraw Statement Report",
      expenseCategory: "Expense Category",
      withdrawCategory: "Withdraw Category",
      depositCategory: "Deposit Category",
      all: "All",
      purchaseReport: "Purchases",
      expenseReport: "Expenses",
      hrReport: "HR Report",
      loanReport: "Loan Report",
      returnLoanReport: "Return Loan Report",
      absentReport: "Absent Report",
      rewardReport: "Reward Report",
      punishmentReport: "Punishment Report",
      permissionReport: "Permission Report",
      salaryPayReport: "Salary Pay Report",
      employeeStatement: "Employee Statement",
      employees: "Employees",
      qty: "Qty",
      customerReport: "Customer Report",
      supplierReport: "Supplier Report",
      getLastPriceForSelling: "Get Last Price For Selling",
      category: "Category",
      initialQty: "Initial Qty",
      sellByUsd: "Sell By USD",
      purchaseByUsd: "Purchase By USD",
      nameBarcode: "Name, Barcode",
      lastInvoice: "Last Invoice",
      paidAmount: "Paid Amount",
      receivedAmount: "Received Amount",
      cashIqd: "Cash IQD",
      cashUsd: "Cash USD",
      checkout: "Create",
      reset: "Reset",
      remove: "Remove",
      theProductQuantityInStockIsZero: "The product quantity in stock is zero",
      theProductQuantityInStockIsOnly: "The product quantity in stock is only",
      productNotFound: "Product not found",
      remain: "Remain",
      systemFontSize: "System Font Size",
      selectDefaultCategory: "Select Default Category",
      general: "General",
      dashboard: "Dashboard",
      newDriver: "New Driver",
      update: "Update",
      emptyDriverNameIsNotAccepted: "Empty Driver Name Is Not Accepted",
      updateExpireDate: "Update Expire Date",
      newExpireDate: "New Expire Date",
      showByMain: "Show By Dollar",
      allowBelowZero: "Allow Below Zero",
      loanLimitDays: "Loan Limit Days",
      dinarPurchase: "Dinar Purchase",
      profitRatio: "Profit Rate",
      addressInKurdish: "Address In Kurdish",
      addressInArabic: "Address In Arabic",
      addressInEnglish: "Address In English",
      discountRate: "Discount Rate",
      pleaseSelectOneInvoiceToUpdateAddress:
        "Please select one invoice to update address",
      newAddress: "New Address",
      updateAddress: "Update Address",
      pleaseEnterNumberOfDays: "Please Enter Number Of Days",
      updateLimitDays: "Update Limit Days",
      ignoreZeroDashboard: "Ignore Zero In Dashboard Report",
      ignoreBelowZeroProductsDashboard:
        "Ignore Below Zero Products Dashboard Report",
      expireDaysDashboard: "Expire Days In Dashboard Report",
      balanceSheet: "Balance Sheet",
      receivable: "Receivable",
      fromCustomers: "From Customers",
      payable: "Payable",
      toSuppliers: "To Suppliers",
      paidExpenses: "Paid Expenses",
      sellOrdersReport: "Sell Orders Report",
      showSmallMeasure: "Show Small Measure",
      smallMeasure: "Small Measure",
      smallSellPrice: "Small Sell Price",
      smallMeasureSellPrice: "Small Measure Sell Price",
      monthlyAllowDays: "Monthly / Weekly Allow Days",
      workingHoursPerDay: "Working Hours Per Day",
      subtractedSalary: "Subtracted Salary",
      addedPunishment: "Added Punishment",
      futureSubtractions: "Future Subtractions",
      returnDate: "Return Date",
      currentRewards: "Current Rewards",
      addedReward: "Added Reward",
      accept: "Accept",
      colorizeTable: "Colorize Table",
      noOfOrders: "No. Of Orders",
      purchaseMainCurrency: "Purchase Main Currency",
      sellMainCurrency: "Sell Main Currency",
      tookBy: "Took By",
      currentSalary: "Current Salary",
      basicSalary: "Basic Salary",
      finalSalary: "Final Salary",
      updatePurchasePriceOnPurchase: "Update Purchase Price On Purchase",
      reportStock: "Report Stock",
      logo: "Logo",
      marginTop: "Margin Top",
      marginXAxis: "Margin XAxis",
      marginBottom: "Margin Bottom",
      width: "Width",
      height: "Height",
      radius: "Radius",
      info: "Info",
      infoSize: "size of Info section",
      invoiceDescription: "Invoice Description",
      invoiceDescriptionSize: "Size of Invoice Description section",
      infoNeedRefresh: "Info (need to refresh)",
      invoiceDescriptionInKurdish: "Invoice Description In Kurdish",
      invoiceDescriptionInArabic: "Invoice Description In Arabic",
      invoiceDescriptionInEnglish: "Invoice Description In English",
      invoiceMockup: "Invoice Mockup",
      invoiceHeaderHeight: "Invoice Header Height",
      logoUrl: "Logo Url",
      uploadAndDownloadMockup: "Upload And Download Mockup",
      upload: "Upload",
      download: "Download",
      uploadedSuccessfully: "Uploaded Successfully",
      downloadedSuccessfully: "Downloaded Successfully",
      someErrorOccurred: "Some Error Occurred",
      fontSize: "Font Size",
      showGift: "Show Gift",
      selectSupplier: "Please select supplier",
      purchaseQty: "Purchase Qty",
      sellQty: "Sell Qty",
      averagePurchasePrice: "Average Purchase Price",
      averageSellPrice: "Average Sell Price",
      totalPurchasePrice: "Total Purchase Price",
      showInUSD: "Show In USD",
      supplierItemReport: "Supplier Item Report",
      userType: "User Type",
      cashvan: "Cashvan",
      labelHeight: "Label Height",
      labelWidth: "Label Width",
      nameColor: "Name Color",
      priceColor: "Price Color",
      barcodeColor: "Barcode Color",
      labelPrinter: "Label Printer",
      numberOfLabels: "Number Of Labels",
      newPrice: "New Price",
      offerText: "Offer Text",
      offerFontSize: "Text Font Size",
      nameFontSize: "Name Font Size",
      priceFontSize: "Price Font Size",
      offerColor: "Offer Color",
      heightSize: "Height Size",
      widthSize: "Width Size",
      offerPrint: "Offer Print",
      createAndGoToList: "Create Go To List",
      showSmallPrice: "Show Small Price",
      showPrice: "Show Price",
      searchForProduct: "Search For Product",
      printStick: "Print Stick",
      barcodeWidth: "Barcode Width",
      barcodeHeight: "Barcode Height",
      boldName: "Bold Name",
      boldPrice: "Bold Price",
      start: "Start",
      end: "End",
      nameSize: "Name Size",
      priceSize: "Price Size",
      itemSupplierReport: "Item Supplier Report",
      generalReports: "General Reports",
      itemReports: "Item Reports",
      cashiers: "Cashiers",
      cashierSells: "Cashier Sells",
      smallMeasureQty: "Small Measure Qty",
      productName: "Product Name",
      cashPrice: "Cash Price",
      returnPrice: "Return Price",
      cashierSellReturn: "Returns",
      returns: "Returns",
      purchases: "Purchases",
      brands: "Brands",
      brand: "Brand",
      sort: "Sort",
      smallMeasurePurchaseQty: "Small Measure Purchase Qty",
      smallMeasureSellQty: "Small Measure Sell Qty",
      initialBalanceDollar: "Initial Balance Dollar",
      initialBalanceDinar: "Initial Balance Dinar",
      mcCurrentBalance: "Dollar Current Balance",
      scCurrentBalance: "Dinar Current Balance",
      currentCash: "Current Cash",
      amount: "Amount",
      withdraw: "Withdraw",
      showCashierReturns: "Show Cashier Returns",
      supplierForgivePriceReport: "Supplier Forgive Price Report",
      forgiveTotalPrice: "Forgive Total Price",
      customerForgivePriceReport: "Customer Forgive Price Report",
      groupProducts: "Group Products",
      averagePurchaseOnePrice: "Average Purchase One Price",
      averageSellOnePrice: "Average Sell One Price",
      startingDate: "Starting Date",
      totalAbsents: "Total Absents",
      returnInvoice: "Return Invoice",
      supplierInvoice: "Supplier Invoice",
      supplierPayment: "Supplier Payment",
      totalSellPerPurchasePrice: "Total Sell Per Purchase Price",
      totalSellProfit: "Total Sell Profit",
      smallPrice: "Small Price",
      showDiscountInvoice: "Show Discount Invoice",
      totalRecords: "Total Records",
      exportCsv: "Export CSV",
      customerName: "Customer Name",
      supplierPaymentReport: "Supplier Payment Report",
      customerPaymentReport: "Customer Payment Report",
      notSaleReport: "Not Sale Report",
      brandName: "Brand Name",
      categoryName: "Category Name",
      supplierStockRemain: "Supplier Stock Remain",
      totalSmallQte: "Total Small Qty",
      mcTotalDamage: "Total Damage",
      mcTotalPurchase: "Total Purchase",
      mcTotalPurchaseReturn: "Total Purchase Return",
      mcTotalQuickSell: "Total Quick Sell",
      mcTotalQuickSellReturn: "Total Quick Sell Return",
      mcTotalSell: "Total Sell",
      mcTotalSellReturn: "Total Sell Return",
      scTotalDamage: "Total Damage",
      scTotalPurchase: "Total Purchase",
      scTotalPurchaseReturn: "Total Purchase Return",
      scTotalQuickSell: "Total Quick Sell",
      scTotalQuickSellReturn: "Total Quick Sell Return",
      scTotalSell: "Total Sell",
      scTotalSellReturn: "Total Sell Return",
      mcSellPrice: "Dollar Sell Price",
      scSellPrice: "Dinar Sell Price",
      sellCurrency: "Sell Currency",
      mcPurchasePrice: "Dollar Purchase Price",
      scPurchasePrice: "Dinar Purchase Price",
      purchaseCurrency: "Purchase Currency",
      mcSmallMeasureSellPrice: "Dollar Small Measure Sell Price",
      scSmallMeasureSellPrice: "Dinar Small Measure Sell Price",
      mcTotalExtraInventory: "Total Extra Inventory",
      scTotalExtraInventory: "Total Extra Inventory",
      gender: "Gender",
      birthdate: "Birthdate",
      initialLoan: "Initial Loan",
      roofLoan: "Roof Loan",
      adminReport: "Admin Reports",
      profitLose: "Profit Lose",
      profitLoseReport: "Profit Lose Report",
      profitLoseReportByInvoice: "Profit Lose Report By Invoice",
      brandItemReport: "Brand Item Report",
      scTotalSellPrice: "Total Sell Price",
      scTotalSellPerPurchasePrice: "Total Sell Per Purchase Price",
      mcTotalSellPrice: "Total Sell Price",
      mcTotalSellPerPurchasePrice: "Total Sell Per Purchase Price",
      showBarcode: "Show Barcode",
      showDescription: "Show Description",
      showImage: "Show Image",
      showSerial: "Show Serial",
      showDiscount: "Show Discount",
      showPreferName: "Show Prefer Name",
      yAxis: "YAxis",
      xAxis: "XAxis",
      addressPhone: "Address, Phone",
      addressPhoneSize: "Size of Address, Phone section",
      size: "Size",
      invoiceDescriptionKurdish: "Invoice Description Kurdish",
      invoiceDescriptionArabic: "Invoice Description Arabic",
      invoiceDescriptionEnglish: "Invoice Description English",
      style: "Style",
      newSalary: "New Salary",
      changeDate: "Change Date",
      salaryBefore: "Previous Salary",
      voucherMockup: "Voucher Mockup",
      totalInvoicePrice: "Total Invoice Price",
      totalPurchaseGiftQty: "Total Purchase Gift Qty",
      paidSalaries: "Paid Salaries",
      producedQte: "Produced Qty",
      totalProcessPrice: "Total Process Price",
      totalProducedPrice: "Total Produced Price",
      totalDiscountPrice: "Total Discount Price",
      totalReturnPrice: "Total Return Price",
      totalSellPrice: "Total Sell Price",
      totalProfit: "Total Profit",
      totalSellQty: "Total Sell Qty",
      totalSellGiftQty: "Total Sell Gift Qty",
      totalReturnQty: "Total Return Qty",
      onlyLosses: "Losses",
      processOrders: "Process Orders",
      produceOrders: "Produce Orders",
      barcodes: "Barcodes",
      barcode1: "Barcode 1",
      barcode2: "Barcode 2",
      barcode3: "Barcode 3",
      barcode4: "Barcode 4",
      barcode5: "Barcode 5",
      barcode6: "Barcode 6",
      barcode7: "Barcode 7",
      barcode8: "Barcode 8",
      barcode9: "Barcode 9",
      inventoryType: "Inventory Type",
      extra: "Extra",
      increaseDecrease: "Increase & Decrease",
      futureQty: "Future Qty",
      periodic: "Periodic",
      continuous: "Continuous",
      sudden: "Sudden",
      currentInBox: "Current In Box",
      storeInventoryInvoice: "Store Inventory Invoice",
      realStock: "Real Stock",
      showSmallMeasureQte: "Show Small Measure Qty",
      smallMeasureGift: "Small Measure Gift",
      smallMeasurePrice: "Small Measure Price",
      smallQty: "Small Qty",
      showReturn: "Show Return",
      userPermissions: "User Permissions",
      canDiscountByPercentage: "Can discount by percentage",
      maxPercentageDiscount: "Max percentage discount",
      canDiscountByAmount: "Can discount by amount",
      maxDiscountAmount: "Max discount amount",
      canOpenDrawer: "Can open drawer",
      canReprintLastInvoice: "Can reprint last invoice",
      canSeeOldSellInvoices: "Can see old sell invoices",
      canSeeOldReturnInvoices: "Can see old return invoices",
      canSeeCashBox: "Can see cash box",
      canEditPosSettings: "Can edit POS settings",
      canPrintInvoiceWithoutSubmit: "Can print invoice without submit",
      canSellByLoan: "Can sell by loan",
      restrictOpeningAccount: "Restrict opening account",
      openingAccountTime: "Opening account time",
      restrictClosingAccount: "Restrict closing account",
      closingAccountTime: "Closing account time",
      canSeeOthersInvoices: "Can see others invoices",
      emptyCashBox: "Empty cash box",
      canAddExpense: "Can add expense",
      canReturnInvoice: "Can return Invoice",
      canReturnWithoutInvoice: "Can return without invoice",
      canDiscountOrderByPercentage: "Can discount order by percentage",
      maxPercentageOrderDiscount: "Max percentage order discount",
      canDiscountOrderByAmount: "Can discount order by amount",
      maxDiscountOrderAmount: "Max discount order amount",
      canGiveGift: "Can Give Gift",
      maxGiftCount: "Max Gift Count",
      maxGiftCountPerOrder: "Max Gift Count Per Order",
      canChangeSellPrice: "Can change sell price",
      canChangePurchasePrice: "Can change purchase price",
      cantSellByPurchasePrice: "Cant Sell By Purchase Price",
      cantSellLessThanPurchasePrice: "Cant Sell Less Than Purchase Price",
      newQty: "New Qty",
      newSmallMeasureQty: "New Small Measure Qty",
      inputQty: "Input Qty",
      inputSmallMeasureQty: "Input Small Measure Qty",
      pleaseChooseImage: "Please choose image",
      pleaseWriteFullName: "Please write full name",
      pleaseWriteAllPasswords: "Please write all passwords",
      passwordsAreNotMatch: "Passwords are not match",
      namesMustBeMoreThan3Chars: "Names must be more than 3 chars",
      isDollar: "Is Dollar?",
      showProductMeasure: "Show Product Measure",
      cashierName: "Cashier Name",
      purchaseReturnQty: "Purchase Return Qty",
      sellReturnQty: "Sell Return Qty",
      quickSellQty: "Quick Sell Qty",
      quickSellReturnQty: "Quick Sell Return Qty",
      supplierStock: "Supplier Stock",
      totalSellMc: "Dollar Total Sell",
      totalSellSc: "Dinar Total Sell",
      totalPurchaseMc: "Dollar Total Purchase",
      totalPurchaseSc: "Dinar Total Purchase",
      supplierSellReport: "Supplier Sell Report",
      totalSellProfitMc: "Dollar Total Sell Profit",
      totalSellProfitSc: "Dinar Total Sell Profit",
      supplierReports: "Supplier Reports",
      supplierStockReport: "Supplier Stock Report",
      viewHisExpense: "View His Expense",
      deleteHisExpense: "Delete His Expense",
      invoiceProfitLose: "Invoice Profit Lose",
      allStores: "All Stores",
      deleteHisSellInvoice: "Delete His Sell Invoice",
      deleteHisSellReturnInvoice: "Delete His Sell Return Invoice",
      canWithdrawForHimSelf: "Can Withdraw For Him Self",
      canReceiveDollar: "Can Receive Dollar",
      canOpenAzReport: "Can Open Az Report",
      canSellOffline: "Can Sell Offline",
      totalDamage: "Total Damage",
      damageQty: "Damage Qty",
      canQuickSellRoundUp: "Can Quick Sell Round Up",
      canQuickSellRoundDown: "Can Quick Sell Round Down",
      canQuickSellRoundExact: "Can Quick Sell Round Exact",
      canToggleSellByMain: "Can Toggle Sell By Main",
      useOldSelect: "Use Old Select",
      showCreatedAt: "Show Created At",
      createdAt: "Created At",
      get: "Get",
      dollarPriceUpdatedSuccess: "Dollar Price Updated Successfully",
      dollarPriceGetSuccess: "Dollar Price Get Successfully",
      startFromZero: "Start From Zero",
      logs: "Logs",
      areYouSureToUpdate: "Are You Sure To Update?",
      showPreviousLoan: "Show Previous Loan",
      mcToScPrice: "Dollar Price",
      selectDamageType: "Select Damage Type",
      totalProductQty: "Total Product Qty",
      top: "Top",
      imageUrl: "Image Url",
      imageBottomGap: "Image Bottom Gap",
      userDetails: "User Details",
      detailsBottomGap: "Details Bottom Gap",
      ordersBottomGap: "Orders Bottom Gap",
      footer: "Footer",
      imageWidth: "Image Width",
      imageHeight: "Image Height",
      showWeight: "Show Weight",
      imageTopGap: "Image Top Gap",
      failToGetInvoiceSettings: "Fail To Get Invoice Settings",
      onlineSettings: "Online Settings",
      settingsUpdatedSuccessFully: "Settings Updated Successfully",
      settingsDownloadedSuccessFully: "Settings Downloaded Successfully",
      failToUpdateSettings: "Fail To Update Settings",
      sl: "Sl",
      weight: "Weight",
      showProductImage: "Show Product Image",
      invoiceDetails: "Invoice Details",
      discountPer: "Discount %",
      userActivities: "User Activities",
      purchaseReturns: "Purchase Returns",
      sellReturns: "Sell Returns",
      customerPayment: "Customer Payments",
      sell: "Sell",
      others: "Others",
      quickSells: "Quick Sells",
      quickSellReturns: "Quick Sell Returns",
      deposit: "Deposit",
      damage: "Damage",
      cashierSellsReport: "Cashier Sells Report",
      casherSellInvoiceNameIsRequired: "Cashier Sell Invoice Name Is Required",
      maxLocalInvoiceCount: "Max Local Invoice Count",
      representativeCustomerStatement: "Representative Customer Statement",
      cashierSellReport: "Cashier Sell Report",
      customerProfitLose: "Customer Profit Lose",
      productSellsInvoice: "Product Sells Invoice",
      lastPrice: "Last Price",
      controller: "Table",
      fullName: "Full Name",
      userName: "Username",
      fullDiscount: "100% Discount",
      updateSellPrice: "Update Sell Price",
      damageInvoice: "Damage Invoice",
      sentQty: "Sent Quantity",
      sendQty: "Send Quantity",
      receiveQte: "Receive Quantity",
      transferInvoice: "Transfer Invoice",
      showOrderCounter: "Show Order Counter",
      FirstIdentifier: "B.No.",
      giftQty: "Gift Qty",
      copy: "Copy",
      copySell: "Copy for Sell Invoice",
      copyPurchase: "Copy for Purcahse Invoice",
      serial: "Serial",
      expirationDate: "Expire Date",
      serials: "Serials",
      chooseSerial: "Choose Serial",
      pay: "pay",
      Dinar: "Dinar",
      Dollar: "Dollar",
      ordersReport: "Orders Report",
      submit: "Submit",
      paid: "Paid",
      paidPrice: "Paid Price",
      count: "Count",
      disableF6Command: "Disable F6 Command",
      received: "Received",
      distribute: "Distribute",
      restore: "Restore",
      restoreDiscountToOriginal:
        "This will restore discount to it's original approximately",
      ChangeUrl: "Change URL",
      selectColorVoucher: "Select Color For Voucher",
      selectColorInvoice: "Select Invoice Color",
      selectTextColor: "Select Text Color",
      selectEvenCellColor: "Select Even Cell Color",
      selectOddCellColor: "Select Odd Cell Color",
      degrees: "Degrees",
      specialist: "Specialist",
      experience: "Experience",
      department: "Department",
      bloodGroup: "Blood Group",
      ABp: "AB+",
      ABm: "AB-",
      Bm: "B-",
      Bp: "B+",
      Ap: "A+",
      Am: "A-",
      Op: "O+",
      Om: "O-",
      regularVisitsRatio: "Regular Visits Ratio",
      specialVisitsRatio: "Special Visits Ratio",
      about: "About",
      mcVisitPrice: "Visit Price: $",
      scVisitPrice: "Visit Price: IQD",
      RVS: "RVS",
      SVS1: "SVS",
      visitPrice: "Visit Price",
      weekDayAvailability: "Week Day Availability",
      generalInfo: "General Infomation",
      testCode: "Test Code",
      unit: "Unit",
      external: "External",
      internal: "Internal",
      costPrice: "Cost Price",
      isAgeInMonth: "Is Age In Month ?",
      minAge: "Minimum Age",
      maxAge: "Maximum Age",
      reference: "Reference",
      criticalLow: "Lowest Critical",
      criticalHigh: "Highest Critical",
      normalLow: "Lowest Normal",
      normalHigh: "Highest Normal",
      month: "Month",
      invoiceHasBeenCopied: "Invoice Has been copied",
      invoiceHasBeenAdded: "Invoice Has been added",
      copyAnyway: "Copy anyway",
      dontCopy: "Don't copy",
      addAnyway: "Add anyway",
      dontAdd: "Don't add",
      ConfirmDeleteCurrentInvoice:
        "You have another saved item(s), Do you want to copy this Invoice anyway?",
      accountantPermissions: "Accountant Permissions",
      invoicePermissions: "Invoice Permissions",
      discountPermissions: "Discount Permissions",
      purchasePermissions: "Purchase Permissions",
      sellPermissions: "Sell Permissions",
      year: "Year",
      doctorName: "Doctor Name",
      latestLab: "Latest Lab",
      latestVisit: "Latest Visit",
      latestMedicine: "Latest Medical",
      miner: "Miner",
      regular: "Regular",
      doctor: "Doctor",
      number: "Number",
      success: "Success",
      latestPrice: "Latest Price",
      labTest: "Lab Test",
      saveButton: "Save",
      condition: "Condition",
      normal: "Normal",
      critical: "Critical",
      abnormal: "Abnormal",
      confirmPerminantDelete: "Are you sure you want to delete this?",
      deleteCancel: "Cancelled",
      basePrice: "Base Price",
      medicines: "Medicines",
      select: "Select",
      years: "Year(s)",
      labTests: "Lab Tests",
      refresh: "Refresh",
      visits: "Visits",
      none: "None",
      choosePatient: "Choose Patient",
      loadLatestVisits: "Load latest visits",
      code: "Code",
      mcCostPrice: "Cost Price",
      chooseMedItem: "Choose Med Item",
      isMiner: "Is Miner",
      medItems: "Med Items",
      XRay: "X-Ray",
      XRays: "X-Rays",
      impression: "Impression",
      findings: "Findings",
      recommendation: "Recommendation",
      tags: "Tags",
      sureUpdate: "Are you sure you want to update?",
      sureDelete: "Are you sure you want to delete?",
      Success: "Success",
      uploadImg: "Upload Image",
      freeVisit: "Free Visit",
      customerVisitLabTest: "Customer Visit Lab Test",
      age: "Age",
      testName: "Test Name",
      normalRange: "Normal Range",
      noMedicines: "No medicianes Available fot this Patient",
      noMeditem: "No med item Available fot this Patient",
      noLabTest: "No lab test Available fot this Patient",
      noXRay: "No X-Ray Available fot this Patient",
      medItemName: "Med Item Name",
      visitDate: "Visit Date",
      canSeePurchasePrice: "Can See Purchase Price",
      value: "Value",
      addNewAttachment: "Add New Attachment",
      attachments: "Attachments",
      both: "Both",
      inited: "This Lab test has been initialized before",
      notInited: "This Lab test has been initialized before",
      BsMedi: "B.Sc. Medical Lab",
      totalVisitCount: "Total Visitor",
      hospitalRatio: "Hospital Ratio",
      docotrRatio: "Doctor Ratio",
      maxSell: "Max sell price",
      minSell: "Min sell price",
      higherPrice:
        "The Price is higher than the Maximum price of product, is that Ok?",
      lowerPrice:
        "The Price is lower than the Minimum price of product, is that Ok?",
      lastUser: "Last User",
      showNote: "Show Note",
      medItemsCostPrice: "Med Item Cost Price",
      groupItems: "Group Items",

      nonPaidSellInvoice: "Non Paid Sell Invoice Report",
      paidSuccessfully: "Paid Successfully",
      representative: "Representative ",
      changeTheRoofLoan: "The Roof Loan is Zero, Change the Roof Loan",
      visitchange: "Visit and Change",
      showDriver: "Show Driver",
      scLoanPrice: "IQD Loan Price",
      mcLoanPrice: "$ Loan Price",
      isLoading: "Is Loading...",
      storeInventory: "Store Inventory",
      representativeCustomerSellInvoiceReport:
        "Representative Customersell Invoice Report",
      totalSellReturnQty: "Total Sell Return",
      calculatedQty: "Calculated Qty",
      calculatedSmallQty: "Calculated Small Qty",
      representativeExpense: "Representative Expense",
      representativeDiscount: "Representative Discount",
      fromStore: "From Store",
      toStore: "To Store",
      itemExists: "Item Exists in the list",
      fromCashier: "From Cashier",
      toCashier: "To Cashier",
      dearUser: "Dear User,",
      youCanChangeTheInvoiceDesign:
        "You can change your invoice design in the below link",
      pageWidth: "Page Width",
      pageHight: "Page Height",
      tableFont: "Table Font Size",
      punishmentNote: "Punishment's Note",
      rewardsNote: "Reward's Note",
      getDinar: "The Dinar Price Got successfully",
      areYouSureToUpdateDinarPrice: "Are you sure to update Dinar Price?",
      tax: "Tax",
      payTax: "Pay Tax",
      other: "Other",
      transport: "Transport",
      isAvailable: "Is Available",
      withdrawReport: "Withdraw Report",
      depositReport: "Deposit Report",
      showInvoiceNumber: "Show Invoice Number",
      showTotalPrice: "Show Total Price",
      totalBankAccount: "Total Bank Accounts",
      totalCashiers: "Total Cashiers",
      showDate: "Show Date",
      showTotal: "Show Total",
      showHeader: "Show Header",
      showInvoice: "Show invoice",
      previousSellPrice: "Previous Sell Price",
      previousPurchasePrice: "Previous Purchase Price",
      after: "After",
      before: "Before",
      role: "Role",
      isActive: "Is Active",
      payTaxText: "You want to pay Tax Price?",
      printOtherProducts: "Print Other Products",
    },
  },
};
