import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";

import { HTTP } from "../../axios";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10000);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [product, setProduct] = React.useState(0);
  const [sellInvoice, setSellInvoice] = React.useState(0);
  const [count, setCount] = React.useState(50);

  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let selectedProduct = ``;
    if (product !== 0) {
      selectedProduct = `&prodId=${product}`;
    }

    let selectedInvoice = ``;
    if (sellInvoice !== 0) {
      selectedInvoice = `&invoiceId=${sellInvoice}`;
    }

    await HTTP.get(
      `${url}/Utilities/GetProductSellsAfterInvoiceWithCount?type=SellInvoice&count=${count}${selectedProduct}${selectedInvoice}&_start=${start}&_end=${end}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
        if (response.data && response.data.length > 0) {
          let newBalance = response.data[0].qte;
          response.data[0].balance = newBalance;
          for (let i = 1; i < response.data.length; i++) {
            let temp = newBalance + response.data[i].qte;
            response.data[i].balance = temp;
            newBalance = temp;
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadProducts = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  const loadInvoices = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/SellInvoices?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((sellInvoice) => {
        newArray.push({
          value: sellInvoice.id,
          label: sellInvoice.invoiceNumber,
          sellInvoice: sellInvoice,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.productStatementReport")}
            </h1>
          </div>
          <Row>
            {isLoading && <LoadingScreen />}
            <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4">
              <p className="m-0 mr-2 text-nowrap">
                {translate("resources.root.sellInvoice")} :
              </p>
              <AsyncSelect
                defaultOptions
                className="w-100"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                loadOptions={loadInvoices}
                onChange={(e) => setSellInvoice(e ? e.value : 0)}
              />
            </Col>
            <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0 mt-4">
              <p className="m-0 mr-2 text-nowrap">
                {translate("resources.root.product")} :
              </p>
              <AsyncSelect
                defaultOptions
                className="w-100"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                loadOptions={loadProducts}
                onChange={(e) => setProduct(e ? e.value : 0)}
              />
            </Col>
            <Col className="col-6 col-lg-2 d-flex align-items-center justify-content-start m-0 mt-4">
              <p className="m-0 mr-2 text-nowrap">
                {translate("resources.root.count")} :
              </p>
              <Form.Control
                type="text"
                placeholder={translate("resources.root.count")}
                onChange={(e) => {
                  setCount(e.target.value);
                }}
                defaultValue={count}
                onFocus={(e) => e.target.select()}
              />
            </Col>
            <Col className="col-6 col-md-3 col-lg-2 p-0 mt-4">
              <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                <FormControlLabel
                  className="m-0 text-nowrap "
                  control={
                    <Switch
                      onChange={(e) => setShowInUSD(e.target.checked)}
                      defaultChecked={showInUSD}
                    />
                  }
                  label={translate("resources.root.showInUSD")}
                />
              </InputGroup>
            </Col>

            <Col className="col-4  mt-4">
              <Button
                id="export"
                variant={"primary btn-block"}
                disabled={data.length <= 0}
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>

            <Col className="col-4  mt-4">
              <Button
                variant={"outline-primary btn-block"}
                disabled={data.length <= 0}
                onClick={() => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";
                  window.print();
                  sidebar[0].style.display = "block";
                  appMenu[0].style.display = "block";
                  appBar[0].style.marginTop = "40px";
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}{" "}
              </Button>
            </Col>
            <Col className="col-4  mt-4">
              <Button
                variant={"primary btn-block"}
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate(
                  `resources.root.${isLoading ? "isLoading" : "search"}`
                )}
              </Button>
            </Col>
          </Row>
          <Row className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.product")}</th>
                    <th>{translate("resources.root.oneMeasure")}</th>
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.lastPrice")}</th>
                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>{one.id}</td>
                          <td>
                            <a
                              href={`/#/Products/${one.productId}/show`}
                              target={"_blank"}
                            >
                              {one.productName}
                            </a>
                          </td>
                          <td>{one.oneMeasure}</td>
                          <td>{one.qte}</td>
                          <td>
                            {showInUSD
                              ? one.mcSellPrice?.toLocaleString()
                              : one.scSellPrice?.toLocaleString()}
                          </td>
                          <td>
                            {showInUSD
                              ? one.mcLastPrice?.toLocaleString()
                              : one.scLastPrice?.toLocaleString()}
                          </td>
                          <td>{one.balance}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
