import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Show,
  ShowButton,
  TabbedShowLayout,
  Tab,
  Create,
  BooleanField,
  FormTab,
  TabbedForm,
  useTranslate,
  useNotify,
  DateField,
  useRefresh,
  useResourceDefinition,
  DateInput,
  ReferenceField,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url } from "../../request";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";

import MuiAlert from "@mui/lab/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Form } from "react-bootstrap";
import MaterialTextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import swal from "sweetalert";
import Swal from "sweetalert2";
import PrintIcon from "@mui/icons-material/Print";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { permissions } from "../Permissions/AllPermission";
import CreatePurchaseInvoice from "../invoices/CreatePurchaseInvoice";
import CreatePurchasePreInvoice from "../invoices/CreatePurchasePreInvoice";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();
  console.log(props);

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Multiple inputs",
                html:
                  '<label className="text-dark">Pay Price:</label> <input value="0" id="swal-input1" type="number" className="swal2-input">' +
                  ' <label className="text-dark">Forgive:</label> <input  value="0" id="swal-input2" type="number" className="swal2-input">',
                showCancelButton: true,
                focusConfirm: false,
                inputValue: 0,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.submit"),
                cancelButtonText: translate("ra.action.cancel"),
                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/Pay?invoiceId=${
                      props.selectedIds[0]
                    }&payPrice=${
                      document.getElementById("swal-input1").value
                    }&forgivePrice=${
                      document.getElementById("swal-input2").value
                    }`
                  )
                    .then(() => {
                      notify(translate("resources.root.PaidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.pay")}
          </Button>
        )}
        {((props.selectedIds.length === 1 &&
          process.env.REACT_APP_APP_NAME === "Bahez") ||
          process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                icon: "warning",
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.payTaxText"),
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("ra.action.cancel"),

                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/PayTaxPayment/${
                      props.selectedIds[0]
                    }?toggle=${true}`
                  )
                    .then(() => {
                      notify(translate("resources.root.paidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.payTax")}
          </Button>
        )}

        <BulkAction
          {...props}
          bulkDeleteButton={true}
          hasPrintOrders={"PurchaseInvoice"}
          hasDelete="purchaseinvoice.delete"
        />
      </div>
    </React.Fragment>
  );
};

export const PurchasesPreList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print force>
          <List
            debounce={1500}
            filters={<SearchFilter driver={true} date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions
                setCurrency={setCurrency}
                currency={currency}
                hasCreate="purchaseinvoice.create"
              />
            }
          >
            <Datagrid
              bulkActionButtons={
                // <BulkAction
                //   {...props}
                //   bulkDeleteButton={true}
                //   hasPrintOrders={"PurchaseInvoice"}
                // />
                <BulkActionButtons />
              }
            >
              <TextField source="id" label={translate("resources.root.id")} />
              <ReferenceField
                label="resources.root.supplier"
                source="supplierId"
                reference="Suppliers"
                link={(record) =>
                  `/Reports/SupplierStatement/${record.supplier.id}`
                }
              >
                <TextField source="supplierName" />
              </ReferenceField>
              {localStorage.getItem("showInvoiceNumber") === "true" && (
                <TextField
                  source="invoiceNumber"
                  label="resources.root.invoiceNumber"
                />
              )}
              {currency === "default" && (
                <FunctionField
                  label="resources.root.price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    record.payByMain
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              {currency !== "default" && (
                <FunctionField
                  label={translate("resources.root.price")}
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    currency === "dollar"
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              <FunctionField
                label={translate("resources.root.quantity")}
                render={(record) =>
                  (record.totalQte - record.totalGiftQte)?.toLocaleString()
                }
              />
              <TextField
                source="totalGiftQte"
                label="resources.root.giftQuantity"
              />
              <TextField
                source="totalQte"
                label="resources.root.totalQuantity"
              />
              <FunctionField
                render={(record) =>
                  moment(record.date).format("DD/MM/YYYY hh:mm:ss A")
                }
                sortBy={"date"}
                label="resources.root.date"
              />
              <BooleanField
                source="isCash"
                label={translate("resources.root.cash")}
              />
              {localStorage.getItem("showNote") === "true" && (
                <TextField source="note" label="resources.root.note" />
              )}
              {localStorage.getItem("showDriver") === "true" && (
                <TextField source="driver" label="resources.root.driver" />
              )}
              {localStorage.getItem("showLastUser") === "true" && (
                <TextField source="lastUser" label="resources.root.lastUser" />
              )}
              {localStorage.getItem("showCreatedAt") === "true" && (
                <FunctionField
                  sortBy={"createdAt"}
                  label="resources.root.createdAt"
                  render={(record) =>
                    record.createdAt
                      ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                      : "-"
                  }
                />
              )}{" "}
              {hasPermission([permissions?.purchaseInvoiceView]) && (
                <ShowButton />
              )}
              {hasPermission([permissions?.purchaseInvoiceUpdate]) && (
                <EditButton />
              )}
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};

export const PurchasesPreCreate = (props) => (
  <Create {...props} component="div">
    <CreatePurchasePreInvoice />
  </Create>
);

export const PurchasesPreShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/PurchasePreInvoices/${id}`).then((res) => {
      setOrders(res.data.purchasePreOrders);
      setInvoice(res.data);
    });
  }, [props]);

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.purchasePreInvoice")}
              order={invoice.purchasePreOrders}
              human={invoice.supplier}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label={translate("resources.root.invoice")}>
                <TextField
                  source="invoiceNumber"
                  label={translate("resources.root.invoiceNumber")}
                />
                <TextField
                  source="supplier.supplierName"
                  label={translate("resources.root.supplierName")}
                />
                <TextField
                  source="supplier.owner"
                  label={translate("resources.root.owner")}
                />
                <FunctionField
                  label={translate("resources.root.quantity")}
                  render={(record) =>
                    `${record.totalQte - record.totalGiftQte}`
                  }
                />
                <TextField
                  source="totalGiftQte"
                  label={translate("resources.root.giftQuantity")}
                />
                <TextField
                  source="totalQte"
                  label={translate("resources.root.finalQuantity")}
                />
                <DateField
                  source="date"
                  label="resources.root.date"
                  locales={"en-GB"}
                  showTime={true}
                />
                <TextField
                  source="note"
                  label={translate("resources.root.note")}
                />
                <TextField
                  source="driver"
                  label={translate("resources.root.driver")}
                />

                <FunctionField
                  label="resources.root.totalPrice"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcTotalPrice?.toLocaleString()}`
                      : `${record.scTotalPrice?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.finalPrice"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.finalDiscount"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcFinalDiscount?.toLocaleString()}`
                      : `${record.scFinalDiscount?.toLocaleString()} IQD`
                  }
                />
              </Tab>
              <Tab label={translate("resources.root.orders")}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.serial")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.productMeasure")}
                        </TableCell>

                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.smallMeasurePrice")}
                        </TableCell>
                        {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              <a href={`#/Products/${order.product.id}`}>
                                {order.product.name}
                              </a>
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {order.productSerial?.serialNumber}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.qte + " + " + order.smallMeasureQte}
                            </TableCell>
                            <TableCell align="right">{order.giftQte}</TableCell>
                            <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>

                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcPurchasePrice?.toLocaleString()
                                : order.scPurchasePrice?.toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSmallMeasurePurchasePrice?.toLocaleString()
                                : order.scSmallMeasurePurchasePrice?.toLocaleString()}
                            </TableCell>
                            {process.env.REACT_APP_ORDER_DISCOUNT ===
                              "true" && (
                              <TableCell align="right">
                                {order.payByMain
                                  ? order.mcDiscount?.toLocaleString()
                                  : order.scDiscount?.toLocaleString()}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice?.toLocaleString()
                                : order.scLastPrice?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <div className="d-flex flex-wrap justify-content-start align-items-center gap-2 my-2">
            <Button
              variant="contained"
              className={classes.botton}
              onClick={() => {
                let sidebar = document.getElementsByClassName("MuiDrawer-root");
                sidebar[0].style.display = "none";
                window.print();
                sidebar[0].style.display = "block";
              }}
            >
              {translate("resources.root.print")}
            </Button>
          </div>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const PurchasesPreEdit = () => {
  return (
    <div component="div">
      <CreatePurchasePreInvoice />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {},
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
